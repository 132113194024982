<template>
  <div id="app">
    <Offcanvas
      :open="offcanvas.open"
      @close-offcanvas="closeSidebar"
      @toggle-offcanvas="toggleSidebar"
    >
      <template v-slot:offcanvas-body>
        <div class="offcanvas-body-container">
          <div>
            <router-link
              to="/"
              class="logo d-flex align-items-center text-uppercase"
            >
              <img
                :src="
                  config.basicInfo.regionCode
                    ? `/images/${config.basicInfo.regionCode}/logo.png`
                    : 'images/default/logo.svg'
                "
                alt=""
                class="logo-image"
              />
              <div class="logo-text">
                <div>
                  {{
                    config.basicInfo.systemName.status
                      ? config.basicInfo.systemName.status
                      : "ИС"
                  }}
                </div>
                <div class="logo-text-name">
                  {{
                    config.basicInfo.systemName.name
                      ? config.basicInfo.systemName.name
                      : "Арена"
                  }}
                </div>
              </div>
            </router-link>
            <template v-if="config.auth && config.authUserType">
              <div class="offcanvas-section-header">Вы вошли как:</div>
              <router-link to="/auth" class="account-info">
                <!--              <img src="/images/default.jpg" class="account-info-image" />-->
                <div
                  v-if="user.avatar.file"
                  class="account-info-image"
                  :style="
                    'background: url(data:' +
                    user.avatar.type +
                    ';base64,' +
                    user.avatar.file +
                    ') no-repeat center center; background-size: cover'
                  "
                ></div>
                <div class="account-info-text">
                  <div v-if="user.name" class="account-info-text-name">
                    {{ user.name }}
                  </div>
                  <div v-if="user.email" class="account-info-text-email">
                    {{ user.email }}
                  </div>
                </div>
              </router-link>
            </template>
            <div class="offcanvas-section-header">Навигационное меню</div>
            <Nav :nav-list="sidebarNavList" :config="config" vertical />
          </div>
          <div :class="['support', { show: offcanvas.support.open }]">
            <div class="support-container">
              <div class="support-content">
                <div class="support-header">Возникли проблемы?</div>
                <div class="support-text">
                  <div>Сообщите нам на почту</div>
                  <div class="support-text-email">
                    {{
                      config.basicInfo.supportEmail
                        ? config.basicInfo.supportEmail
                        : "support@test-mail.ru"
                    }}
                  </div>
                </div>
                <!--                <button class="btn-support-primary">Сообщить о проблеме</button>-->
                <a
                  :href="
                    config.basicInfo.supportEmail
                      ? 'mailto:' + config.basicInfo.supportEmail
                      : 'mailto:support@test-mail.ru'
                  "
                  class="btn-support-primary"
                  ><div>Сообщить о проблеме</div></a
                >
              </div>
            </div>
            <button class="btn-support-toggle" @click="toggleSupport">
              <div class="icon-support">?</div>
            </button>
          </div>
        </div>
      </template>
    </Offcanvas>
    <b-sidebar
      id="sidebar-main"
      title="Меню"
      bg-variant="white"
      shadow="sm"
      backdrop
    >
      <Nav :nav-list="sidebarNavList" vertical />
    </b-sidebar>
    <router-view
      :config="config"
      :dictionaries="dictionaries"
      :app-loaded="appLoaded"
      :calendar-data="calendar"
      :services="services"
      :news="news"
      :news-article="newsArticle"
      :organizations="organizations"
      :organization="organization"
      :organization-passport="organizationPassport"
      :children="children"
      :person="person"
      :person-account="personAccount"
      :objects="objects"
      :stadium="stadium"
      :events="events"
      :events-poster-data="eventsPosterDB.items"
      :event="event"
      :faq="faq"
      :child-create-data="childCreateData"
      :child-update-data="childUpdateData"
      :invalid-account="invalidAccount"
      @go-to-view="goToView($event)"
      @esia-sign-in="esiaSignIn"
      @change-previous-month="changePreviousMonth"
      @change-next-month="changeNextMonth"
      @change-previous-week="changePreviousWeek"
      @change-next-week="changeNextWeek"
      @change-day="calendarChangeDay($event)"
      @open-offcanvas="openSidebar"
      @show-modal="showModal($event)"
      @show-modal-app-public-with-org="showModalAppPublicWithOrg($event)"
      @show-modal-app-event-with-event="showModalAppEventWithEvent($event)"
      @news-read="newsRead($event)"
      @stadium-read="stadiumRead($event)"
      @child-read="childRead($event)"
      @event-read="eventRead($event)"
      @organization-read="organizationRead($event)"
      @child-create="goToView('/childnew')"
      @change-new-child-form="
        changeFormWithValidate(
          childCreateData[$event.formIndex].form,
          $event.field
        )
      "
      @change-update-child-form="
        changeFormWithValidate(
          childUpdateData[$event.formIndex].form,
          $event.field
        )
      "
      @child-edit="editDetailsInfo(childUpdateData, config.views.childUpdate)"
      @child-update="
        updateDetailsInfo(childUpdateData, config.views.childUpdate)
      "
      @reset-child-update-view="resetChildUpdateView"
      @back-action="goBack($event)"
      @app-org-revocate="appOrgRevocate($event)"
      @app-event-revocate="appEventRevocate($event)"
      @app-training-revocate="appTrainingRevocate($event)"
      @app-training-confirm="appTrainingConfirm($event)"
      @app-training-refuse="appTrainingRefuse($event)"
      @app-public-revocate="appPublicRevocate($event)"
      @app-org-select-to-approve="appOrgSelectToApprove($event)"
      @app-org-deny="appOrgDeny($event)"
      @app-event-approve="appEventApprove($event)"
      @app-event-deny="appEventDeny($event)"
      @app-training-select-to-offer="appTrainingSelectToOffer($event)"
      @app-training-select-to-advance="appTrainingSelectToAdvance($event)"
      @app-training-select-to-approve="appTrainingSelectToApprove($event)"
      @app-training-select-to-deny="appTrainingSelectToDeny($event)"
      @app-training-select-to-response="appTrainingSelectToResponse($event)"
      @app-training-offer="appTrainingOffer($event)"
      @app-training-approve="appTrainingApprove($event)"
      @app-training-deny="appTrainingDeny($event)"
      @app-public-approve="appPublicApprove($event)"
      @app-public-deny="appPublicDeny($event)"
      @change-tab-services="changeTab(services.tabs, $event)"
      @send-new-child="sendNewChild"
      @send-update-child="sendUpdateChild"
      @go-to-training="goToTraining"
      @go-to-public="goToPublic"
      @go-to-apps="goToApps"
      @logout="getLogout"
      @change-orgs-page-size="
        changePageSize(organizations.table, $event, 'getOrganizations')
      "
      @change-orgs-page="
        changePage(organizations.table, $event, 'getOrganizations')
      "
      @change-events-page-size="
        changePageSize(events.table, $event, 'getEvents')
      "
      @change-events-page="changePage(events.table, $event, 'getEvents')"
      @change-stadiums-page-size="
        changePageSize(objects.table, $event, 'getObjects')
      "
      @change-stadiums-page="changePage(objects.table, $event, 'getObjects')"
      @change-news-page-size="changePageSize(news.table, $event, 'getNews')"
      @change-news-page="changePage(news.table, $event, 'getNews')"
      @change-training-passport-page-size="
        changePageSize(
          organizationPassport.trainings.table,
          $event,
          'getTrainingAppsForOrgPassport'
        )
      "
      @change-training-passport-page="
        changePage(
          organizationPassport.trainings.table,
          $event,
          'getTrainingAppsForOrgPassport'
        )
      "
      @sort-training-passport-table="
        sortTable(
          $event.tableName,
          $event.sortedColumnIndex,
          'getTrainingAppsForOrgPassport'
        )
      "
      @change-training-passport-filter="
        changeFilter(organizationPassport.trainings.table.filters, $event)
      "
      @apply-training-passport-filter="getTrainingAppsForOrgPassport"
      @clear-training-passport-filter="
        resetFilter(
          organizationPassport.trainings.table,
          'getTrainingAppsForOrgPassport'
        )
      "
      @change-orgs-filter="changeFilter(organizations.table.filters, $event)"
      @apply-orgs-filter="getOrganizations"
      @clear-orgs-filter="resetFilter(organizations.table, 'getOrganizations')"
      @orgs-input-search="organizations.table.searchField = $event"
      @orgs-apply-search="findOrgsByName($event)"
      @change-stadiums-filter="changeFilter(objects.table.filters, $event)"
      @apply-stadiums-filter="getObjects"
      @clear-stadiums-filter="resetFilter(objects.table, 'getObjects')"
      @stadiums-input-search="objects.table.searchField = $event"
      @stadiums-apply-search="findStadiumsByName($event)"
      @change-events-filter="changeFilter(events.table.filters, $event)"
      @apply-events-filter="getEvents"
      @clear-events-filter="resetFilter(events.table, 'getEvents')"
      @events-input-search="events.table.searchField = $event"
      @events-apply-search="findEventsByName($event)"
      @discipline-read="disciplineRead($event)"
      @reset-discipline="resetDiscipline"
    />
    <Modal
      modal-id="appModal"
      header
      vertical-center
      scrollable
      class="shadow-sm"
      :static-backdrop="config.modal.id === 9"
    >
      <template v-slot:modal-header>
        <ModalHeader
          :title="config.modal.title"
          :only-title="config.modal.id === 9"
          @hide-modal="hideModal"
        />
      </template>
      <template v-slot:modal-body>
        <ModalContentPersonAddDoc
          v-if="config.modal.id === 1"
          :modal-form-data="modals[0]"
          @change-form="changeForm(modals[0].form.fields, $event)"
          @send-form="sendAddPersonDoc"
        />
        <ModalContentPersonAddEducation
          v-else-if="config.modal.id === 2"
          :modal-form-data="modals[1]"
          @change-form="changeFormWithValidate(modals[1].form, $event)"
          @send-form="sendAddPersonEducation"
        />
        <ModalContentPersonAddAppOrg
          v-else-if="config.modal.id === 3"
          :modal-form-data="modals[2]"
          @go-to-view="goToView('/organizations')"
          @change-form-part1="
            changeFormWithValidate(modals[2].formPart1, $event)
          "
          @change-form-part2="
            changeFormWithValidate(modals[2].formPart2, $event)
          "
          @change-form-part3="
            changeFormWithValidate(modals[2].formPart3, $event)
          "
          @send-form="sendAppOrg"
        />
        <ModalContentPersonAddAppEvent
          v-else-if="config.modal.id === 4"
          :modal-form-data="modals[3]"
          @go-to-view="goToView('/schedule')"
          @change-form-part1="
            changeFormWithValidate(modals[3].formPart1, $event)
          "
          @change-form-part2="
            changeFormWithValidate(modals[3].formPart2, $event)
          "
          @change-form-part3="
            changeFormWithValidate(modals[3].formPart3, $event)
          "
          @send-form="sendAppEvent"
        />
        <ModalContentPersonAddAppTraining
          v-else-if="config.modal.id === 5"
          :modal-form-data="modals[4]"
          :config="config"
          @go-to-view="goToView('/organizations')"
          @read-requirements="readRequirements($event)"
          @change-form-part1="changeAddAppTraining(modals[4].formPart1, $event)"
          @change-form-part2="changeAddAppTraining(modals[4].formPart2, $event)"
          @change-form-part3="changeAddAppTraining(modals[4].formPart3, $event)"
          @send-form="sendAppTraining"
        />
        <ModalContentPersonAddAppTrainingReq
          v-else-if="config.modal.id === 6"
          :requirements="modals[5].requirements"
          @return-to-training-app-modal="returnToTrainingAppModal"
        />
        <ModalContentPersonAddAppPublic
          v-else-if="config.modal.id === 7"
          :modal-form-data="modals[6]"
          @go-to-view="goToView('/objects')"
          @change-form-part1="changeAddAppPublic(modals[6].formPart1, $event)"
          @change-form-part2="changeAddAppPublic(modals[6].formPart2, $event)"
          @send-form="sendAppPublic"
        />
        <ModalContentPersonApproveAppOrg
          v-else-if="config.modal.id === 8"
          :modal-form-data="modals[7]"
          @change-form="changeFormWithValidate(modals[7].form, $event)"
          @send-form="appOrgApprove"
          @hide-modal="hideModal"
        />
        <ModalContentPersonSelectOrg
          v-else-if="config.modal.id === 9"
          :modal-form-data="config.userOrgs"
          @sign-in-with-org="signInWithOrg($event)"
          @select-person="signInWithPerson"
        />
        <Alert v-else-if="config.modal.id === 13" type="danger" class="mb-0">{{
          config.modal.errorText
        }}</Alert>
        <ModalContentTrainingOffer
          v-else-if="config.modal.id === 15"
          :modal-form-data="modals[13]"
          @change-form="changeFormWithValidate(modals[13].form, $event)"
          @send-form="appTrainingOffer"
          @hide-modal="hideModal"
          @read-comment="readComment"
        />
        <ModalContentTrainingAdvance
          v-else-if="config.modal.id === 10"
          :modal-form-data="modals[9]"
          @change-form="changeFormWithValidate(modals[9].form, $event)"
          @send-form="appTrainingAdvance"
          @hide-modal="hideModal"
        />
        <ModalContentTrainingApprove
          v-else-if="config.modal.id === 11"
          :modal-form-data="modals[10]"
          @change-form="changeFormWithValidate(modals[10].form, $event)"
          @send-form="appTrainingApprove"
          @hide-modal="hideModal"
        />
        <ModalContentTrainingDeny
          v-else-if="config.modal.id === 12"
          :modal-form-data="modals[11]"
          @change-form="changeFormWithValidate(modals[11].form, $event)"
          @send-form="appTrainingDeny"
          @hide-modal="hideModal"
        />
        <ModalContentTrainingResponse
          v-else-if="config.modal.id === 14"
          :modal-form-data="modals[12]"
          @change-form="changeFormWithValidate(modals[12].form, $event)"
          @send-form="appTrainingResponse"
          @hide-modal="hideModal"
        />
        <ModalContentTrainingComment
          v-else-if="config.modal.id === 16"
          :modal-form-data="modals[14]"
          @change-form="changeFormWithValidate(modals[14].form, $event)"
          @update-comment="updateComment"
          @reset-comment="resetComment"
        />
        <ModalContentInvalidAddAppIpra
          v-else-if="config.modal.id === 17"
          :modal-form-data="modals[15]"
        />
        <!--        @change-form-part1=""-->
        <!--        @change-form-part2=""-->
        <!--        @send-form=""-->
      </template>
    </Modal>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import Offcanvas from "./components/universal/BS46Offcanvas";
import Nav from "./components/universal/BS46Nav";
import Modal from "./components/universal/BS46Modal";
import ModalHeader from "./components/ModalHeader";
import ModalContentPersonAddDoc from "./components/modals/ModalContentPersonAddDoc";
import ModalContentPersonAddEducation from "./components/modals/ModalContentPersonAddEducation";
import ModalContentPersonAddAppOrg from "./components/modals/ModalContentPersonAddAppOrg";
import ModalContentPersonAddAppTraining from "./components/modals/ModalContentPersonAddAppTraining";
import ModalContentPersonAddAppTrainingReq from "./components/modals/ModalContentPersonAddAppTrainingReq";
import ModalContentPersonAddAppPublic from "./components/modals/ModalContentPersonAddAppPublic";
import ModalContentPersonAddAppEvent from "./components/modals/ModalContentPersonAddAppEvent";
import Alert from "./components/universal/BS46Alert";
import ModalContentPersonApproveAppOrg from "./components/modals/ModalContentPersonApproveAppOrg";
import ModalContentPersonSelectOrg from "./components/modals/ModalContentPersonSelectOrg";
import ModalContentTrainingAdvance from "./components/modals/ModalContentTrainingAdvance";
import ModalContentTrainingApprove from "./components/modals/ModalContentTrainingApprove";
import ModalContentTrainingDeny from "./components/modals/ModalContentTrainingDeny";
import ModalContentTrainingResponse from "./components/modals/ModalContentTrainingResponse";
import ModalContentTrainingOffer from "./components/modals/ModalContentTrainingOffer";
import ModalContentTrainingComment from "./components/modals/ModalContentTrainingComment";
import ModalContentInvalidAddAppIpra from "./components/modals/ModalContentInvalidAddAppIpra";

export default {
  components: {
    ModalContentInvalidAddAppIpra,
    ModalContentTrainingComment,
    ModalContentTrainingOffer,
    ModalContentTrainingResponse,
    ModalContentTrainingDeny,
    ModalContentTrainingApprove,
    ModalContentTrainingAdvance,
    ModalContentPersonSelectOrg,
    ModalContentPersonApproveAppOrg,
    Alert,
    ModalContentPersonAddAppEvent,
    Offcanvas,
    Nav,
    Modal,
    ModalHeader,
    ModalContentPersonAddDoc,
    ModalContentPersonAddEducation,
    ModalContentPersonAddAppOrg,
    ModalContentPersonAddAppTraining,
    ModalContentPersonAddAppTrainingReq,
    ModalContentPersonAddAppPublic,
  },
  data() {
    return {
      config: {
        url: "/",
        basicInfo: {
          regionCode: null,
          regionImageFileName: "",
          systemName: {
            status: "",
            name: "",
            fullName: "",
          },
          regionNames: [],
          supportEmail: "",
        },
        homePage: {
          services: {
            tabs: [],
            cards: [],
          },
          calendar: {
            title: "",
          },
        },
        objectsPage: {
          isMapFrame: false,
        },
        personAccountPage: {
          addAppTrainingModal: {
            isOrgRegistryLink: false,
          },
        },
        appLoaded: false,
        dictionariesLoaded: false,
        loadersDelay: 1000,
        auth: false,
        authUserType: "",
        legal: false,
        org: null,
        esiaUrl: "",
        mapUrl: "",
        userShortName: null,
        userOrgs: [],
        authModalMode: "",
        views: {
          childUpdate: {
            loading: false,
            editMode: false,
          },
        },
        modal: {
          id: null,
          title: "",
          errorText: "",
        },
        appToApproveId: null,
        appTrainingSelectedForDecision: null,
        childForUpdateId: null,
      },
      regionsConfigs: {
        24: {
          // url: "http://192.168.17.39:8085/",
          // url: "https://opensport.isands.ru/",
          // url: "https://visminsport24.krskcit.ru/",
          // url: "https://sport.krskcit.ru/",
          url: "/",
          basicInfo: {
            regionCode: 24,
            regionImageFileName: "region_emblem.png",
            systemName: {
              status: "ИС",
              name: "Минспорт",
              fullName: "ИС Минспорт",
            },
            regionNames: [
              "Красноярский край",
              "Красноярского края",
              "Красноярскому краю",
              "Красноярский край",
              "Красноярским краем",
              "Красноярском крае",
            ],
            supportEmail: "ipk_rfk@mail.ru",
          },
          homePage: {
            services: {
              tabs: [
                {
                  id: "persons-services",
                  title: "Физ.лицам",
                  active: true,
                  image: { src: "/icons/forpersons.svg" },
                  servicesArray: [0, 1, 2, 3],
                },
                {
                  id: "sportsmans-services",
                  title: "Спортсменам",
                  active: false,
                  image: { src: "/icons/forsportsmans.svg" },
                  servicesArray: [0, 1, 3],
                },
                {
                  id: "parents-services",
                  title: "Родителям",
                  active: false,
                  image: { src: "/icons/forparents.svg" },
                  servicesArray: [0],
                },
              ],
              cards: [
                {
                  annotation: "",
                  author: "",
                  categories: ["Сервисы"],
                  createDate: "",
                  datePublish: "Записаться",
                  id: "service-01",
                  image: {
                    src: "images/24/services/01.jpg",
                    type: "",
                  },
                  action: "go-to-training",
                  link: "/organizations",
                  imagePosition: "",
                  text: "",
                  title:
                    "Записаться на занятия спортом в спортивные организации края",
                },
                {
                  annotation: "",
                  author: "",
                  categories: ["Сервисы"],
                  createDate: "",
                  datePublish: "Записаться",
                  id: "service-02",
                  image: {
                    src: "/images/24/services/02.jpg",
                    type: "",
                  },
                  action: "go-to-public",
                  link: "/objects",
                  imagePosition: "",
                  text: "",
                  title: "Запись на занятия массовым спортом",
                },
                {
                  annotation: "",
                  author: "",
                  categories: ["Сервисы"],
                  createDate: "",
                  datePublish: "Открыть реестр",
                  id: "service-03",
                  image: {
                    src: "/images/24/services/03.jpg",
                    type: "",
                  },
                  action: "",
                  link: "/objects",
                  imagePosition: "",
                  text: "",
                  title: "Реестр объектов спорта",
                },
                {
                  annotation: "",
                  author: "",
                  categories: ["Сервисы"],
                  createDate: "",
                  datePublish: "Зарегистрироваться",
                  id: "service-04",
                  image: {
                    src: "/images/24/services/04.jpg",
                    type: "",
                  },
                  action: "go-to-apps",
                  link: "/schedule",
                  imagePosition: "",
                  text: "",
                  title:
                    "Регистрация в качестве участника или зрителя физкультурного мероприятия",
                },
              ],
            },
            calendar: {
              title: "Календарь",
            },
          },
          objectsPage: {
            isMapFrame: true,
          },
          personAccountPage: {
            addAppTrainingModal: {
              isOrgRegistryLink: true,
            },
          },
          appLoaded: false,
          dictionariesLoaded: false,
          loadersDelay: 1000,
          auth: false,
          authUserType: "",
          legal: false,
          org: null,
          esiaUrl: "",
          mapUrl: "",
          userShortName: null,
          userOrgs: [],
          authModalMode: "",
          views: {
            childUpdate: {
              loading: false,
              editMode: false,
            },
          },
          modal: {
            id: null,
            title: "",
            errorText: "",
          },
          appToApproveId: null,
          appTrainingSelectedForDecision: null,
          childForUpdateId: null,
        },
        54: {
          // url: "https://opensport.isands.ru/",
          // url: "https://arena.nso.ru/",
          url: "/",
          basicInfo: {
            regionCode: 54,
            regionImageFileName: "region_emblem.svg",
            systemName: {
              status: "ГИС",
              name: "Статспорт",
              fullName: "ГИС Статспорт",
            },
            regionNames: [
              "Новосибирская область",
              "Новосибирской области",
              "Новосибирской области",
              "Новосибирскую область",
              "Новосибирской областью",
              "Новосибирской области",
            ],
            supportEmail: "statsport@nso.ru",
          },
          homePage: {
            services: {
              tabs: [
                {
                  id: "persons-services",
                  title: "Физ.лицам",
                  active: true,
                  image: { src: "/icons/forpersons.svg" },
                  servicesArray: [0, 1, 2, 3],
                },
                {
                  id: "sportsmans-services",
                  title: "Спортсменам",
                  active: false,
                  image: { src: "/icons/forsportsmans.svg" },
                  servicesArray: [0, 1, 3],
                },
                {
                  id: "parents-services",
                  title: "Родителям",
                  active: false,
                  image: { src: "/icons/forparents.svg" },
                  servicesArray: [0],
                },
                {
                  id: "gosuslugi-services",
                  title: "Госуслуги",
                  active: false,
                  image: { src: "/icons/forparents.svg" },
                  servicesArray: [4, 5, 6],
                },
              ],
              cards: [
                {
                  annotation: "",
                  author: "",
                  categories: ["Сервисы"],
                  createDate: "",
                  datePublish: "Записаться",
                  id: "service-01",
                  image: {
                    src: "images/54/services/01.jpg",
                    type: "",
                  },
                  action: "go-to-training",
                  link: "/organizations",
                  imagePosition: "",
                  text: "",
                  title:
                    "Записаться на занятия спортом в спортивные организации области",
                },
                {
                  annotation: "",
                  author: "",
                  categories: ["Сервисы"],
                  createDate: "",
                  datePublish: "Записаться",
                  id: "service-02",
                  image: {
                    src: "images/54/services/02.jpg",
                    type: "",
                  },
                  action: "go-to-public",
                  link: "/objects",
                  imagePosition: "",
                  text: "",
                  title: "Запись на занятия массовым спортом",
                },
                {
                  annotation: "",
                  author: "",
                  categories: ["Сервисы"],
                  createDate: "",
                  datePublish: "Открыть реестр",
                  id: "service-03",
                  image: {
                    src: "images/54/services/03.jpg",
                    type: "",
                  },
                  action: "",
                  link: "/objects",
                  imagePosition: "",
                  text: "",
                  title: "Реестр объектов спорта",
                },
                {
                  annotation: "",
                  author: "",
                  categories: ["Сервисы"],
                  createDate: "",
                  datePublish: "Зарегистрироваться",
                  id: "service-04",
                  image: {
                    src: "images/54/services/04.jpg",
                    type: "",
                  },
                  action: "go-to-apps",
                  link: "/schedule",
                  imagePosition: "",
                  text: "",
                  title:
                    "Регистрация в качестве участника или зрителя физкультурного мероприятия",
                },
                {
                  annotation: "",
                  author: "",
                  categories: ["Сервисы"],
                  createDate: "",
                  datePublish: "Перейти",
                  id: "service-05",
                  image: {
                    src: "/images/54/services/05.jpg",
                    type: "",
                  },
                  action: "",
                  link: "https://www.gosuslugi.ru/600152/1/form",
                  imagePosition: "",
                  text: "",
                  title: "Присвоение спортивных разрядов",
                },
                {
                  annotation: "",
                  author: "",
                  categories: ["Сервисы"],
                  createDate: "",
                  datePublish: "Перейти",
                  id: "service-06",
                  image: {
                    src: "/images/54/services/06.jpg",
                    type: "",
                  },
                  action: "",
                  link: "https://www.gosuslugi.ru/600151/1/form",
                  imagePosition: "",
                  text: "",
                  title:
                    "Присвоение квалификационных категорий спортивных судей",
                },
                {
                  annotation: "",
                  author: "",
                  categories: ["Сервисы"],
                  createDate: "",
                  datePublish: "Перейти",
                  id: "service-07",
                  image: {
                    src: "/images/54/services/07.jpg",
                    type: "",
                  },
                  action: "",
                  link: "https://www.gosuslugi.ru/600158/1/form",
                  imagePosition: "",
                  text: "",
                  title:
                    "Государственная аккредитация региональных спортивных федераций",
                },
              ],
            },
            calendar: {
              title: "Календарь спортивных мероприятий",
            },
          },
          objectsPage: {
            isMapFrame: false,
          },
          personAccountPage: {
            addAppTrainingModal: {
              isOrgRegistryLink: false,
            },
          },
          appLoaded: false,
          dictionariesLoaded: false,
          loadersDelay: 1000,
          auth: false,
          authUserType: "",
          legal: false,
          org: null,
          esiaUrl: "",
          mapUrl: "",
          userShortName: null,
          userOrgs: [],
          authModalMode: "",
          views: {
            childUpdate: {
              loading: false,
              editMode: false,
            },
          },
          modal: {
            id: null,
            title: "",
            errorText: "",
          },
          appToApproveId: null,
          appTrainingSelectedForDecision: null,
          childForUpdateId: null,
        },
      },

      appLoaded: false,

      user: {
        id: 3002,
        personId: 3002,
        oid: 1065740661,
        userId: 61695,
        avatar: {
          file: "",
          type: "",
        },
        name: "",
        email: "",
      },

      offcanvas: {
        open: false,
        support: {
          open: false,
        },
      },

      sidebarNavList: [
        {
          id: 1,
          title: "Главная",
          type: "link",
          link: "/",
          forAuthUser: false,
          forUserType: "",
          icon: {
            src: "/icons/sidebar/home.svg",
          },
        },
        {
          id: 2,
          title: "Новости",
          type: "link",
          link: "/news",
          forAuthUser: false,
          forUserType: "",
          icon: {
            src: "/icons/sidebar/news.svg",
          },
        },
        {
          id: 3,
          title: "Справочная",
          type: "link",
          link: "/faq",
          forAuthUser: false,
          forUserType: "",
          icon: {
            src: "/icons/sidebar/inquiry.svg",
          },
        },
        {
          id: 4,
          title: "Календарь событий",
          type: "link",
          link: "/schedule",
          forAuthUser: false,
          forUserType: "",
          icon: {
            src: "/icons/sidebar/schedule.svg",
          },
        },
        {
          id: 5,
          title: "Реестр объектов спорта",
          type: "link",
          link: "/objects",
          forAuthUser: false,
          forUserType: "",
          icon: {
            src: "/icons/sidebar/objects.svg",
          },
        },
        // {
        //   id: 16,
        //   title: "Личный кабинет инвалида",
        //   type: "link",
        //   link: "/invalidaccount",
        //   forAuthUser: false,
        //   forUserType: "",
        //   icon: {
        //     src: "/icons/sidebar/invalid.svg",
        //   },
        // },
        {
          id: 6,
          title: "Личный кабинет физического лица",
          type: "link",
          link: "/personaccount",
          forAuthUser: true,
          forUserType: "person",
          icon: {
            src: "/icons/sidebar/person.svg",
          },
        },
        /*{
          id: 7,
          title: "Дети",
          type: "link",
          link: "/children",
          forAuthUser: false,
          icon: {
            src: "/icons/sidebar/children.svg",
          },
        },
        {
          id: 8,
          title: "Новый ребенок",
          type: "link",
          link: "/childnew",
          forAuthUser: false,
          icon: {
            src: "/icons/sidebar/children.svg",
          },
        },*/
        {
          id: 9,
          title: "Юридические лица",
          type: "link",
          link: "/organizations",
          forAuthUser: false,
          forUserType: "",
          icon: {
            src: "/icons/sidebar/entity.svg",
          },
        },
        /*{
          id: 10,
          title: "Выход",
          type: "link",
          link: "/",
          forAuthUser: false,
          icon: {
            src: "/icons/sidebar/exit.svg",
          },
        },*/
        /*{
          id: 11,
          title: "Test",
          type: "link",
          link: "/test",
          forAuthUser: false,
          icon: {
            src: "/icons/sidebar/home.svg",
          },
        },*/
        {
          id: 12,
          title: "Авторизация",
          type: "link",
          link: "/auth",
          forAuthUser: false,
          forUserType: "",
          icon: {
            src: "/icons/sidebar/home.svg",
          },
        },
        /*{
          id: 13,
          title: "Просмотр ребенка",
          type: "link",
          link: "/childupdate",
          forAuthUser: false,
          icon: {
            src: "/icons/sidebar/home.svg",
          },
        },*/
        {
          id: 14,
          title: "Паспорт юридического лица",
          type: "link",
          link: "/orgpassport",
          forAuthUser: true,
          forUserType: "organization",
          icon: {
            src: "/icons/sidebar/home.svg",
          },
        },
        /*{
          id: 15,
          title: "Событие",
          type: "link",
          link: "/event",
          forAuthUser: false,
          icon: {
            src: "/icons/sidebar/home.svg",
          },
        },
        {
          id: 16,
          title: "Просмотр юридического лица",
          type: "link",
          link: "/organization",
          forAuthUser: false,
          icon: {
            src: "/icons/sidebar/home.svg",
          },
        },
        {
          id: 17,
          title: "Новостная статья",
          type: "link",
          link: "/newsarticle",
          forAuthUser: false,
          icon: {
            src: "/icons/sidebar/home.svg",
          },
        },
        {
          id: 18,
          title: "Просмотр физического лица",
          type: "link",
          link: "/person",
          forAuthUser: false,
          icon: {
            src: "/icons/sidebar/person.svg",
          },
        },
        {
          id: 19,
          title: "Просмотр спортивного объекта",
          type: "link",
          link: "/stadium",
          forAuthUser: false,
          icon: {
            src: "/icons/sidebar/person.svg",
          },
        },*/
      ],

      dictionaries: {
        // Вшитые справочники
        orgRoles: [
          { id: "1", value: "1", label: "Спортсмен" },
          { id: "2", value: "2", label: "Тренер" },
          { id: "7", value: "7", label: "Сотрудник юридического лица" },
          { id: "8", value: "8", label: "Администратор юридического лица" },
        ],
        eventRoles: [
          { id: "1", value: "1", label: "Зритель" },
          { id: "2", value: "2", label: "Спортсмен" },
          { id: "3", value: "3", label: "Другой" },
        ],
        trainingAppealTypes: [
          { id: 1, value: 1, label: "Личное обращение" },
          { id: 2, value: 2, label: "Обращение родителя/опекуна" },
        ],
        genders: [
          { id: 1, value: 1, label: "Мужской" },
          { id: 2, value: 2, label: "Женский" },
        ],
        trainingStatuses: [
          { id: "1", value: "draft", label: "Черновик" },
          { id: "2", value: "waiting", label: "Ожидает" },
          { id: "3", value: "invitation", label: "Отправлено приглашение" },
          { id: "4", value: "confirmed", label: "Подтверждена заявителем" },
          { id: "5", value: "refused", label: "Заявитель отказался" },
          { id: "6", value: "review", label: "На рассмотрении" },
          { id: "7", value: "enrolled", label: "Зачислен" },
          { id: "8", value: "answered", label: "Отвечено" },
          { id: "9", value: "withdrawn", label: "Отозвано" },
        ],
        // Справочники, получаемые с сервера при старте приложения, если пользователь авторизован как ФЛ
        eventStatuses: [],
        identifyDocuments: [],
        educationGrades: [{ id: "", value: "", label: "" }],
        organizations: [{ id: "", value: "", label: "" }],
        allSports: [{ id: "", value: "", label: "" }],
        eventsForApp: [{ id: "", value: "", label: "" }],
        objectsForPublic: [{ id: "", value: "", label: "" }],
        // Справочники, получаемые с сервера по мере заполнения форм физлицом
        orgsForSportTraining: [{ id: "", value: "", label: "" }],
        // Данные о детях получены при запросе ЛК ФЛ и берутся из поля personAccountDB
        servicesForStadiumPublic: [{ id: "", value: "", label: "" }],
        // Справочники юрлица
        orgSports: [{ id: "", value: "", label: "" }],
      },

      calendar: {
        day: new Date(),
      },

      services: {
        // tabs: [
        //   {
        //     id: "persons-services",
        //     title: "Физ.лицам",
        //     active: true,
        //     image: { src: "/icons/forpersons.svg" },
        //   },
        //   {
        //     id: "sportsmans-services",
        //     title: "Спортсменам",
        //     active: false,
        //     image: { src: "/icons/forsportsmans.svg" },
        //   },
        //   {
        //     id: "parents-services",
        //     title: "Родителям",
        //     active: false,
        //     image: { src: "/icons/forparents.svg" },
        //   },
        // ],
        // cards: [
        //   {
        //     annotation: "",
        //     author: "",
        //     categories: ["Сервисы"],
        //     createDate: "",
        //     datePublish: "Записаться",
        //     id: "service-01",
        //     image: {
        //       src: "/images/services/01.jpg",
        //       type: "",
        //     },
        //     link: "/organizations",
        //     imagePosition: "",
        //     text: "",
        //     title:
        //       "Записаться на занятия спортом в спортивные организации края",
        //   },
        //   {
        //     annotation: "",
        //     author: "",
        //     categories: ["Сервисы"],
        //     createDate: "",
        //     datePublish: "Записаться",
        //     id: "service-02",
        //     image: {
        //       src: "/images/services/02.jpg",
        //       type: "",
        //     },
        //     link: "/objects",
        //     imagePosition: "",
        //     text: "",
        //     title: "Запись на занятия массовым спортом",
        //   },
        //   {
        //     annotation: "",
        //     author: "",
        //     categories: ["Сервисы"],
        //     createDate: "",
        //     datePublish: "Открыть реестр",
        //     id: "service-03",
        //     image: {
        //       src: "/images/services/03.jpg",
        //       type: "",
        //     },
        //     link: "/objects",
        //     imagePosition: "",
        //     text: "",
        //     title: "Реестр объектов спорта",
        //   },
        //   {
        //     annotation: "",
        //     author: "",
        //     categories: ["Сервисы"],
        //     createDate: "",
        //     datePublish: "Зарегистрироваться",
        //     id: "service-04",
        //     image: {
        //       src: "/images/services/04.jpg",
        //       type: "",
        //     },
        //     link: "/schedule",
        //     imagePosition: "",
        //     text: "",
        //     title:
        //       "Регистрация в качестве участника или зрителя физкультурного мероприятия",
        //   },
        // ],
      },

      // Новости
      news: {
        allNews: [],
        hotNews: [],
        table: {
          pagination: {
            itemsTotal: 0,
            page: 1,
            pageSize: 10,
            itemsPerPage: [10, 25, 50],
          },
        },
      },
      newsArticle: {
        loader: {
          isLoading: false,
          isResponse: false,
          comment: "Загрузка новостной статьи",
          theme: "secondary",
        },
        newsArticleData: {
          id: null,
          title: "",
          createDate: "",
          author: "",
          text: "",
        },
      },

      // Справочная
      faq: {
        id: "faqAccordion",
        items: [],
      },

      // Календарь событий
      eventsDB: {},
      events: {
        table: {
          id: "eventsRegistryTable",
          service: 1,
          searchField: "",
          columnsList: [
            { title: "id", name: "id", sorted: true },
            { title: "№ в КП", name: "number" },
            { title: "Наименование события", name: "name" },
            {
              title: "Сроки проведения",
              name: "dates",
            },
            { title: "Место проведения", name: "place" },
            { title: "Статус", name: "status" },
            { title: "", name: "otherInfo" },
          ],
          primaryColumn: "id",
          rowsList: [],
          sortColumn: "id",
          ascendingSortOrder: false,
          filters: [
            {
              id: "1",
              label: "Наименование",
              type: "input",
              subtype: "text",
              width: 12,
              responsive: "col-sm-4",
              horizontal: true,
              horizontalWidth: {
                label: {
                  width: 4,
                  responsive: "col-sm-5",
                },
                field: {
                  width: 8,
                  responsive: "col-sm-7",
                },
              },
              value: "",
            },
            {
              id: "2",
              label: "Вид спорта",
              type: "input",
              subtype: "text",
              width: 12,
              responsive: "col-sm-4",
              horizontal: true,
              horizontalWidth: {
                label: {
                  width: 4,
                  responsive: "col-sm-5",
                },
                field: {
                  width: 8,
                  responsive: "col-sm-7",
                },
              },
              value: "",
            },
            {
              id: "3",
              label: "Статус",
              type: "select",
              itemsList: [],
              width: 12,
              responsive: "col-sm-4",
              defaultValueLabel: "Выберите",
              horizontal: true,
              horizontalWidth: {
                label: {
                  width: 4,
                  responsive: "col-sm-5",
                },
                field: {
                  width: 8,
                  responsive: "col-sm-7",
                },
              },
              values: [],
            },
          ],
          pagination: {
            itemsTotal: 0,
            page: 1,
            pageSize: 10,
            itemsPerPage: [10, 25, 50],
          },
        },
      },
      eventsPosterDB: {},
      event: {
        id: null,
        loader: {
          isLoading: false,
          isResponse: false,
          comment: "Загрузка данных мероприятия",
          theme: "secondary",
        },
        tabs: [
          { id: "event-profile", title: "Общая информация", active: true },
          { id: "event-docs", title: "Документы", active: false },
          { id: "event-sports", title: "Дисциплины", active: false },
          { id: "event-persons", title: "Участники", active: false },
        ],
        basic: {
          forms: [
            {
              name: "generalForm",
              form: {
                title: "Общая информация",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "event-number",
                    label: "№ в календарном плане",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "event-name",
                    label: "Наименование события",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "event-group",
                    label: "Группа мероприятий",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "event-owner",
                    label: "Организатор события",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "placeForm",
              form: {
                title: "Дата и место проведения",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "event-place",
                    label: "Место проведения",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "event-app-deadline-date",
                    label: "Подача заявок До",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "event-start-date",
                    label: "Дата проведения С",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "event-finish-date",
                    label: "Дата проведения По",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
          ],
          sports: {
            table: {
              id: "eventSportsTable",
              service: 1,
              columnsList: [
                { title: "id", name: "id", sorted: true },
                { title: "№", name: "number" },
                { title: "Вид спорта", name: "sport" },
                { title: "Спортивная дисциплина", name: "discipline" },
              ],
              primaryColumn: "id",
              rowsList: [
                ["1", "1", "Альпинизм", "класс - высотно-технический"],
                ["2", "2", "Альпинизм", "класс - высотно-технический"],
                ["3", "3", "Альпинизм", "класс - высотно-технический"],
              ],
              sortColumn: "id",
              ascendingSortOrder: false,
              filters: [
                {
                  id: "1",
                  label: "№ сообщения",
                  type: "input",
                  subtype: "number",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "2",
                  label: "Дата создания сообщения",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " c" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "3",
                  label: "Тема",
                  type: "input",
                  subtype: "Тема",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "4",
                  label: "Дата входа в статус",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "5",
                  label: "Статус",
                  type: "select",
                  itemsList: [
                    { id: 1, text: "Черновик" },
                    { id: 2, text: "В работе" },
                    { id: 3, text: "Обработано" },
                    { id: 4, text: "Архивная" },
                  ],
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "6",
                  label: "Срок отправки ответа",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "7",
                  label: "Показать только архивные",
                  type: "checkbox",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
              ],
              pagination: {
                itemsTotal: 15,
                page: 1,
                pageSize: 10,
                itemsPerPage: [10, 25, 50],
              },
            },
          },
          groups: {
            table: {
              id: "eventGroupsTable",
              service: 1,
              columnsList: [
                { title: "id", name: "id", sorted: true },
                { title: "№", name: "number" },
                {
                  title: "Группа участников по полу и возрасту",
                  name: "group",
                },
                { title: "Количество участников", name: "count" },
              ],
              primaryColumn: "id",
              rowsList: [
                ["1", "1", "Мужчины до 18 лет", "класс - высотно-технический"],
                [
                  "2",
                  "2",
                  "Мужчины от 18 до 25 лет",
                  "класс - высотно-технический",
                ],
                [
                  "3",
                  "3",
                  "Мужчины от 25 до 35 лет",
                  "класс - высотно-технический",
                ],
              ],
              sortColumn: "id",
              ascendingSortOrder: false,
              filters: [
                {
                  id: "1",
                  label: "№ сообщения",
                  type: "input",
                  subtype: "number",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "2",
                  label: "Дата создания сообщения",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " c" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "3",
                  label: "Тема",
                  type: "input",
                  subtype: "Тема",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "4",
                  label: "Дата входа в статус",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "5",
                  label: "Статус",
                  type: "select",
                  itemsList: [
                    { id: 1, text: "Черновик" },
                    { id: 2, text: "В работе" },
                    { id: 3, text: "Обработано" },
                    { id: 4, text: "Архивная" },
                  ],
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "6",
                  label: "Срок отправки ответа",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "7",
                  label: "Показать только архивные",
                  type: "checkbox",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
              ],
              pagination: {
                itemsTotal: 15,
                page: 1,
                pageSize: 10,
                itemsPerPage: [10, 25, 50],
              },
            },
          },
        },
        documents: [
          { id: 1, name: "Наименование документа" },
          { id: 2, name: "Устав организации" },
          { id: 3, name: "Нормативы физической подготовки" },
          { id: 4, name: "Правила посещения" },
        ],
        disciplines: {
          sports: {
            table: {
              id: "eventDisciplinesSportsTable",
              service: 1,
              columnsList: [
                { title: "id", name: "id", sorted: true },
                { title: "№", name: "number" },
                { title: "Наименование дисциплины", name: "discipline" },
                {
                  title: "Группа участников по полу и возрасту",
                  name: "group",
                },
              ],
              primaryColumn: "id",
              rowsList: [
                ["1", "1", "класс - высотно-технический", "М до 18 лет"],
                ["2", "2", "класс - высотно-технический", "М от 18 до 25 лет"],
                ["3", "3", "класс - высотно-технический", "М от 25 до 35 лет"],
              ],
              sortColumn: "id",
              ascendingSortOrder: false,
              selectable: true,
              selectedRowPrimaryValue: null,
              filters: [
                {
                  id: "1",
                  label: "№ сообщения",
                  type: "input",
                  subtype: "number",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "2",
                  label: "Дата создания сообщения",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " c" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "3",
                  label: "Тема",
                  type: "input",
                  subtype: "Тема",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "4",
                  label: "Дата входа в статус",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "5",
                  label: "Статус",
                  type: "select",
                  itemsList: [
                    { id: 1, text: "Черновик" },
                    { id: 2, text: "В работе" },
                    { id: 3, text: "Обработано" },
                    { id: 4, text: "Архивная" },
                  ],
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "6",
                  label: "Срок отправки ответа",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "7",
                  label: "Показать только архивные",
                  type: "checkbox",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
              ],
              pagination: {
                itemsTotal: 15,
                page: 1,
                pageSize: 10,
                itemsPerPage: [10, 25, 50],
              },
            },
          },
          docs: [
            { id: 1, name: "Протокол «Альпинизм. Мужчины до 18 лет»" },
            { id: 2, name: "Протокол «Альпинизм. Мужчины от 18 до 25 лет" },
            { id: 3, name: "Протокол «Альпинизм. Мужчины от 25 до 35 лет" },
          ],
          referee: {
            allEventRefereeRowsList: [],
            table: {
              id: "eventRefereeTable",
              service: 1,
              columnsList: [
                { title: "id", name: "id", sorted: true },
                { title: "№", name: "number" },
                { title: "ФИО", name: "fullName" },
                { title: "Функциональная нагрузка", name: "grade" },
              ],
              primaryColumn: "id",
              rowsList: [],
              sortColumn: "id",
              ascendingSortOrder: false,
              filters: [
                {
                  id: "1",
                  label: "№ сообщения",
                  type: "input",
                  subtype: "number",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "2",
                  label: "Дата создания сообщения",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " c" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "3",
                  label: "Тема",
                  type: "input",
                  subtype: "Тема",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "4",
                  label: "Дата входа в статус",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "5",
                  label: "Статус",
                  type: "select",
                  itemsList: [
                    { id: 1, text: "Черновик" },
                    { id: 2, text: "В работе" },
                    { id: 3, text: "Обработано" },
                    { id: 4, text: "Архивная" },
                  ],
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "6",
                  label: "Срок отправки ответа",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "7",
                  label: "Показать только архивные",
                  type: "checkbox",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
              ],
              pagination: {
                itemsTotal: 15,
                page: 1,
                pageSize: 10,
                itemsPerPage: [10, 25, 50],
              },
            },
          },
          sportsmans: {
            allSportsmanRowsList: [],
            table: {
              id: "disciplineSportsmansTable",
              service: 1,
              columnsList: [
                { title: "id", name: "id", sorted: true },
                { title: "№", name: "number" },
                { title: "ФИО", name: "fullName" },
                { title: "Год рождения", name: "birth" },
                { title: "Место", name: "place" },
                { title: "Измеренный результат", name: "result" },
                { title: "Тип рекорда", name: "resultType" },
                { title: "Дата установления", name: "recordDate" },
              ],
              primaryColumn: "id",
              rowsList: [
                [
                  "1",
                  "1",
                  "Бондаренко Илья Алексеевич",
                  "2005",
                  "тестовое значение",
                  "1-е",
                  "-",
                  "-",
                ],
                [
                  "2",
                  "2",
                  "Бакаев Артем Дмитриевич",
                  "2005",
                  "тестовое значение",
                  "2-е",
                  "Лучшее время прохождения дистанции",
                  "11.10.2022",
                ],
                [
                  "3",
                  "3",
                  "Ткаченко Игорь Сергеевич",
                  "2005",
                  "тестовое значение",
                  "3-е",
                  "-",
                  "-",
                ],
              ],
              sortColumn: "id",
              ascendingSortOrder: false,
              filters: [
                {
                  id: "1",
                  label: "№ сообщения",
                  type: "input",
                  subtype: "number",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "2",
                  label: "Дата создания сообщения",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " c" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "3",
                  label: "Тема",
                  type: "input",
                  subtype: "Тема",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "4",
                  label: "Дата входа в статус",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "5",
                  label: "Статус",
                  type: "select",
                  itemsList: [
                    { id: 1, text: "Черновик" },
                    { id: 2, text: "В работе" },
                    { id: 3, text: "Обработано" },
                    { id: 4, text: "Архивная" },
                  ],
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "6",
                  label: "Срок отправки ответа",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "7",
                  label: "Показать только архивные",
                  type: "checkbox",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
              ],
              pagination: {
                itemsTotal: 15,
                page: 1,
                pageSize: 10,
                itemsPerPage: [10, 25, 50],
              },
            },
          },
          selectedDisciplineId: null,
        },
        participants: {
          table: {
            id: "eventParticipantsTable",
            service: 1,
            columnsList: [
              { title: "id", name: "id", sorted: true },
              { title: "№", name: "number" },
              { title: "ФИО", name: "fullName" },
              { title: "Год рождения", name: "birth" },
              { title: "Дисциплины", name: "discipline" },
            ],
            primaryColumn: "id",
            rowsList: [
              [
                "1",
                "1",
                "Бондаренко Илья Алексеевич",
                "2005",
                "класс - высотно-технический",
              ],
              [
                "2",
                "2",
                "Бакаев Артем Дмитриевич",
                "2005",
                "класс - высотно-технический",
              ],
              [
                "3",
                "3",
                "Ткаченко Игорь Сергеевич",
                "2005",
                "класс - высотно-технический",
              ],
            ],
            sortColumn: "id",
            ascendingSortOrder: false,
            filters: [
              {
                id: "1",
                label: "№ сообщения",
                type: "input",
                subtype: "number",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "2",
                label: "Дата создания сообщения",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " c" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "3",
                label: "Тема",
                type: "input",
                subtype: "Тема",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "4",
                label: "Дата входа в статус",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "5",
                label: "Статус",
                type: "select",
                itemsList: [
                  { id: 1, text: "Черновик" },
                  { id: 2, text: "В работе" },
                  { id: 3, text: "Обработано" },
                  { id: 4, text: "Архивная" },
                ],
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "6",
                label: "Срок отправки ответа",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "7",
                label: "Показать только архивные",
                type: "checkbox",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
            ],
            pagination: {
              itemsTotal: 15,
              page: 1,
              pageSize: 10,
              itemsPerPage: [10, 25, 50],
            },
          },
        },
        apps: {
          table: {
            id: "eventAppsTable",
            service: 1,
            columnsList: [
              { title: "id", name: "id", sorted: true },
              { title: "№", name: "appId" },
              { title: "ФИО заявителя", name: "sender" },
              { title: "Дата подачи", name: "startAppDate" },
              { title: "Статус участника", name: "role" },
              { title: "Статус рассмотрения", name: "status" },
              { title: "Наименование события", name: "orgEvent" },
              { title: "", name: "otherInfo" },
            ],
            primaryColumn: "id",
            rowsList: [
              [
                "1",
                "1",
                "Сергей Игоревич Иванов",
                "15.09.2022",
                "Спортсмен",
                "Одобрена",
                "Чемпионат Красноярского края по хоккею",
                "",
              ],
              [
                "2",
                "2",
                "Сергей Игоревич Иванов",
                "17.03.2022",
                "Спортсмен",
                "На согласовании",
                "Чемпионат Красноярского края по хоккею",
                "Отозвать заявку",
              ],
              [
                "3",
                "3",
                "Сергей Игоревич Иванов",
                "01.03.2022",
                "Спортсмен",
                "Отказано",
                "Чемпионат Красноярского края по хоккею",
                "",
              ],
            ],
            sortColumn: "id",
            ascendingSortOrder: false,
            filters: [
              {
                id: "1",
                label: "№ сообщения",
                type: "input",
                subtype: "number",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "2",
                label: "Дата создания сообщения",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " c" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "3",
                label: "Тема",
                type: "input",
                subtype: "Тема",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "4",
                label: "Дата входа в статус",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "5",
                label: "Статус",
                type: "select",
                itemsList: [
                  { id: 1, text: "Черновик" },
                  { id: 2, text: "В работе" },
                  { id: 3, text: "Обработано" },
                  { id: 4, text: "Архивная" },
                ],
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "6",
                label: "Срок отправки ответа",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "7",
                label: "Показать только архивные",
                type: "checkbox",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
            ],
            pagination: {
              itemsTotal: 15,
              page: 1,
              pageSize: 10,
              itemsPerPage: [10, 25, 50],
            },
          },
        },
      },

      // Объекты спорта
      objects: {
        table: {
          id: "stadiumsTable",
          service: 1,
          searchField: "",
          columnsList: [
            { title: "id", name: "id", sorted: true },
            { title: "Общая информация", name: "basic" },
            { title: "", name: "contacts" },
            {
              title: "Тип спортсооружений, входящих в состав объекта",
              name: "buildings",
            },
            { title: "Виды спорта", name: "sports" },
          ],
          primaryColumn: "id",
          rowsList: [],
          sortColumn: "id",
          ascendingSortOrder: false,
          filters: [
            {
              id: "1",
              label: "Наименование",
              type: "input",
              subtype: "text",
              width: 12,
              responsive: "col-sm-4",
              horizontal: true,
              horizontalWidth: {
                label: {
                  width: 4,
                  responsive: "col-sm-5",
                },
                field: {
                  width: 8,
                  responsive: "col-sm-7",
                },
              },
              value: "",
            },
            {
              id: "2",
              label: "Вид спорта",
              type: "input",
              subtype: "text",
              width: 12,
              responsive: "col-sm-4",
              horizontal: true,
              horizontalWidth: {
                label: {
                  width: 4,
                  responsive: "col-sm-5",
                },
                field: {
                  width: 8,
                  responsive: "col-sm-7",
                },
              },
              value: "",
            },
          ],
          pagination: {
            itemsTotal: 0,
            page: 1,
            pageSize: 10,
            itemsPerPage: [10, 25, 50],
          },
        },
      },
      stadiumDB: {},
      stadium: {
        loader: {
          isLoading: false,
          isResponse: false,
          comment: "Загрузка данных спортивного объекта",
          theme: "secondary",
        },
        data: {
          imageFile: "",
          imageType: "",
        },
        objectAppServiceses: [],
        objectParts: [],
        objectSchedules: [],
        objectServices: [],
        objectSportTypes: [],
        visitRules: {
          visitRules: [],
          visitRuleFiles: [],
        },

        apps: {
          table: {
            id: "publicAppsTable",
            service: 1,
            columnsList: [
              { title: "id", name: "id", sorted: true },
              { title: "№", name: "appId" },
              { title: "Отправитель", name: "sender" },
              { title: "Дата подачи", name: "startAppDate" },
              { title: "Роль", name: "role" },
              { title: "Статус рассмотрения", name: "status" },
              { title: "Наименование организации", name: "orgEvent" },
              { title: "", name: "otherInfo" },
            ],
            primaryColumn: "id",
            rowsList: [
              [
                "1",
                "1",
                "Сергей Игоревич Иванов",
                "15.09.2022",
                "Спортсмен",
                "Одобрена",
                "МАУ «Спортивная школа олимпийского резерва «Здоровый мир»",
                "Подробнее",
              ],
              [
                "2",
                "2",
                "Сергей Игоревич Иванов",
                "17.03.2022",
                "Организатор",
                "На согласовании",
                "Чемпионат Красноярского края по хоккею",
                "Подробнее",
              ],
              [
                "3",
                "3",
                "Сергей Игоревич Иванов",
                "01.03.2022",
                "Организатор",
                "Отказано",
                "Чемпионат Красноярского края по хоккею",
                "Подробнее",
              ],
            ],
            sortColumn: "id",
            ascendingSortOrder: false,
            filters: [
              {
                id: "1",
                label: "№ сообщения",
                type: "input",
                subtype: "number",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "2",
                label: "Дата создания сообщения",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " c" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "3",
                label: "Тема",
                type: "input",
                subtype: "Тема",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "4",
                label: "Дата входа в статус",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "5",
                label: "Статус",
                type: "select",
                itemsList: [
                  { id: 1, text: "Черновик" },
                  { id: 2, text: "В работе" },
                  { id: 3, text: "Обработано" },
                  { id: 4, text: "Архивная" },
                ],
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "6",
                label: "Срок отправки ответа",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "7",
                label: "Показать только архивные",
                type: "checkbox",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
            ],
            pagination: {
              itemsTotal: 15,
              page: 1,
              pageSize: 10,
              itemsPerPage: [10, 25, 50],
            },
          },
        },
      },

      // Юридические лица
      organizationsDB: {},
      organizations: {
        table: {
          id: "organizationsRegistryTable",
          service: 1,
          searchField: "",
          columnsList: [
            { title: "id", name: "id", sorted: true },
            { title: "Наименование", name: "orgName" },
            { title: "Юридический адрес", name: "legalAddress" },
            { title: "Виды спорта", name: "sportType" },
            { title: "Статус юр.лица", name: "orgStatus" },
          ],
          primaryColumn: "id",
          rowsList: [
            [
              "1",
              "КГБУ «Региональный центр спортивной подготовки по адаптивным видам спорта»",
              "г. Красноярск, ул. Свердловская, 49г",
              "Спорт глухих (тхэквондо, волейбол), Спорт слепых (велосипедный спорт, голбол, дзюдо, легкая атлетика, футбол, каратэ), Спорт лиц с ПОДА (бочча, парасноуборд, баскетбол на колясках, волейбол сидя, конный спорт, горные лыжи, керлинг на колясках, лыжные гонки и биатлон, настольный теннис, пауэрлифтинг, плавание, следж хоккей, стрельба из лука, армспорт)",
              "Спортивный клуб",
            ],
            [
              "2",
              "МАУ «Спортивная школа олимпийского резерва «Здоровый мир»",
              "г. Красноярск, ул. ул. Пархоменко, 7",
              "гребной слалом, триатлон, пауэрлифтинг, конный спорт, синхронное плавание",
              "Физкультурно-спортивная организация",
            ],
            [
              "3",
              "МАУ «Спортивная школа олимпийского резерва по вольной борьбе»",
              "г. Красноярск, ул. Ломоносова, 14",
              "вольная борьба",
              "Физкультурно-спортивная организация",
            ],
            [
              "4",
              "Бадминтонный клуб «Енисей»",
              "г. Красноярск,ул. 60 лет образования СССР, дом. 17 (спорткомплекс «Солнечный»)",
              "бадминтон",
              "Спортивный клуб",
            ],
            [
              "5",
              "КГБУ «Региональный центр спортивной подготовки по адаптивным видам спорта»",
              "г. Красноярск, ул. Свердловская, 49г",
              "Спорт глухих (тхэквондо, волейбол), Спорт слепых (велосипедный спорт, голбол, дзюдо, легкая атлетика, футбол, каратэ), Спорт лиц с ПОДА (бочча, парасноуборд, баскетбол на колясках, волейбол сидя, конный спорт, горные лыжи, керлинг на колясках, лыжные гонки и биатлон, настольный теннис, пауэрлифтинг, плавание, следж хоккей, стрельба из лука, армспорт)",
              "Спортивный клуб",
            ],
            [
              "6",
              "МАУ «Спортивная школа олимпийского резерва «Здоровый мир»",
              "г. Красноярск, ул. ул. Пархоменко, 7",
              "гребной слалом, триатлон, пауэрлифтинг, конный спорт, синхронное плавание",
              "Физкультурно-спортивная организация",
            ],
          ],
          sortColumn: "id",
          ascendingSortOrder: false,
          filters: [
            {
              id: "1",
              label: "Наименование",
              type: "input",
              subtype: "text",
              width: 12,
              responsive: "col-sm-4",
              horizontal: true,
              horizontalWidth: {
                label: {
                  width: 4,
                  responsive: "col-sm-5",
                },
                field: {
                  width: 8,
                  responsive: "col-sm-7",
                },
              },
              value: "",
            },
            {
              id: "2",
              label: "Вид спорта",
              type: "input",
              subtype: "text",
              width: 12,
              responsive: "col-sm-4",
              horizontal: true,
              horizontalWidth: {
                label: {
                  width: 4,
                  responsive: "col-sm-5",
                },
                field: {
                  width: 8,
                  responsive: "col-sm-7",
                },
              },
              value: "",
            },
          ],
          pagination: {
            itemsTotal: 0,
            page: 1,
            pageSize: 10,
            itemsPerPage: [10, 25, 50],
          },
        },
      },
      organizationDB: {},
      organization: {
        loader: {
          isLoading: false,
          isResponse: false,
          comment: "Загрузка данных юридического лица",
          theme: "secondary",
        },
        tabs: [
          {
            id: "organization-profile",
            title: "Общая информация",
            active: true,
          },
          {
            id: "organization-activities",
            title: "Виды деятельности",
            active: false,
          },
          { id: "organization-sports", title: "Виды спорта", active: false },
          { id: "organization-docs", title: "Документы", active: false },
          {
            id: "organization-requirements",
            title: "Требования",
            active: false,
          },
        ],
        profile: {
          forms: [
            {
              name: "orgNameForm",
              form: {
                title: "Информация о юридическом лице",
                width: 12,
                responsive: "col-sm-12",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-lg-3",
                  },
                  field: {
                    width: 8,
                    responsive: "col-lg-9",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "organization-full-name",
                    label: "Полное наименование",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-short-name",
                    label: "Сокращенное наименование",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-type",
                    label: "Тип юр.лица",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-form",
                    label: "Организационно-правовая форма",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "contactForm",
              form: {
                title: "Контактная информация",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "organization-phone",
                    label: "Контактный телефон",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-site",
                    label: "Интернет-сайт",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-email",
                    label: "Электронная почта",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-legal-address",
                    label: "Юридический адрес",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-fact-address",
                    label: "Фактический адрес",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "addressForm",
              form: {
                title: "Учредитель",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "organization-owner-type",
                    label: "Тип учредителя",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-owner",
                    label: "Учредитель",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-boss-fullname",
                    label: "ФИО руководителя",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "requisitesForm",
              form: {
                title: "Реквизиты",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "organization-inn",
                    label: "ИНН",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-kpp",
                    label: "КПП",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-ogrn",
                    label: "ОГРН",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-oktmo",
                    label: "ОКТМО",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "bankDetailsForm",
              form: {
                title: "Платежные реквизиты",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "organization-bank-name",
                    label: "Наименование банка",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-bank-inn",
                    label: "ИНН банка",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-bank-kpp",
                    label: "КПП банка",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-bank-bik",
                    label: "БИК банка",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-bank-pay-account",
                    label: "Номер расчетного счета",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-bank-cor-account",
                    label: "Номер корр. счета",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
          ],
        },
        activities: [
          {
            id: 1,
            title:
              "84.11.11: Деятельность федеральных органов государственной власти, кроме полномочных представителей Президента Российской Федерации и территориальных органов федеральных органов исполнительной власти",
            description:
              "Равным образом новая модель организационной деятельности играет важную роль в формировании системы обучения кадров, соответствует насущным потребностям.",
          },
          {
            id: 2,
            title: "93.11: Деятельность спортивных объектов",
            description:
              "Таким образом, перспективное планирование предоставляет широкие возможности для экономической целесообразности принимаемых решений. Но представители современных социальных резервов неоднозначны и будут подвергнуты целой серии независимых исследований.",
          },
          {
            id: 3,
            title: "93.29.1: Деятельность парков отдыха и пляжей",
            description:
              "Но представители современных социальных резервов неоднозначны и будут подвергнуты целой серии независимых исследований. Как принято считать, сторонники тоталитаризма в науке могут быть своевременно верифицированы.",
          },
        ],
        workers: [
          {
            id: 1,
            fullName: "Аршинцев Валерий Олегович",
            position: "Тренер",
            isAdmin: false,
            photo: {
              src: "/images/default.jpg",
              fileEntryId: "",
            },
          },
          {
            id: 2,
            fullName: "Полипович Сергей Андреевич",
            position: "Руководитель",
            isAdmin: true,
            photo: {
              src: "/images/default.jpg",
              fileEntryId: "",
            },
          },
          {
            id: 3,
            fullName: "Петров Сергей Иванович",
            position: "Администратор",
            isAdmin: false,
            photo: {
              src: "/images/default_dark.jpg",
              fileEntryId: "",
            },
          },
        ],
        sports: [
          { id: 1, name: "Хоккей на траве", discipline: "Мини хоккей с мячом" },
          {
            id: 2,
            name: "Водно-моторный спорт (общероссийские)",
            discipline: "Аквабайк - ранэбаут GP1 кольцевые гонки",
          },
          {
            id: 3,
            name: "Гребля на байдарках и каноэ (общероссийские)",
            discipline: "Д- 10 дистанция 2000 смешанный",
          },
        ],
        documents: [
          {
            id: 1,
            name: "Наименование документа",
            title: "Имя документа 1",
            fileEntryId: "",
          },
          {
            id: 2,
            name: "Устав организации",
            title: "Имя документа 2",
            fileEntryId: "",
          },
          {
            id: 3,
            name: "Нормативы физической подготовки",
            title: "Имя документа 3",
            fileEntryId: "",
          },
          {
            id: 4,
            name: "Правила посещения",
            title: "Имя документа 4",
            fileEntryId: "",
          },
        ],
        apps: {
          table: {
            id: "organizationAppsTable",
            service: 1,
            columnsList: [
              { title: "id", name: "id", sorted: true },
              { title: "№", name: "appNum" },
              { title: "Отправитель", name: "appPerson" },
              { title: "Роль", name: "role" },
              { title: "Дата создания", name: "startAppDate" },
              { title: "Организация", name: "organization" },
              { title: "Статус рассмотрения", name: "status" },
            ],
            primaryColumn: "id",
            rowsList: [
              [
                "1",
                "1",
                "Питаева Ирина Львовна",
                "Спортсмен",
                "15.09.2022",
                "АНО «Исполнительная дирекция молодежного чемпионата мира по хоккею 2023 год",
                "Одобрена",
              ],
              [
                "2",
                "2",
                "Балясников Владимир Андреевич",
                "Тренер",
                "17.03.2022",
                "АНО «Исполнительная дирекция молодежного чемпионата мира по хоккею 2023 год",
                "Отказано",
              ],
              [
                "3",
                "3",
                "Полипович Сергей Андреевич",
                "Тренер",
                "01.03.2022",
                "АНО «Исполнительная дирекция молодежного чемпионата мира по хоккею 2023 год",
                "На согласовании",
              ],
            ],
            sortColumn: "id",
            ascendingSortOrder: false,
            filters: [
              {
                id: "1",
                label: "№ сообщения",
                type: "input",
                subtype: "number",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "2",
                label: "Дата создания сообщения",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " c" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "3",
                label: "Тема",
                type: "input",
                subtype: "Тема",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "4",
                label: "Дата входа в статус",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "5",
                label: "Статус",
                type: "select",
                itemsList: [
                  { id: 1, text: "Черновик" },
                  { id: 2, text: "В работе" },
                  { id: 3, text: "Обработано" },
                  { id: 4, text: "Архивная" },
                ],
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "6",
                label: "Срок отправки ответа",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "7",
                label: "Показать только архивные",
                type: "checkbox",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
            ],
            pagination: {
              itemsTotal: 15,
              page: 1,
              pageSize: 10,
              itemsPerPage: [10, 25, 50],
            },
          },
        },
        requirements: {
          description: "",
          files: [],
        },
      },

      children: {
        table: {
          id: "childrenRegistryTable",
          service: 1,
          columnsList: [
            { title: "id", name: "id", sorted: true },
            { title: "Фото", name: "photo", sorted: true },
            { title: "ФИО", name: "fullName", sorted: true },
            { title: "Пол", name: "gender" },
            { title: "Дата рождения", name: "birthday", sorted: true },
            { title: "Адрес регистрации", name: "registrationAddress" },
            { title: "Организация", name: "organization" },
            { title: "", name: "otherInfo" },
          ],
          primaryColumn: "id",
          rowsList: [
            [
              "1",
              "",
              "Савиров Сергей Юрьевич",
              "Мужской",
              "03.11.2005",
              "г. Красноярск, ул. Мира, д. 13, кв. 73",
              "МАУ «Спортивная школа олимпийского резерва «Здоровый мир»",
              "",
            ],
            [
              "2",
              "",
              "Яковлева Лилия Сергеевна",
              "Женский",
              "16.09.2009",
              "Красноярский край, г. Норильск, площадь Газовиков Заполярья, д. 3/1, кв. 37 ",
              "МАУ «Спортивная школа олимпийского резерва по вольной борьбе»",
              "",
            ],
          ],
          sortColumn: "id",
          ascendingSortOrder: false,
          filters: [
            {
              id: "1",
              label: "№ сообщения",
              type: "input",
              subtype: "number",
              width: 12,
              responsive: "col-sm-4 col-md-3 col-lg-2",
            },
            {
              id: "2",
              label: "Дата создания сообщения",
              type: "range",
              subtype: "date",
              itemsList: [{ label: " c" }, { label: " по" }],
              width: 12,
              responsive: "col-sm-8 col-md-6 col-lg-4",
            },
            {
              id: "3",
              label: "Тема",
              type: "input",
              subtype: "Тема",
              width: 12,
              responsive: "col-sm-4 col-md-3 col-lg-2",
            },
            {
              id: "4",
              label: "Дата входа в статус",
              type: "range",
              subtype: "date",
              itemsList: [{ label: " с" }, { label: " по" }],
              width: 12,
              responsive: "col-sm-8 col-md-6 col-lg-4",
            },
            {
              id: "5",
              label: "Статус",
              type: "select",
              itemsList: [
                { id: 1, text: "Черновик" },
                { id: 2, text: "В работе" },
                { id: 3, text: "Обработано" },
                { id: 4, text: "Архивная" },
              ],
              width: 12,
              responsive: "col-sm-4 col-md-3 col-lg-2",
            },
            {
              id: "6",
              label: "Срок отправки ответа",
              type: "range",
              subtype: "date",
              itemsList: [{ label: " с" }, { label: " по" }],
              width: 12,
              responsive: "col-sm-8 col-md-6 col-lg-4",
            },
            {
              id: "7",
              label: "Показать только архивные",
              type: "checkbox",
              width: 12,
              responsive: "col-sm-4 col-md-3 col-lg-2",
            },
          ],
          pagination: {
            itemsTotal: 15,
            page: 1,
            pageSize: 10,
            itemsPerPage: [10, 25, 50],
          },
        },
      },
      childCreateData: [
        {
          name: "fioForm",
          form: {
            title: "ФИО",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "new-child-last-name",
                label: "Фамилия",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "new-child-first-name",
                label: "Имя",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "new-child-middle-name",
                label: "Отчество",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
            ],
          },
        },
        {
          name: "birthForm",
          form: {
            title: "Пол и дата рождения",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "new-child-gender",
                label: "Пол",
                type: "select",
                itemsList: [],
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "new-child-birth-day",
                label: "Дата рождения",
                type: "input",
                subtype: "date",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "new-child-birth-place",
                label: "Место рождения",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
            ],
          },
        },
        {
          name: "citizenshipForm",
          form: {
            title: "Гражданство",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "new-child-country",
                label: "Гражданство ребенка",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "new-child-region",
                label: "Субъект",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "new-child-city",
                label: "Населенный пункт",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
            ],
          },
        },
        {
          name: "identifyForm",
          form: {
            title: "Удостоверение личности",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "new-child-doc-type",
                label: "Тип документа, удостоверяющего личность",
                type: "select",
                itemsList: [],
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "new-child-doc-series",
                label: "Серия",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "new-child-doc-number",
                label: "Номер",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "new-child-doc-issue-date",
                label: "Дата выдачи",
                type: "input",
                subtype: "date",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "new-child-doc-issuer",
                label: "Кем выдан",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "new-child-doc-issuer-code",
                label: "Код подразделения/номер актовой записи",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
            ],
          },
        },
        {
          name: "registrationForm",
          form: {
            title: "Сведения о регистрации",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "new-child-legal-address",
                label: "Адрес регистрации",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "new-child-living-address",
                label: "Почтовый адрес",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
            ],
          },
        },
        {
          name: "snilsForm",
          form: {
            title: "СНИЛС (при наличии)",
            validity: true,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "new-child-snils",
                label: "Идентификационный номер",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
            ],
          },
        },
        {
          name: "contactForm",
          form: {
            title: "Контактная информация",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "new-child-phone-child",
                label: "Телефон ребенка",
                type: "input",
                subtype: "phone",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "new-child-phone-parent",
                label: "Телефон законного представителя",
                type: "input",
                subtype: "phone",
                width: 12,
                responsive: "",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "new-child-email",
                label: "Адрес электронной почты",
                type: "input",
                subtype: "email",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "new-child-notification",
                label: "Отправлять уведомление на электронную почту",
                type: "checkbox",
                width: 12,
                responsive: "",
                horizontal: false,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: false,
              },
            ],
          },
        },
      ],
      childUpdateData: [
        {
          name: "fioFormUpdate",
          form: {
            title: "ФИО",
            validity: true,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            disabled: true,
            fields: [
              {
                id: "update-child-last-name",
                label: "Фамилия",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "update-child-first-name",
                label: "Имя",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "update-child-middle-name",
                label: "Отчество",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
            ],
          },
        },
        {
          name: "birthFormUpdate",
          form: {
            title: "Пол и дата рождения",
            validity: true,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            disabled: true,
            fields: [
              {
                id: "update-child-gender",
                label: "Пол",
                type: "select",
                itemsList: [],
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "update-child-birth-day",
                label: "Дата рождения",
                type: "input",
                subtype: "date",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "update-child-birth-place",
                label: "Место рождения",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
            ],
          },
        },
        {
          name: "citizenshipFormUpdate",
          form: {
            title: "Гражданство",
            validity: true,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            disabled: true,
            fields: [
              {
                id: "update-child-country",
                label: "Гражданство ребенка",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "update-child-region",
                label: "Субъект",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "update-child-city",
                label: "Населенный пункт",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
            ],
          },
        },
        {
          name: "identifyFormUpdate",
          form: {
            title: "Удостоверение личности",
            validity: true,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            disabled: true,
            fields: [
              {
                id: "update-child-doc-type",
                label: "Тип документа, удостоверяющего личность",
                type: "select",
                itemsList: [],
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "update-child-doc-series",
                label: "Серия",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "update-child-doc-number",
                label: "Номер",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "update-child-doc-issue-date",
                label: "Дата выдачи",
                type: "input",
                subtype: "date",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "update-child-doc-issuer",
                label: "Кем выдан",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "update-child-doc-issuer-code",
                label: "Код подразделения/номер актовой записи",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
            ],
          },
        },
        {
          name: "registrationFormUpdate",
          form: {
            title: "Сведения о регистрации",
            validity: true,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            disabled: true,
            fields: [
              {
                id: "update-child-legal-address",
                label: "Адрес регистрации",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "update-child-living-address",
                label: "Почтовый адрес",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
            ],
          },
        },
        {
          name: "snilsFormUpdate",
          form: {
            title: "СНИЛС (при наличии)",
            validity: true,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            disabled: true,
            fields: [
              {
                id: "update-child-snils",
                label: "Идентификационный номер",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
            ],
          },
        },
        {
          name: "contactFormUpdate",
          form: {
            title: "Контактная информация",
            validity: true,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            disabled: true,
            fields: [
              {
                id: "update-child-phone-child",
                label: "Телефон ребенка",
                type: "input",
                subtype: "phone",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "update-child-phone-parent",
                label: "Телефон законного представителя",
                type: "input",
                subtype: "phone",
                width: 12,
                responsive: "",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "update-child-email",
                label: "Адрес электронной почты",
                type: "input",
                subtype: "email",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "update-child-notification",
                label: "Отправлять уведомление на электронную почту",
                type: "checkbox",
                width: 12,
                responsive: "",
                horizontal: false,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: false,
              },
            ],
          },
        },
      ],

      // Физические лица
      // Информация по выбранному физлицу
      personDB: {},
      person: {
        tabs: [
          { id: "person-profile", title: "Личные данные", active: true },
          { id: "person-docs", title: "Документы", active: false },
          { id: "person-education", title: "Образование", active: false },
          { id: "person-apps", title: "Заявки", active: false },
          {
            id: "person-training",
            title: "Спортивная подготовка",
            active: false,
          },
          { id: "person-mass", title: "Массовый спорт", active: false },
          { id: "person-children", title: "Дети", active: false },
        ],
        profile: {
          forms: [
            {
              name: "fioForm",
              form: {
                title: "ФИО",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-family-name",
                    label: "Фамилия",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-first-name",
                    label: "Имя",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-middle-name",
                    label: "Отчество",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "birthForm",
              form: {
                title: "Пол и дата рождения",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-gender",
                    label: "Пол",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-birth-date",
                    label: "Дата рождения",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-birth-place",
                    label: "Место рождения",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "citizenshipForm",
              form: {
                title: "Гражданство",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-citizenship",
                    label: "Гражданство",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-region",
                    label: "Субъект",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-city",
                    label: "Населенный пункт",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "contactForm",
              form: {
                title: "Контактная информация",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-phone",
                    label: "Контактный номер телефона",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "registrationForm",
              form: {
                title: "Сведения о регистрации",
                width: 12,
                responsive: "col-sm-12",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-5",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-registration-address",
                    label: "Адрес регистрации",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-3",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-9",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-fact-address",
                    label: "Почтовый адрес",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-3",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-9",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
          ],
        },
        docs: {
          forms: [
            {
              name: "identifyForm",
              form: {
                title: "Удостоверение личности",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-doc-type",
                    label: "Тип документа",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-doc-series",
                    label: "Серия",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-doc-number",
                    label: "Номер",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-doc-issue-date",
                    label: "Дата выдачи",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-doc-issuer",
                    label: "Кем выдан",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-doc-code",
                    label: "Номер актовой записи",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "innForm",
              form: {
                title: "ИНН",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-doc-inn",
                    label: "Идентификационный номер",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "snilsForm",
              form: {
                title: "СНИЛС (при наличии)",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-doc-snils",
                    label: "Номер",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
          ],
        },
        education: {
          forms: [
            {
              name: "identifyForm",
              form: {
                title: "Сведения об общем образовании",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-education-level",
                    label: "Образовательное учреждение",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-education-name",
                    label: "Наименование учреждения",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-education-start-year",
                    label: "Год поступления",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-education-finish-year",
                    label: "Год окончания",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
          ],
        },
        apps: {
          orgApps: {
            table: {
              id: "personAppsTable",
              service: 1,
              columnsList: [
                { title: "id", name: "id", sorted: true },
                { title: "№", name: "appId" },
                { title: "Отправитель", name: "sender" },
                { title: "Дата подачи", name: "startAppDate" },
                { title: "Роль", name: "role" },
                { title: "Статус рассмотрения", name: "status" },
                { title: "Наименование организации", name: "orgEvent" },
                { title: "", name: "otherInfo" },
              ],
              primaryColumn: "id",
              rowsList: [
                [
                  "1",
                  "1",
                  "Сергей Игоревич Иванов",
                  "15.09.2022",
                  "Спортсмен",
                  "Одобрена",
                  "МАУ «Спортивная школа олимпийского резерва «Здоровый мир»",
                  "Подробнее",
                ],
                [
                  "2",
                  "2",
                  "Сергей Игоревич Иванов",
                  "17.03.2022",
                  "Организатор",
                  "На согласовании",
                  "Чемпионат Красноярского края по хоккею",
                  "Подробнее",
                ],
                [
                  "3",
                  "3",
                  "Сергей Игоревич Иванов",
                  "01.03.2022",
                  "Организатор",
                  "Отказано",
                  "Чемпионат Красноярского края по хоккею",
                  "Подробнее",
                ],
              ],
              sortColumn: "id",
              ascendingSortOrder: false,
              filters: [
                {
                  id: "1",
                  label: "№ сообщения",
                  type: "input",
                  subtype: "number",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "2",
                  label: "Дата создания сообщения",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " c" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "3",
                  label: "Тема",
                  type: "input",
                  subtype: "Тема",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "4",
                  label: "Дата входа в статус",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "5",
                  label: "Статус",
                  type: "select",
                  itemsList: [
                    { id: 1, text: "Черновик" },
                    { id: 2, text: "В работе" },
                    { id: 3, text: "Обработано" },
                    { id: 4, text: "Архивная" },
                  ],
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "6",
                  label: "Срок отправки ответа",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "7",
                  label: "Показать только архивные",
                  type: "checkbox",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
              ],
              pagination: {
                itemsTotal: 15,
                page: 1,
                pageSize: 10,
                itemsPerPage: [10, 25, 50],
              },
            },
          },
          eventApps: {
            table: {
              id: "personAppsTable",
              service: 1,
              columnsList: [
                { title: "id", name: "id", sorted: true },
                { title: "№", name: "appId" },
                { title: "ФИО заявителя", name: "sender" },
                { title: "Дата подачи", name: "startAppDate" },
                { title: "Статус участника", name: "role" },
                { title: "Статус рассмотрения", name: "status" },
                { title: "Наименование события", name: "orgEvent" },
                { title: "", name: "otherInfo" },
              ],
              primaryColumn: "id",
              rowsList: [
                [
                  "1",
                  "1",
                  "Сергей Игоревич Иванов",
                  "15.09.2022",
                  "Спортсмен",
                  "Одобрена",
                  "МАУ «Спортивная школа олимпийского резерва «Здоровый мир»",
                  "Подробнее",
                ],
                [
                  "2",
                  "2",
                  "Сергей Игоревич Иванов",
                  "17.03.2022",
                  "Организатор",
                  "На согласовании",
                  "Чемпионат Красноярского края по хоккею",
                  "Подробнее",
                ],
                [
                  "3",
                  "3",
                  "Сергей Игоревич Иванов",
                  "01.03.2022",
                  "Организатор",
                  "Отказано",
                  "Чемпионат Красноярского края по хоккею",
                  "Подробнее",
                ],
              ],
              sortColumn: "id",
              ascendingSortOrder: false,
              filters: [
                {
                  id: "1",
                  label: "№ сообщения",
                  type: "input",
                  subtype: "number",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "2",
                  label: "Дата создания сообщения",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " c" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "3",
                  label: "Тема",
                  type: "input",
                  subtype: "Тема",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "4",
                  label: "Дата входа в статус",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "5",
                  label: "Статус",
                  type: "select",
                  itemsList: [
                    { id: 1, text: "Черновик" },
                    { id: 2, text: "В работе" },
                    { id: 3, text: "Обработано" },
                    { id: 4, text: "Архивная" },
                  ],
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "6",
                  label: "Срок отправки ответа",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "7",
                  label: "Показать только архивные",
                  type: "checkbox",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
              ],
              pagination: {
                itemsTotal: 15,
                page: 1,
                pageSize: 10,
                itemsPerPage: [10, 25, 50],
              },
            },
          },
        },
        training: {
          table: {
            id: "personAppsTable",
            service: 1,
            columnsList: [
              { title: "id", name: "id", sorted: true },
              { title: "№", name: "appId" },
              { title: "Тип обращения", name: "appType" },
              { title: "Дата подачи", name: "startAppDate" },
              { title: "Статус рассмотрения", name: "status" },
              { title: "Наименование организации", name: "orgEvent" },
              { title: "", name: "otherInfo" },
            ],
            primaryColumn: "id",
            rowsList: [
              [
                "1",
                "1",
                "Личная заявка",
                "15.09.2022",
                "Одобрена",
                "МАУ «Спортивная школа олимпийского резерва «Здоровый мир»",
                "Подробнее",
              ],
              [
                "2",
                "2",
                "Заявка родителя",
                "17.03.2022",
                "На согласовании",
                "Бадминтонный клуб «Енисей»",
                "Подробнее",
              ],
              [
                "3",
                "3",
                "Заявка опекуна",
                "01.03.2022",
                "Отказано",
                "КГБУ «Региональный центр спортивной подготовки по адаптивным видам спорта»",
                "Подробнее",
              ],
            ],
            sortColumn: "id",
            ascendingSortOrder: false,
            filters: [
              {
                id: "1",
                label: "№ сообщения",
                type: "input",
                subtype: "number",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "2",
                label: "Дата создания сообщения",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " c" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "3",
                label: "Тема",
                type: "input",
                subtype: "Тема",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "4",
                label: "Дата входа в статус",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "5",
                label: "Статус",
                type: "select",
                itemsList: [
                  { id: 1, text: "Черновик" },
                  { id: 2, text: "В работе" },
                  { id: 3, text: "Обработано" },
                  { id: 4, text: "Архивная" },
                ],
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "6",
                label: "Срок отправки ответа",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "7",
                label: "Показать только архивные",
                type: "checkbox",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
            ],
            pagination: {
              itemsTotal: 15,
              page: 1,
              pageSize: 10,
              itemsPerPage: [10, 25, 50],
            },
          },
        },
        mass: {
          table: {
            id: "personAppsTable",
            service: 1,
            columnsList: [
              { title: "id", name: "id", sorted: true },
              { title: "№", name: "appId" },
              { title: "Наименование услуги", name: "serviceName" },
              { title: "Дата подачи", name: "startAppDate" },
              { title: "Статус рассмотрения", name: "status" },
              { title: "Наименование объекта", name: "orgEvent" },
              { title: "Дата и время события", name: "eventTime" },
              { title: "", name: "otherInfo" },
            ],
            primaryColumn: "id",
            rowsList: [
              [
                "1",
                "1",
                "Запись на посещение",
                "15.09.2022",
                "Одобрена",
                "МАУ «Спортивная школа олимпийского резерва «Здоровый мир»",
                "15.09.2022 12:00",
                "Подробнее",
              ],
              [
                "2",
                "2",
                "Запись на посещение",
                "17.03.2022",
                "На согласовании",
                "Бадминтонный клуб «Енисей»",
                "17.03.2022 17:00",
                "Подробнее",
              ],
              [
                "3",
                "3",
                "Запись на посещение",
                "17.03.2022",
                "На согласовании",
                "Бадминтонный клуб «Енисей»",
                "17.03.2022 17:00",
                "Подробнее",
              ],
            ],
            sortColumn: "id",
            ascendingSortOrder: false,
            filters: [
              {
                id: "1",
                label: "№ сообщения",
                type: "input",
                subtype: "number",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "2",
                label: "Дата создания сообщения",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " c" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "3",
                label: "Тема",
                type: "input",
                subtype: "Тема",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "4",
                label: "Дата входа в статус",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "5",
                label: "Статус",
                type: "select",
                itemsList: [
                  { id: 1, text: "Черновик" },
                  { id: 2, text: "В работе" },
                  { id: 3, text: "Обработано" },
                  { id: 4, text: "Архивная" },
                ],
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "6",
                label: "Срок отправки ответа",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "7",
                label: "Показать только архивные",
                type: "checkbox",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
            ],
            pagination: {
              itemsTotal: 15,
              page: 1,
              pageSize: 10,
              itemsPerPage: [10, 25, 50],
            },
          },
        },
        children: {
          table: {
            id: "personChildrenTable",
            service: 1,
            columnsList: [
              { title: "id", name: "id", sorted: true },
              { title: "Фото", name: "photo" },
              { title: "ФИО", name: "fullName" },
              { title: "Пол", name: "gender" },
              { title: "Дата рождения", name: "birthday" },
              { title: "Адрес регистрации", name: "registrationAddress" },
              { title: "Организация", name: "organization" },
              { title: "", name: "otherInfo" },
            ],
            primaryColumn: "id",
            rowsList: [
              [
                "1",
                "",
                "Савиров Сергей Юрьевич",
                "Мужской",
                "03.11.2005",
                "г. Красноярск, ул. Мира, д. 13, кв. 73",
                "МАУ «Спортивная школа олимпийского резерва «Здоровый мир»",
                "",
              ],
              [
                "2",
                "",
                "Яковлева Лилия Сергеевна",
                "Женский",
                "16.09.2009",
                "Красноярский край, г. Норильск, площадь Газовиков Заполярья, д. 3/1, кв. 37 ",
                "МАУ «Спортивная школа олимпийского резерва по вольной борьбе»",
                "",
              ],
            ],
            sortColumn: "id",
            ascendingSortOrder: false,
            filters: [
              {
                id: "1",
                label: "№ сообщения",
                type: "input",
                subtype: "number",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "2",
                label: "Дата создания сообщения",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " c" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "3",
                label: "Тема",
                type: "input",
                subtype: "Тема",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "4",
                label: "Дата входа в статус",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "5",
                label: "Статус",
                type: "select",
                itemsList: [
                  { id: 1, text: "Черновик" },
                  { id: 2, text: "В работе" },
                  { id: 3, text: "Обработано" },
                  { id: 4, text: "Архивная" },
                ],
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "6",
                label: "Срок отправки ответа",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "7",
                label: "Показать только архивные",
                type: "checkbox",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
            ],
            pagination: {
              itemsTotal: 15,
              page: 1,
              pageSize: 10,
              itemsPerPage: [10, 25, 50],
            },
          },
        },
      },
      // Информация для личного кабинета физлица
      // !!!Не удалять, содержит информацию о детях физлица и ТП в чистом виде, полученном с сервера
      personAccountDB: {},
      personAccount: {
        tabs: [
          { id: "person-profile", title: "Личные данные", active: true },
          { id: "person-docs", title: "Документы", active: false },
          { id: "person-education", title: "Образование", active: false },
          { id: "person-apps", title: "Заявки", active: false },
          {
            id: "person-training",
            title: "Спортивная подготовка",
            active: false,
          },
          { id: "person-mass", title: "Массовый спорт", active: false },
          { id: "person-children", title: "Дети", active: false },
        ],
        profile: {
          forms: [
            {
              name: "fioForm",
              form: {
                title: "ФИО",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-family-name",
                    label: "Фамилия",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-first-name",
                    label: "Имя",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-middle-name",
                    label: "Отчество",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "birthForm",
              form: {
                title: "Пол и дата рождения",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-gender",
                    label: "Пол",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-birth-date",
                    label: "Дата рождения",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-birth-place",
                    label: "Место рождения",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "citizenshipForm",
              form: {
                title: "Гражданство",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-citizenship",
                    label: "Гражданство",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-region",
                    label: "Субъект",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-city",
                    label: "Населенный пункт",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "contactForm",
              form: {
                title: "Контактная информация",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-phone",
                    label: "Контактный номер телефона",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "registrationForm",
              form: {
                title: "Сведения о регистрации",
                width: 12,
                responsive: "col-sm-12",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-5",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-registration-address",
                    label: "Адрес регистрации",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-3",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-9",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-fact-address",
                    label: "Почтовый адрес",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-3",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-9",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
          ],
        },
        docs: {
          forms: [
            {
              name: "identifyForm",
              form: {
                title: "Удостоверение личности",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-doc-type",
                    label: "Тип документа",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-doc-series",
                    label: "Серия",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-doc-number",
                    label: "Номер",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-doc-issue-date",
                    label: "Дата выдачи",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-doc-issuer",
                    label: "Кем выдан",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-doc-code",
                    label: "Номер актовой записи",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "innForm",
              form: {
                title: "ИНН",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-doc-inn",
                    label: "Идентификационный номер",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "snilsForm",
              form: {
                title: "СНИЛС (при наличии)",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-doc-snils",
                    label: "Номер",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
          ],
        },
        education: {
          forms: [
            {
              id: null,
              name: "identifyForm",
              form: {
                title: "Сведения об общем образовании",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "person-education-level",
                    label: "Образовательное учреждение",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-education-name",
                    label: "Наименование учреждения",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-education-start-year",
                    label: "Год поступления",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "person-education-finish-year",
                    label: "Год окончания",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
          ],
        },
        apps: {
          orgApps: {
            table: {
              id: "personAppsTable",
              service: 1,
              columnsList: [
                { title: "id", name: "id", sorted: true },
                { title: "№", name: "appId" },
                { title: "Отправитель", name: "sender" },
                { title: "Дата подачи", name: "startAppDate" },
                { title: "Роль", name: "role" },
                { title: "Статус рассмотрения", name: "status" },
                { title: "Наименование организации", name: "orgEvent" },
                { title: "", name: "otherInfo" },
              ],
              primaryColumn: "id",
              rowsList: [],
              sortColumn: "id",
              ascendingSortOrder: false,
              filters: [
                {
                  id: "1",
                  label: "№ сообщения",
                  type: "input",
                  subtype: "number",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "2",
                  label: "Дата создания сообщения",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " c" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "3",
                  label: "Тема",
                  type: "input",
                  subtype: "Тема",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "4",
                  label: "Дата входа в статус",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "5",
                  label: "Статус",
                  type: "select",
                  itemsList: [
                    { id: 1, text: "Черновик" },
                    { id: 2, text: "В работе" },
                    { id: 3, text: "Обработано" },
                    { id: 4, text: "Архивная" },
                  ],
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "6",
                  label: "Срок отправки ответа",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "7",
                  label: "Показать только архивные",
                  type: "checkbox",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
              ],
              pagination: {
                itemsTotal: 15,
                page: 1,
                pageSize: 10,
                itemsPerPage: [10, 25, 50],
              },
            },
          },
          eventApps: {
            table: {
              id: "personAppsTable",
              service: 1,
              columnsList: [
                { title: "id", name: "id", sorted: true },
                { title: "№", name: "appId" },
                { title: "ФИО заявителя", name: "sender" },
                { title: "Дата подачи", name: "startAppDate" },
                { title: "Статус участника", name: "role" },
                { title: "Статус рассмотрения", name: "status" },
                { title: "Наименование события", name: "orgEvent" },
                { title: "", name: "otherInfo" },
              ],
              primaryColumn: "id",
              rowsList: [],
              sortColumn: "id",
              ascendingSortOrder: false,
              filters: [
                {
                  id: "1",
                  label: "№ сообщения",
                  type: "input",
                  subtype: "number",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "2",
                  label: "Дата создания сообщения",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " c" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "3",
                  label: "Тема",
                  type: "input",
                  subtype: "Тема",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "4",
                  label: "Дата входа в статус",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "5",
                  label: "Статус",
                  type: "select",
                  itemsList: [
                    { id: 1, text: "Черновик" },
                    { id: 2, text: "В работе" },
                    { id: 3, text: "Обработано" },
                    { id: 4, text: "Архивная" },
                  ],
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
                {
                  id: "6",
                  label: "Срок отправки ответа",
                  type: "range",
                  subtype: "date",
                  itemsList: [{ label: " с" }, { label: " по" }],
                  width: 12,
                  responsive: "col-sm-8 col-md-6 col-lg-4",
                },
                {
                  id: "7",
                  label: "Показать только архивные",
                  type: "checkbox",
                  width: 12,
                  responsive: "col-sm-4 col-md-3 col-lg-2",
                },
              ],
              pagination: {
                itemsTotal: 15,
                page: 1,
                pageSize: 10,
                itemsPerPage: [10, 25, 50],
              },
            },
          },
        },
        training: {
          table: {
            id: "personAppsTable",
            service: 1,
            columnsList: [
              { title: "id", name: "id" },
              { title: "№", name: "appId" },
              { title: "Тип обращения", name: "appType" },
              { title: "Дата подачи", name: "startAppDate" },
              { title: "Статус рассмотрения", name: "status" },
              {
                title: "Наименование организации",
                name: "orgEvent",
              },
              { title: "", name: "otherInfo" },
            ],
            primaryColumn: "id",
            rowsList: [],
            sortColumn: "id",
            ascendingSortOrder: false,
            filters: [
              {
                id: "1",
                label: "№ сообщения",
                type: "input",
                subtype: "number",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "2",
                label: "Дата создания сообщения",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " c" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "3",
                label: "Тема",
                type: "input",
                subtype: "Тема",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "4",
                label: "Дата входа в статус",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "5",
                label: "Статус",
                type: "select",
                itemsList: [
                  { id: 1, text: "Черновик" },
                  { id: 2, text: "В работе" },
                  { id: 3, text: "Обработано" },
                  { id: 4, text: "Архивная" },
                ],
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "6",
                label: "Срок отправки ответа",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "7",
                label: "Показать только архивные",
                type: "checkbox",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
            ],
            pagination: {
              itemsTotal: 15,
              page: 1,
              pageSize: 10,
              itemsPerPage: [10, 25, 50],
            },
          },
        },
        mass: {
          table: {
            id: "personAppsTable",
            service: 1,
            columnsList: [
              { title: "id", name: "id", sorted: true },
              { title: "№", name: "appId" },
              { title: "Наименование услуги", name: "serviceName" },
              { title: "Дата подачи", name: "startAppDate" },
              { title: "Статус рассмотрения", name: "status" },
              { title: "Наименование объекта", name: "orgEvent" },
              { title: "Дата и время события", name: "eventTime" },
              { title: "", name: "otherInfo" },
            ],
            primaryColumn: "id",
            rowsList: [],
            sortColumn: "id",
            ascendingSortOrder: false,
            filters: [
              {
                id: "1",
                label: "№ сообщения",
                type: "input",
                subtype: "number",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "2",
                label: "Дата создания сообщения",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " c" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "3",
                label: "Тема",
                type: "input",
                subtype: "Тема",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "4",
                label: "Дата входа в статус",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "5",
                label: "Статус",
                type: "select",
                itemsList: [
                  { id: 1, text: "Черновик" },
                  { id: 2, text: "В работе" },
                  { id: 3, text: "Обработано" },
                  { id: 4, text: "Архивная" },
                ],
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "6",
                label: "Срок отправки ответа",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "7",
                label: "Показать только архивные",
                type: "checkbox",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
            ],
            pagination: {
              itemsTotal: 15,
              page: 1,
              pageSize: 10,
              itemsPerPage: [10, 25, 50],
            },
          },
        },
        children: {
          table: {
            id: "personChildrenTable",
            service: 1,
            columnsList: [
              { title: "id", name: "id", sorted: true },
              { title: "Фото", name: "photo" },
              { title: "ФИО", name: "fullName" },
              { title: "Пол", name: "gender" },
              { title: "Дата рождения", name: "birthday" },
              { title: "Адрес регистрации", name: "registrationAddress" },
              { title: "Организация", name: "organization" },
              { title: "", name: "otherInfo" },
            ],
            primaryColumn: "id",
            rowsList: [],
            sortColumn: "id",
            ascendingSortOrder: false,
            filters: [
              {
                id: "1",
                label: "№ сообщения",
                type: "input",
                subtype: "number",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "2",
                label: "Дата создания сообщения",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " c" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "3",
                label: "Тема",
                type: "input",
                subtype: "Тема",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "4",
                label: "Дата входа в статус",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "5",
                label: "Статус",
                type: "select",
                itemsList: [
                  { id: 1, text: "Черновик" },
                  { id: 2, text: "В работе" },
                  { id: 3, text: "Обработано" },
                  { id: 4, text: "Архивная" },
                ],
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "6",
                label: "Срок отправки ответа",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "7",
                label: "Показать только архивные",
                type: "checkbox",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
            ],
            pagination: {
              itemsTotal: 15,
              page: 1,
              pageSize: 10,
              itemsPerPage: [10, 25, 50],
            },
          },
        },
      },

      // ЛК инвалида
      invalidAccountDB: {},
      invalidAccount: {
        tabs: [
          { id: "invalid-profile", title: "Личные данные", active: true },
          { id: "invalid-ipra", title: "Карточка ИПРА", active: false },
          { id: "invalid-docs", title: "Документы", active: false },
          { id: "invalid-education", title: "Образование", active: false },
          { id: "invalid-apps", title: "Заявки ИПРА", active: false },
          {
            id: "invalid-history",
            title: "История выданных ИПРА",
            active: false,
          },
        ],
        profile: {
          forms: [
            {
              name: "fioForm",
              form: {
                title: "ФИО",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-family-name",
                    label: "Фамилия",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-first-name",
                    label: "Имя",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-middle-name",
                    label: "Отчество",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "birthForm",
              form: {
                title: "Пол и дата рождения",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-gender",
                    label: "Пол",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-birth-date",
                    label: "Дата рождения",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-birth-place",
                    label: "Место рождения",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "citizenshipForm",
              form: {
                title: "Гражданство",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-citizenship",
                    label: "Гражданство",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-region",
                    label: "Субъект",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-city",
                    label: "Населенный пункт",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "contactForm",
              form: {
                title: "Контактная информация",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-email",
                    label: "Электронная почта",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-phone",
                    label: "Контактный номер телефона",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "registrationForm",
              form: {
                title: "Сведения о регистрации",
                width: 12,
                responsive: "col-sm-12",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-5",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-registration-address",
                    label: "Адрес регистрации",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-3",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-9",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-fact-address",
                    label: "Почтовый адрес",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-3",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-9",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
          ],
        },
        ipra: {
          forms: [
            {
              name: "basicForm",
              form: {
                title: "Основные сведения",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-full-name",
                    label: "ФИО инвалида",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-birth-date",
                    label: "Дата рождения",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-delegate-full-name",
                    label: "ФИО законного представителя",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-living-district",
                    label: "Район проживания",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "orgForm",
              form: {
                title: "Организация",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-org-name",
                    label: "Название организации",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-org-address",
                    label: "Адрес организации",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-org-phone",
                    label: "Телефон организации",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-org-district",
                    label: "Район расположения",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "contactForm",
              form: {
                title: "Контактная информация",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-phone",
                    label: "Контактный номер",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "trainingForm",
              form: {
                title:
                  "Физкультурно-оздоровительные мероприятия, занятия спортом",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-training-neediness",
                    label: "Нуждаемость",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "ipraForm",
              form: {
                title: "ИПРА",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-ipra-date-start",
                    label: "Дата выдачи ИПРА",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-ipra-number",
                    label: "№ ИПРА",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-ipra-date-finish",
                    label: "Срок действия ИПРА",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-ipra-date-publish",
                    label: "Дата размещения ИПРА",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-ipra-date-report",
                    label: "Срок отчета",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-ipra-status",
                    label: "Статус обращения",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-ipra-report",
                    label: "Отчет об исполнении ИПРА",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "generalForm",
              form: {
                title: "Общие сведения",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-citizenship-code",
                    label: "Код гражданства",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-homeless",
                    label: "Лицо без определенного места жительства",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-without-registration",
                    label: "Лицо без постоянной регистрации",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "secondaryForm",
              form: {
                title: "Дополнительные поля",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-mse-clinic-name",
                    label: "Название организации, направившей на МСЭ",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-mse-clinic-address",
                    label: "Адрес организации, направившей на МСЭ",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-mse-report-number",
                    label: "Номер протокола МСЭ",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-mse-report-date",
                    label: "Дата протокола МСЭ",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "registrationForm",
              form: {
                title: "Сведения об адресе",
                width: 12,
                responsive: "col-sm-12",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-5",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-registration-address-code",
                    label: "Код типа адреса",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-3",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-9",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-address",
                    label: "Адрес в виде строки",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-3",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-9",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
          ],
        },
        docs: {
          forms: [
            {
              name: "identifyForm",
              form: {
                title: "Удостоверение личности",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-doc-type",
                    label: "Тип документа",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-doc-series",
                    label: "Серия",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-doc-number",
                    label: "Номер",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-doc-issue-date",
                    label: "Дата выдачи",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-doc-issuer",
                    label: "Кем выдан",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-doc-issuer-code",
                    label: "Код подразделения",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "innForm",
              form: {
                title: "ИНН",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-doc-inn",
                    label: "Идентификационный номер",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "snilsForm",
              form: {
                title: "СНИЛС (при наличии)",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-doc-snils",
                    label: "Номер",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
          ],
        },
        education: {
          forms: [
            {
              id: null,
              name: "educationForm",
              form: {
                title: "Сведения об общем образовании",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "invalid-education-level",
                    label: "Образовательное учреждение",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-education-name",
                    label: "Наименование учреждения",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-education-start-year",
                    label: "Год поступления",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "invalid-education-finish-year",
                    label: "Год окончания",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
          ],
        },
        ipraApps: {
          table: {
            id: "ipraAppsTable",
            service: 1,
            columnsList: [
              { title: "id", name: "id", sorted: true },
              { title: "Номер", name: "appId" },
              { title: "Дата обращения", name: "appDate" },
              { title: "Отправитель", name: "applicant" },
              { title: "Район", name: "district" },
              { title: "Мероприятие", name: "event" },
              { title: "Текст обращения", name: "appText" },
              { title: "Статус", name: "status" },
            ],
            primaryColumn: "id",
            rowsList: [
              [
                "Первое",
                "Второе",
                "Третье",
                "Четвертое",
                "Пятое",
                "Шестое",
                "Седьмое",
                "Восьмое",
              ],
            ],
            sortColumn: "id",
            ascendingSortOrder: false,
            filters: [
              {
                id: "1",
                label: "№ сообщения",
                type: "input",
                subtype: "number",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "2",
                label: "Дата создания сообщения",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " c" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "3",
                label: "Тема",
                type: "input",
                subtype: "Тема",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "4",
                label: "Дата входа в статус",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "5",
                label: "Статус",
                type: "select",
                itemsList: [
                  { id: 1, text: "Черновик" },
                  { id: 2, text: "В работе" },
                  { id: 3, text: "Обработано" },
                  { id: 4, text: "Архивная" },
                ],
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "6",
                label: "Срок отправки ответа",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "7",
                label: "Показать только архивные",
                type: "checkbox",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
            ],
            pagination: {
              itemsTotal: 15,
              page: 1,
              pageSize: 10,
              itemsPerPage: [10, 25, 50],
            },
          },
        },
        ipraHistory: {
          table: {
            id: "ipraHistoryTable",
            service: 1,
            columnsList: [
              { title: "id", name: "id" },
              { title: "№ ИПРА", name: "appId" },
              { title: "Дата выдачи ИПРА", name: "appDateStart" },
              { title: "Срок действия ИПРА", name: "appDateFinish" },
              { title: "Статус", name: "status" },
            ],
            primaryColumn: "id",
            rowsList: [["Первое", "Второе", "Третье", "Четвертое", "Пятое"]],
            sortColumn: "id",
            ascendingSortOrder: false,
            filters: [
              {
                id: "1",
                label: "№ сообщения",
                type: "input",
                subtype: "number",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "2",
                label: "Дата создания сообщения",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " c" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "3",
                label: "Тема",
                type: "input",
                subtype: "Тема",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "4",
                label: "Дата входа в статус",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "5",
                label: "Статус",
                type: "select",
                itemsList: [
                  { id: 1, text: "Черновик" },
                  { id: 2, text: "В работе" },
                  { id: 3, text: "Обработано" },
                  { id: 4, text: "Архивная" },
                ],
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "6",
                label: "Срок отправки ответа",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "7",
                label: "Показать только архивные",
                type: "checkbox",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
            ],
            pagination: {
              itemsTotal: 15,
              page: 1,
              pageSize: 10,
              itemsPerPage: [10, 25, 50],
            },
          },
        },
      },

      // Информация для паспорта юрлица
      organizationPassportDB: {},
      organizationPassport: {
        tabs: [
          {
            id: "organization-profile",
            title: "Общая информация",
            active: true,
          },
          {
            id: "organization-activities",
            title: "Виды деятельности",
            active: false,
          },
          { id: "organization-workers", title: "Сотрудники", active: false },
          { id: "organization-sports", title: "Виды спорта", active: false },
          { id: "organization-docs", title: "Документы", active: false },
          {
            id: "organization-requirements",
            title: "Требования",
            active: false,
          },
          { id: "organization-apps", title: "Заявки", active: false },
          {
            id: "organization-trainings",
            title: "Заявки на спорт.подготовку",
            active: false,
          },
        ],
        profile: {
          forms: [
            {
              name: "orgNameForm",
              form: {
                title: "Информация о юридическом лице",
                width: 12,
                responsive: "col-sm-12",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-lg-3",
                  },
                  field: {
                    width: 8,
                    responsive: "col-lg-9",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "organization-full-name",
                    label: "Полное наименование",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-short-name",
                    label: "Сокращенное наименование",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-type",
                    label: "Тип юр.лица",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-form",
                    label: "Организационно-правовая форма",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "contactForm",
              form: {
                title: "Контактная информация",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "organization-phone",
                    label: "Контактный телефон",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-site",
                    label: "Интернет-сайт",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-email",
                    label: "Электронная почта",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-legal-address",
                    label: "Юридический адрес",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-fact-address",
                    label: "Фактический адрес",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "addressForm",
              form: {
                title: "Учредитель",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "organization-owner-type",
                    label: "Тип учредителя",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-owner",
                    label: "Учредитель",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-boss-fullname",
                    label: "ФИО руководителя",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "requisitesForm",
              form: {
                title: "Реквизиты",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "organization-inn",
                    label: "ИНН",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-kpp",
                    label: "КПП",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-ogrn",
                    label: "ОГРН",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-oktmo",
                    label: "ОКТМО",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
            {
              name: "bankDetailsForm",
              form: {
                title: "Платежные реквизиты",
                width: 12,
                responsive: "col-sm-6",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                disabled: true,
                fields: [
                  {
                    id: "organization-bank-name",
                    label: "Наименование банка",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-bank-inn",
                    label: "ИНН банка",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-bank-kpp",
                    label: "КПП банка",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-bank-bik",
                    label: "БИК банка",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-bank-pay-account",
                    label: "Номер расчетного счета",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                  {
                    id: "organization-bank-cor-account",
                    label: "Номер корр. счета",
                    type: "input",
                    subtype: "text",
                    width: 12,
                    responsive: "",
                    horizontal: true,
                    horizontalWidth: {
                      label: {
                        width: 4,
                        responsive: "col-sm-5",
                      },
                      field: {
                        width: 8,
                        responsive: "col-sm-7",
                      },
                    },
                    value: "",
                  },
                ],
              },
            },
          ],
        },
        activities: [
          {
            id: 1,
            title:
              "84.11.11: Деятельность федеральных органов государственной власти, кроме полномочных представителей Президента Российской Федерации и территориальных органов федеральных органов исполнительной власти",
            description:
              "Равным образом новая модель организационной деятельности играет важную роль в формировании системы обучения кадров, соответствует насущным потребностям.",
          },
          {
            id: 2,
            title: "93.11: Деятельность спортивных объектов",
            description:
              "Таким образом, перспективное планирование предоставляет широкие возможности для экономической целесообразности принимаемых решений. Но представители современных социальных резервов неоднозначны и будут подвергнуты целой серии независимых исследований.",
          },
          {
            id: 3,
            title: "93.29.1: Деятельность парков отдыха и пляжей",
            description:
              "Но представители современных социальных резервов неоднозначны и будут подвергнуты целой серии независимых исследований. Как принято считать, сторонники тоталитаризма в науке могут быть своевременно верифицированы.",
          },
        ],
        workers: [
          {
            id: 1,
            fullName: "Аршинцев Валерий Олегович",
            position: "Тренер",
            isAdmin: false,
            photo: {
              src: "/images/default.jpg",
              fileEntryId: "",
            },
          },
          {
            id: 2,
            fullName: "Полипович Сергей Андреевич",
            position: "Руководитель",
            isAdmin: true,
            photo: {
              src: "/images/default.jpg",
              fileEntryId: "",
            },
          },
          {
            id: 3,
            fullName: "Петров Сергей Иванович",
            position: "Администратор",
            isAdmin: false,
            photo: {
              src: "/images/default_dark.jpg",
              fileEntryId: "",
            },
          },
        ],
        sports: [
          { id: 1, name: "Хоккей на траве", discipline: "Мини хоккей с мячом" },
          {
            id: 2,
            name: "Водно-моторный спорт (общероссийские)",
            discipline: "Аквабайк - ранэбаут GP1 кольцевые гонки",
          },
          {
            id: 3,
            name: "Гребля на байдарках и каноэ (общероссийские)",
            discipline: "Д- 10 дистанция 2000 смешанный",
          },
        ],
        documents: [
          {
            id: 1,
            name: "Наименование документа",
            title: "Имя документа 1",
            fileEntryId: "",
          },
          {
            id: 2,
            name: "Устав организации",
            title: "Имя документа 2",
            fileEntryId: "",
          },
          {
            id: 3,
            name: "Нормативы физической подготовки",
            title: "Имя документа 3",
            fileEntryId: "",
          },
          {
            id: 4,
            name: "Правила посещения",
            title: "Имя документа 4",
            fileEntryId: "",
          },
        ],
        apps: {
          table: {
            id: "organizationAppsTable",
            service: 1,
            columnsList: [
              { title: "id", name: "id", sorted: true },
              { title: "№", name: "appNum" },
              { title: "Отправитель", name: "appPerson" },
              { title: "Роль", name: "role" },
              { title: "Дата создания", name: "startAppDate" },
              { title: "Организация", name: "organization" },
              { title: "Статус рассмотрения", name: "status" },
            ],
            primaryColumn: "id",
            rowsList: [
              [
                "1",
                "1",
                "Питаева Ирина Львовна",
                "Спортсмен",
                "15.09.2022",
                "АНО «Исполнительная дирекция молодежного чемпионата мира по хоккею 2023 год",
                "Одобрена",
              ],
              [
                "2",
                "2",
                "Балясников Владимир Андреевич",
                "Тренер",
                "17.03.2022",
                "АНО «Исполнительная дирекция молодежного чемпионата мира по хоккею 2023 год",
                "Отказано",
              ],
              [
                "3",
                "3",
                "Полипович Сергей Андреевич",
                "Тренер",
                "01.03.2022",
                "АНО «Исполнительная дирекция молодежного чемпионата мира по хоккею 2023 год",
                "На согласовании",
              ],
            ],
            sortColumn: "id",
            ascendingSortOrder: false,
            filters: [
              {
                id: "1",
                label: "№ сообщения",
                type: "input",
                subtype: "number",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "2",
                label: "Дата создания сообщения",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " c" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "3",
                label: "Тема",
                type: "input",
                subtype: "Тема",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "4",
                label: "Дата входа в статус",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "5",
                label: "Статус",
                type: "select",
                itemsList: [
                  { id: 1, text: "Черновик" },
                  { id: 2, text: "В работе" },
                  { id: 3, text: "Обработано" },
                  { id: 4, text: "Архивная" },
                ],
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
              {
                id: "6",
                label: "Срок отправки ответа",
                type: "range",
                subtype: "date",
                itemsList: [{ label: " с" }, { label: " по" }],
                width: 12,
                responsive: "col-sm-8 col-md-6 col-lg-4",
              },
              {
                id: "7",
                label: "Показать только архивные",
                type: "checkbox",
                width: 12,
                responsive: "col-sm-4 col-md-3 col-lg-2",
              },
            ],
            pagination: {
              itemsTotal: 15,
              page: 1,
              pageSize: 10,
              itemsPerPage: [10, 25, 50],
            },
          },
        },
        trainings: {
          table: {
            id: "organizationTrainingsTable",
            service: 1,
            columnsList: [
              { title: "id", name: "id" },
              { title: "№", name: "trainingNum" },
              { title: "ФИО Заявителя", name: "trainingPerson" },
              { title: "Ребенок", name: "trainingChild" },
              { title: "Дата подачи", name: "date", sorted: true },
              { title: "Вид спорта", name: "sport", sorted: true },
              {
                title: "Статус рассмотрения",
                name: "status",
                sorted: true,
              },
            ],
            primaryColumn: "id",
            rowsList: [],
            sortColumn: "date",
            ascendingSortOrder: false,
            filters: [
              {
                id: "training-passport-filter-date",
                label: "Дата подачи",
                type: "range",
                subtype: "date",
                itemsList: [
                  { label: " c", value: null },
                  { label: " по", value: null },
                ],
                width: 12,
                responsive: "col-sm-4",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
              },
              {
                id: "training-passport-filter-sport",
                label: "Вид спорта",
                type: "select",
                itemsList: [],
                width: 12,
                responsive: "col-sm-4",
                defaultValueLabel: "Выберите",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "training-passport-filter-status",
                label: "Статус",
                type: "select",
                itemsList: [],
                width: 12,
                responsive: "col-sm-4",
                defaultValueLabel: "Выберите",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
            ],
            pagination: {
              itemsTotal: 0,
              page: 1,
              pageSize: 10,
              itemsPerPage: [10, 25, 50],
            },
          },
        },
        requirements: {
          description: "",
          files: [],
        },
      },

      modals: [
        {
          id: 1,
          name: "addPersonDocForm",
          title: "Добавление документа",
          form: {
            title: "Добавление документа",
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "add-person-doc-type",
                label: "Тип документа",
                type: "select",
                itemsList: [
                  { id: 1, value: 1, label: "Свидетельство о браке" },
                  { id: 2, value: 2, label: "Водительское удостоверение" },
                ],
                width: 12,
                responsive: "",
                defaultValueLabel: "Выберите тип документа",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "add-person-doc-series",
                label: "Серия",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
              {
                id: "add-person-doc-number",
                label: "Номер",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
              {
                id: "add-person-doc-issue-date",
                label: "Дата выдачи",
                type: "input",
                subtype: "date",
                width: 12,
                responsive: "",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "add-person-doc-issuer",
                label: "Кем выдан",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
              {
                id: "add-person-doc-code",
                label: "Код подразделения номер актовой записи",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
              {
                id: "add-person-doc-file",
                label: "Прикрепленные документы",
                type: "input",
                subtype: "file",
                comment:
                  "Вы можете прикрепить скан-копию документа (но это не обязательно)",
                button: {
                  text: "Добавить документ",
                  icon: {
                    url: "/icons/paperclip.svg",
                  },
                },
                file: {
                  name: "",
                  type: "",
                  base64: "",
                },
                width: 12,
                responsive: "",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
            ],
          },
        },
        {
          id: 2,
          name: "addPersonEducationForm",
          title: "Добавление данных об образовании",
          form: {
            title: "Добавление данных об образовании",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "add-person-education-grade-type",
                label: "Уровень образования",
                type: "select",
                itemsList: [
                  { id: 1, value: 1, label: "Высшее" },
                  { id: 2, value: 2, label: "Среднее" },
                  { id: 3, value: 3, label: "Общее" },
                ],
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "add-person-education-institution",
                label: "Учебное заведение",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
              {
                id: "add-person-education-profession",
                label: "Специализация",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: null,
              },
              {
                id: "add-person-education-start",
                label: "Год поступления",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
              {
                id: "add-person-education-finish",
                label: "Год окончания",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
              {
                id: "add-person-education-degree-series",
                label: "Серия диплома",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
              {
                id: "add-person-education-degree-number",
                label: "Номер диплома",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
              {
                id: "add-person-education-degree-date",
                label: "Дата выдачи документа об образовании",
                type: "input",
                subtype: "date",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
              {
                id: "add-person-education-file",
                label: "Прикрепленные документы",
                type: "input",
                subtype: "file",
                comment:
                  "Вы можете прикрепить скан-копию документа (но это не обязательно)",
                button: {
                  text: "Добавить документ",
                  icon: {
                    url: "/icons/paperclip.svg",
                  },
                },
                file: {
                  name: "",
                  type: "",
                  base64: "",
                },
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
            ],
          },
        },
        {
          id: 3,
          name: "addPersonAppOrgForm",
          title: "Создание заявки в организацию",
          formPart1: {
            title: "Создание заявки в организацию",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "add-person-app-org-organization",
                label: "Организация",
                type: "select",
                itemsList: [],
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
            ],
          },
          formPart2: {
            title: "Создание заявки в организацию",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "add-person-app-org-role",
                label: "Роль",
                type: "select",
                itemsList: [],
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "add-person-app-org-comment",
                label: "Комментарий к заявке",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
              {
                id: "add-person-app-org-file",
                label: "Прикрепленные документы",
                type: "input",
                subtype: "file",
                comment: "Прикрепленные документы",
                button: {
                  text: "Добавить документ",
                  icon: {
                    url: "/icons/adddoc.svg",
                  },
                },
                file: {
                  name: "",
                  type: "",
                  base64: "",
                },
                width: 12,
                responsive: "",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
            ],
          },
          formPart3: {
            title: "Создание заявки в организацию",
            validity: false,
            horizontal: false,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "add-person-app-org-agree",
                label: "Согласие на обработку персональных данных",
                type: "checkbox",
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите",
                horizontal: false,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: false,
              },
            ],
          },
        },
        {
          id: 4,
          name: "addPersonAppEventForm",
          title: "Создание заявки на участие в мероприятии",
          formPart1: {
            title: "Создание заявки на участие в мероприятии",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "add-person-app-event-event",
                label: "Событие",
                type: "select",
                itemsList: [
                  { id: 1, value: 1, label: "Событие 1" },
                  { id: 2, value: 2, label: "Событие 2" },
                ],
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
            ],
          },
          formPart2: {
            title: "Создание заявки на участие в мероприятии",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "add-person-app-event-role",
                label: "Роль",
                type: "select",
                itemsList: [
                  { id: 1, value: 1, label: "Спортсмен" },
                  { id: 2, value: 2, label: "Тренер" },
                ],
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "add-person-app-event-comment",
                label: "Комментарий к заявке",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
            ],
          },
          formPart3: {
            title: "Создание заявки на участие в мероприятии",
            validity: false,
            horizontal: false,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "add-person-app-event-agree",
                label: "Согласие на обработку персональных данных",
                type: "checkbox",
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите",
                horizontal: false,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: false,
              },
            ],
          },
        },
        {
          id: 5,
          name: "addPersonAppTrainingForm",
          title: "Создание заявки на спортивную подготовку",
          formPart1: {
            title: "Создание заявки на спортивную подготовку",
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "add-person-app-training-sport",
                label: "Вид спорта",
                type: "select",
                itemsList: [],
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите вид спорта",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "add-person-app-training-type",
                label: "Тип обращения",
                type: "select",
                itemsList: [],
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите тип обращения",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "add-person-app-training-org",
                label: "Организация",
                type: "select",
                itemsList: [
                  { id: 1, value: 1, label: "Тестовая организация 1" },
                  { id: 2, value: 2, label: "Тестовая организация 2" },
                  { id: 3, value: 3, label: "Тестовая организация 3" },
                  { id: 4, value: 4, label: "Тестовая организация 4" },
                ],
                width: 12,
                responsive: "",
                required: true,
                disabled: true,
                defaultValueLabel: "Выберите организацию",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
            ],
          },
          formPart2: {
            title: "Создание заявки на спортивную подготовку",
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "add-person-app-training-children",
                label: "ФИО",
                type: "select",
                itemsList: [
                  { id: 1, value: 1, label: "Савиров С.Ю." },
                  { id: 2, value: 2, label: "Иванов С.П." },
                ],
                width: 12,
                responsive: "",
                required: true,
                disabled: true,
                defaultValueLabel: "Выберите",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "add-person-app-training-city",
                label: "Населенный пункт",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                disabled: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
              {
                id: "add-person-app-training-comment",
                label: "Комментарий к заявке",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                disabled: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
            ],
          },
          formPart3: {
            title: "Создание заявки на спортивную подготовку",
            validity: false,
            horizontal: false,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "add-person-app-training-agree",
                label: "Согласие на обработку персональных данных",
                type: "checkbox",
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите",
                horizontal: false,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: false,
              },
            ],
          },
        },
        {
          id: 6,
          name: "addPersonAppTrainingReqForm",
          title: "Требования к спортсменам",
          requirements: {
            description: "",
            files: [],
          },
        },
        {
          id: 7,
          name: "addPersonAppPublicForm",
          title: "Заявка на занятие массовым спортом",
          formPart1: {
            title: "Заявка на занятие массовым спортом",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "add-person-app-public-object",
                label: "Объект",
                type: "select",
                itemsList: [],
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
            ],
          },
          formPart2: {
            title: "Заявка на занятие массовым спортом",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "add-person-app-public-type",
                label: "Тип услуги",
                type: "select",
                itemsList: [
                  { id: 1, value: 1, label: "Запись на посещение" },
                  { id: 2, value: 2, label: "Запись на промотр" },
                ],
                width: 12,
                responsive: "",
                required: true,
                disabled: true,
                defaultValueLabel: "Выберите",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "add-person-app-public-date",
                label: "Дата и время",
                type: "input",
                subtype: "datetime-local",
                width: 12,
                responsive: "",
                required: true,
                disabled: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
              {
                id: "add-person-app-public-comment",
                label: "Комментарий к заявке",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                disabled: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
            ],
          },
        },
        {
          id: 8,
          name: "addPersonAppOrgForm",
          title: "Одобрение заявки в организацию",
          form: {
            title: "Одобрение заявки в организацию",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "approve-person-app-position",
                label: "Должность",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
              {
                id: "approve-person-app-doc",
                label: "Прикрепленные документы",
                type: "input",
                subtype: "file",
                comment: "Прикрепленные документы",
                button: {
                  text: "Добавить документ",
                  icon: {
                    url: "/icons/adddoc.svg",
                  },
                },
                file: {
                  name: "",
                  type: "",
                  base64: "",
                },
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
            ],
          },
        },
        { id: 9, name: "personSelectOrg", title: "Выбор организации" },
        {
          id: 10,
          name: "trainingAdvanceForm",
          title: "Добавление протокола сдачи вступительных нормативов",
          form: {
            title: "Добавление протокола сдачи вступительных нормативов",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "advance-training-doc",
                label: "Прикрепленные документы",
                type: "input",
                subtype: "file",
                comment: "Прикрепленные документы",
                button: {
                  text: "Добавить документ",
                  icon: {
                    url: "/icons/adddoc.svg",
                  },
                },
                file: {
                  name: "",
                  type: "",
                  base64: "",
                },
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
            ],
          },
        },
        {
          id: 11,
          name: "trainingApproveForm",
          title: "Зачисление на спортивную подготовку",
          form: {
            title: "Зачисление на спортивную подготовку",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "approve-training-sport",
                label: "Вид спорта",
                type: "select",
                itemsList: [],
                width: 12,
                responsive: "",
                required: true,
                disabled: true,
                defaultValueLabel: "Выберите",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "approve-training-discipline",
                label: "Дисциплина",
                type: "select",
                itemsList: [],
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
            ],
          },
        },
        {
          id: 12,
          name: "trainingDenyForm",
          title: "Отказ зачисления на спортивную подготовку",
          form: {
            title: "Отказ зачисления на спортивную подготовку",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "deny-training-comment",
                label: "Причина отказа",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
            ],
          },
        },
        {
          id: 14,
          name: "trainingResponseForm",
          title: "Ответ на заявление о спортивной подготовке",
          form: {
            title: "Ответ на заявление о спортивной подготовке",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "response-training-comment",
                label: "Сообщение",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
            ],
          },
        },
        {
          id: 15,
          name: "trainingOfferForm",
          title: "Приглашение на зачисление",
          form: {
            title: "Приглашение на зачисление",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "offer-training-message",
                label: "Сообщение",
                type: "input",
                subtype: "text",
                width: 12,
                responsive: "",
                required: true,
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                focusable: true,
                value: "",
              },
              {
                id: "offer-training-doc",
                label: "Прикрепленные документы",
                type: "input",
                subtype: "file",
                comment: "Прикрепленные документы",
                button: {
                  text: "Добавить документ",
                  icon: {
                    url: "/icons/adddoc.svg",
                  },
                },
                file: {
                  name: "",
                  type: "",
                  base64: "",
                },
                width: 12,
                responsive: "",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: "",
              },
            ],
          },
        },
        {
          id: 16,
          name: "trainingCommentForm",
          title: "Сообщение",
          form: {
            title: "Сообщение",
            validity: false,
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "training-comment",
                label: "+++---+++",
                type: "textarea",
                width: 12,
                responsive: "",
                required: true,
                withoutLabel: true,
                value: "",
              },
            ],
          },
        },
        {
          id: 17,
          name: "addInvalidAppIpraForm",
          title: "Создать заявку на мероприятия ИПРА",
          formPart1: {
            title: "Создание заявки на спортивную подготовку",
            horizontal: true,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "add-invalid-app-ipra-district",
                label: "Район",
                type: "select",
                itemsList: [],
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите район",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "add-invalid-app-ipra-type",
                label: "Тип услуги",
                type: "select",
                itemsList: [],
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите тип услуги",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "add-invalid-app-ipra-service",
                label: "Фактическая услуга ИПРА",
                type: "select",
                itemsList: [
                  { id: 1, value: 1, label: "Тестовая организация 1" },
                  { id: 2, value: 2, label: "Тестовая организация 2" },
                  { id: 3, value: 3, label: "Тестовая организация 3" },
                  { id: 4, value: 4, label: "Тестовая организация 4" },
                ],
                width: 12,
                responsive: "",
                required: true,
                disabled: true,
                defaultValueLabel: "Выберите услугу",
                horizontal: true,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                values: [],
              },
              {
                id: "add-invalid-app-ipra-message",
                label: "Текст обращения",
                type: "textarea",
                width: 12,
                responsive: "",
                required: true,
                withoutLabel: true,
                value: "",
              },
            ],
          },
          formPart2: {
            title: "Создание заявки на спортивную подготовку",
            validity: false,
            horizontal: false,
            horizontalWidth: {
              label: {
                width: 4,
                responsive: "col-sm-5",
              },
              field: {
                width: 8,
                responsive: "col-sm-7",
              },
            },
            fields: [
              {
                id: "add-invalid-app-ipra-agree",
                label: "Согласие на обработку персональных данных",
                type: "checkbox",
                width: 12,
                responsive: "",
                required: true,
                defaultValueLabel: "Выберите",
                horizontal: false,
                horizontalWidth: {
                  label: {
                    width: 4,
                    responsive: "col-sm-5",
                  },
                  field: {
                    width: 8,
                    responsive: "col-sm-7",
                  },
                },
                value: false,
              },
            ],
          },
        },
      ],
    };
  },

  computed: {
    organizationsQuery: function () {
      let query = this.config.url + "organizations/?page=";
      query += this.organizations.table.pagination.page;
      query += "&pageSize=" + this.organizations.table.pagination.pageSize;
      if (this.organizations.table.filters[0].value) {
        query += "&name=" + this.organizations.table.filters[0].value;
      }
      if (this.organizations.table.filters[1].value) {
        query += "&sport=" + this.organizations.table.filters[1].value;
      }
      return query;
    },
    eventsQuery: function () {
      let query = this.config.url + "events/?page=";
      query += this.events.table.pagination.page;
      query += "&pageSize=" + this.events.table.pagination.pageSize;
      // query += "&dateStart=01.01.0001&dateEnd=31.12.3999";
      if (this.events.table.filters[0].value) {
        query += "&name=" + this.events.table.filters[0].value;
      }
      if (this.events.table.filters[1].value) {
        query += "&sport=" + this.events.table.filters[1].value;
      }
      if (this.events.table.filters[2].values.length > 0) {
        query += "&status=" + this.events.table.filters[2].values[0];
      }
      return query;
    },
    eventsPosterQuery: function () {
      let query = this.config.url + "events/?page=1&pageSize=4";
      query +=
        "&dateStart=" +
        this.calendarDdMmYyyy +
        "&dateEnd=" +
        this.calendarDdMmYyyy;
      return query;
    },
    stadiumsQuery: function () {
      let query = this.config.url + "objects/?page=";
      query += this.objects.table.pagination.page;
      query += "&pageSize=" + this.objects.table.pagination.pageSize;
      if (this.objects.table.filters[0].value) {
        query += "&name=" + this.objects.table.filters[0].value;
      }
      if (this.objects.table.filters[1].value) {
        query += "&sport=" + this.objects.table.filters[1].value;
      }
      return query;
    },
    newsQuery: function () {
      let query = this.config.url + "news/?page=";
      query += this.news.table.pagination.page;
      query += "&pageSize=" + this.news.table.pagination.pageSize;
      query += "&published=true";
      return query;
    },
    trainingAppsForOrgPassportQuery: function () {
      let query = this.config.url + "lk/organization/trainings/apps?page=";
      query += this.organizationPassport.trainings.table.pagination.page;
      query +=
        "&pageSize=" +
        this.organizationPassport.trainings.table.pagination.pageSize;
      query +=
        "&sorting=" + this.organizationPassport.trainings.table.sortColumn;
      query +=
        "&order=" +
        this.organizationPassport.trainings.table.ascendingSortOrder;
      // if (
      //   this.organizationPassport.trainings.table.filters[0].itemsList[0].value
      // ) {
      //   query +=
      //     "&dateStart=" +
      //     this.organizationPassport.trainings.table.filters[0].itemsList[0]
      //       .value;
      // }
      // if (
      //   this.organizationPassport.trainings.table.filters[0].itemsList[1].value
      // ) {
      //   query +=
      //     "&dateEnd=" +
      //     this.organizationPassport.trainings.table.filters[0].itemsList[1]
      //       .value;
      // }
      // if (
      //   this.organizationPassport.trainings.table.filters[1].values.length > 0
      // ) {
      //   query +=
      //     "&sport=" +
      //     this.organizationPassport.trainings.table.filters[2].values[0];
      // }
      // if (
      //   this.organizationPassport.trainings.table.filters[2].values.length > 0
      // ) {
      //   query +=
      //     "&status=" +
      //     this.organizationPassport.trainings.table.filters[2].values[0];
      // }
      return query;
    },
    calendarDdMmYyyy: function () {
      let day = this.calendar.day.getDate();
      let month = this.calendar.day.getMonth() + 1;
      let year = this.calendar.day.getFullYear();
      let calendarDdMmYyyy = day + "." + month + "." + year;
      return calendarDdMmYyyy;
    },
  },

  methods: {
    readComment() {
      this.modals[14].form.fields[0].value =
        this.modals[13].form.fields[0].value;
      this.config.modal.id = 16;
      this.config.modal.title = "Сообщение";
    },
    updateComment() {
      this.modals[13].form.fields[0].value =
        this.modals[14].form.fields[0].value;
      this.validateForm(this.modals[13].form);
      this.config.modal.id = 15;
      this.config.modal.title = "Приглашение на зачисление";
    },
    resetComment() {
      this.config.modal.id = 15;
      this.config.modal.title = "Приглашение на зачисление";
    },

    changePageSize(table, newPageInfo, refreshMethod) {
      table.pagination.page = newPageInfo[0];
      table.pagination.pageSize = newPageInfo[1];
      this[refreshMethod]();
    },
    changePage(table, page, refreshMethod) {
      table.pagination.page = page;
      this[refreshMethod]();
    },
    sortTable(tableAddressString, columnIndex, refreshMethod) {
      console.log(tableAddressString);
      console.log(columnIndex);
      console.log(refreshMethod);
      let tableAddressArray = tableAddressString.split(".");
      let table;
      if (tableAddressArray.length === 3) {
        table =
          this[tableAddressArray[0]][tableAddressArray[1]][
            tableAddressArray[2]
          ];
      }
      console.log(table);
      let columnSortName = table.columnsList[columnIndex].name;
      table.pagination.page = 1;
      if (table.sortColumn === columnSortName) {
        table.ascendingSortOrder = !table.ascendingSortOrder;
      } else {
        table.ascendingSortOrder = true;
        table.sortColumn = columnSortName;
      }
      this[refreshMethod]();
    },
    changeFilter(filters, newFilterData) {
      console.log("Изменяется фильтр");
      console.log(filters);
      console.log(newFilterData);
      let filterItem = filters.find(function (item) {
        if (item.id === newFilterData.id) return true;
      });
      console.log(filterItem);
      if (filterItem.type === "select") {
        filterItem.values = newFilterData.values;
      } else if (filterItem.type === "range") {
        filterItem.itemsList[newFilterData.index].value = newFilterData.value;
      } else {
        filterItem.value = newFilterData.value;
      }
    },
    resetFilter(tableData, refreshMethod) {
      console.log(tableData);
      tableData.filters.forEach(function (field) {
        if (field.type === "select") {
          field.values = [];
        } else if (field.type === "checkbox") {
          field.value = false;
        } else if (field.type === "input") {
          if (field.subtype === "file") {
            field.file.name = "";
            field.file.type = "";
            field.file.base64 = "";
          }
          field.value = "";
        } else if (field.type === "range") {
          field.itemsList[0].value = null;
          field.itemsList[1].value = null;
        }
      });
      this[refreshMethod]();
    },

    goToView(targetView) {
      this.hideModal();
      this.$router.push(targetView);
    },
    goBack(records) {
      console.log(records);
      this.$router.go(records);
    },
    editDetailsInfo(formsArray, view) {
      formsArray.forEach(function (item) {
        item.form.disabled = false;
      });
      view.editMode = true;
    },
    updateDetailsInfo(formsArray, view) {
      formsArray.forEach(function (item) {
        item.form.disabled = true;
      });
      view.editMode = false;
    },

    // Лоадер
    loaderStart(loader, loaderComment) {
      loader.isResponse = false;
      loader.isLoading = true;
      if (loaderComment) {
        loader.comment = loaderComment;
      }
    },
    loaderFinish(loader) {
      loader.isResponse = true;
      loader.isLoading = false;
    },

    // Личный кабинет физлица
    async getPersonAccountById() {
      await axios.get(this.config.url + "lk/person/").then((response) => {
        console.groupCollapsed("Личный кабинет физического лица");
        console.log(response.data);
        console.groupEnd();
        this.personAccountDB = response.data;
        this.parsePerson(response.data, "personAccount");
        this.parseInvalid(response.data, "invalidAccount");
      });
    },
    async parsePerson(personData, targetParsedData = "person") {
      // Аватар и данные для сайдбара
      // avatarFileEntryId
      if (targetParsedData === "personAccount") {
        // Аватар
        if (personData.data.avatarFileEntryId > 0) {
          let avatarImage = await axios.get(
            this.config.url + "files/" + personData.data.avatarFileEntryId
          );
          this.user.avatar.file = avatarImage.data.file;
          this.user.avatar.type = avatarImage.data.mimeType;
        }
        // Имя и почта
        this.user.name =
          personData.data.firstName + " " + personData.data.familyName;
        this.user.email = personData.data.email;
      }

      // Раздел "Личные данные"
      // Блок "ФИО"
      this[targetParsedData].profile.forms[0].form.fields[0].value =
        personData.data.familyName;
      this[targetParsedData].profile.forms[0].form.fields[1].value =
        personData.data.firstName;
      this[targetParsedData].profile.forms[0].form.fields[2].value =
        personData.data.middleName;
      // Блок "Пол и дата рождения"
      this[targetParsedData].profile.forms[1].form.fields[0].value =
        personData.data.gender;
      this[targetParsedData].profile.forms[1].form.fields[1].value =
        personData.data.birthDate;
      this[targetParsedData].profile.forms[1].form.fields[2].value =
        personData.data.birthPlace;
      // Блок "Гражданство"
      this[targetParsedData].profile.forms[2].form.fields[0].value =
        personData.data.citizenship;
      this[targetParsedData].profile.forms[2].form.fields[1].value =
        personData.data.subjectRF;
      this[targetParsedData].profile.forms[2].form.fields[2].value =
        personData.data.city;
      // Блок "Контактная информация"
      this[targetParsedData].profile.forms[3].form.fields[0].value =
        personData.data.phone;
      // Блок "Сведения о регистрации"
      this[targetParsedData].profile.forms[4].form.fields[0].value =
        personData.data.address;
      this[targetParsedData].profile.forms[4].form.fields[1].value =
        personData.data.postal;

      // Раздел "Документы"
      // Блок "Удостоверение личности"
      this[targetParsedData].docs.forms[0].form.fields[0].value =
        personData.data.docType;
      this[targetParsedData].docs.forms[0].form.fields[1].value =
        personData.data.docSeries;
      this[targetParsedData].docs.forms[0].form.fields[2].value =
        personData.data.docNumber;
      this[targetParsedData].docs.forms[0].form.fields[3].value =
        personData.data.docIssueDate;
      this[targetParsedData].docs.forms[0].form.fields[4].value =
        personData.data.docIssuer;
      this[targetParsedData].docs.forms[0].form.fields[5].value =
        personData.data.docCode;
      // Блок "ИНН"
      this[targetParsedData].docs.forms[1].form.fields[0].value =
        personData.data.inn;
      // Блок "СНИЛС"
      this[targetParsedData].docs.forms[2].form.fields[0].value =
        personData.data.snils;
      // Блок "Иные документы"

      // Раздел "Образование"
      if (personData.personEducations.length > 0) {
        // Клонирование типовой формы
        for (let i = 0; i < personData.personEducations.length - 1; i++) {
          let cloneForm = JSON.parse(
            JSON.stringify(this[targetParsedData].education.forms[0])
          );
          this[targetParsedData].education.forms.push(cloneForm);
        }
        for (let i = 0; i < personData.personEducations.length; i++) {
          this[targetParsedData].education.forms[i].id =
            personData.personEducations[i].id;
          this[targetParsedData].education.forms[i].form.fields[0].value =
            personData.personEducations[i].level;
          this[targetParsedData].education.forms[i].form.fields[1].value =
            personData.personEducations[i].name;
          this[targetParsedData].education.forms[i].form.fields[2].value =
            personData.personEducations[i].yearStart;
          this[targetParsedData].education.forms[i].form.fields[3].value =
            personData.personEducations[i].year;
        }
      }

      // Раздел "Заявки"
      // Заявки в организацию
      if (personData.personOrgApps.length > 0) {
        let appRows = [];
        personData.personOrgApps.forEach(function (item) {
          let row = [];
          row.push(item.id);
          row.push(item.id);
          row.push(item.author);
          row.push(item.date.substring(0, 10));
          row.push(item.role);
          row.push({
            type: "component",
            subtype: "app-person-status-simple",
            data: item,
          });
          if (item.orgName) {
            row.push(item.orgName);
          } else {
            row.push("");
          }
          row.push({
            type: "component",
            subtype: "app-revocate",
            data: item,
          });
          appRows.push(row);
        });
        this[targetParsedData].apps.orgApps.table.rowsList = appRows.sort(
          this.compareId
        );
      }
      // Заявки на участие в мероприятии
      if (personData.personEventApps.length > 0) {
        let appRows = [];
        personData.personEventApps.forEach(function (item) {
          let row = [];
          row.push(item.id);
          row.push(item.id);
          row.push(item.author);
          row.push(item.date.substring(0, 10));
          row.push(item.type);
          row.push({
            type: "component",
            subtype: "app-person-status-simple",
            data: item,
          });
          if (item.eventName) {
            row.push(item.eventName);
          } else {
            row.push("");
          }
          row.push({
            type: "component",
            subtype: "app-revocate",
            data: item,
          });
          appRows.push(row);
        });
        this[targetParsedData].apps.eventApps.table.rowsList = appRows.sort(
          this.compareId
        );
      }

      // Раздел "Спортивная подготовка"
      if (personData.personAppSportsTrainings.length > 0) {
        let appRows = [];
        for (let i = 0; i < personData.personAppSportsTrainings.length; i++) {
          let row = [];
          row.push(personData.personAppSportsTrainings[i].id);
          row.push(personData.personAppSportsTrainings[i].id);
          row.push(personData.personAppSportsTrainings[i].role);
          row.push(
            personData.personAppSportsTrainings[i].date.substring(0, 10)
          );
          if (+personData.personAppSportsTrainings[i].fileEntryId) {
            personData.personAppSportsTrainings[i].file =
              await this.getFileByEntryId(
                personData.personAppSportsTrainings[i].fileEntryId
              );
          }
          row.push({
            type: "component",
            subtype: "app-person-status",
            data: personData.personAppSportsTrainings[i],
          });
          row.push(personData.personAppSportsTrainings[i].orgName);
          row.push({
            type: "component",
            subtype: "app-training-revocate",
            data: personData.personAppSportsTrainings[i],
          });
          appRows.push(row);
        }
        // personData.personAppSportsTrainings.forEach(function (item) {
        //   let row = [];
        //   row.push(item.id);
        //   row.push(item.id);
        //   row.push(item.role);
        //   row.push(item.date.substring(0, 10));
        //   if (item.fileEntryId) {
        //     item.file = await this.getFileByEntryId(item.fileEntryId)
        //   }
        //   row.push({
        //     type: "component",
        //     subtype: "app-person-status",
        //     data: item,
        //   });
        //   row.push(item.orgName);
        //   row.push({
        //     type: "component",
        //     subtype: "app-training-revocate",
        //     data: item,
        //   });
        //   appRows.push(row);
        // });
        this[targetParsedData].training.table.rowsList = appRows.sort(
          this.compareId
        );
      }

      // Раздел "Массовый спорт"
      if (personData.personServicesApps.length > 0) {
        let appRows = [];
        personData.personServicesApps.forEach(function (item) {
          let row = [];
          row.push(item.id);
          row.push(item.id);
          row.push(item.nameService);
          row.push(item.date.substring(0, 10));
          row.push({
            type: "component",
            subtype: "app-person-status-simple",
            data: item,
          });
          row.push(item.objectName);
          row.push(item.day);
          row.push({
            type: "component",
            subtype: "app-revocate",
            data: item,
          });
          appRows.push(row);
        });
        this[targetParsedData].mass.table.rowsList = appRows.sort(
          this.compareId
        );
      }

      // Раздел "Дети"
      if (personData.childs.length > 0) {
        let appRows = [];
        for (let i = 0; i < personData.childs.length; i++) {
          // personData.childs.forEach(function (item) {
          let row = [];
          row.push(personData.childs[i].childData.id);
          if (personData.childs[i].childData.avatarFileEntryId > 0) {
            let childAvatarImage = await axios.get(
              this.config.url +
                "files/" +
                personData.childs[i].childData.avatarFileEntryId
            );
            row.push({
              type: "component",
              subtype: "child-avatar",
              data: {
                file: childAvatarImage.data.file,
                type: childAvatarImage.data.mimeType,
              },
            });
          } else {
            row.push({
              type: "html",
              content: "<img src='/images/default.jpg' class='child-avatar'/>",
            });
          }
          row.push(personData.childs[i].childData.fullName);
          if (personData.childs[i].childData.gender === "male") {
            row.push("Мужской");
          } else if (personData.childs[i].childData.gender === "female") {
            row.push("Женский");
          } else {
            row.push("");
          }
          row.push(personData.childs[i].childData.birthDate);
          row.push(personData.childs[i].childData.address);
          let childOrgs = { type: "html", content: "" };
          if (personData.childs[i].childOrgs.length > 0) {
            childOrgs.content += "<ul class='child-orgs'>";
            personData.childs[i].childOrgs.forEach(function (org) {
              childOrgs.content += "<li>" + org.name + "</li>";
            });
            childOrgs.content += "</ul>";
          }
          row.push(childOrgs);
          row.push("");
          appRows.push(row);
          // });
        }
        this[targetParsedData].children.table.rowsList = appRows;
      }
    },
    // async parseInvalid(personData, targetParsedData = "person") {
    //   // Раздел "Личные данные"
    //   // Блок "ФИО"
    //   this[targetParsedData].profile.forms[0].form.fields[0].value =
    //     personData.data.familyName;
    //   this[targetParsedData].profile.forms[0].form.fields[1].value =
    //     personData.data.firstName;
    //   this[targetParsedData].profile.forms[0].form.fields[2].value =
    //     personData.data.middleName;
    //   // Блок "Пол и дата рождения"
    //   this[targetParsedData].profile.forms[1].form.fields[0].value =
    //     personData.data.gender;
    //   this[targetParsedData].profile.forms[1].form.fields[1].value =
    //     personData.data.birthDate;
    //   this[targetParsedData].profile.forms[1].form.fields[2].value =
    //     personData.data.birthPlace;
    //   // Блок "Гражданство"
    //   this[targetParsedData].profile.forms[2].form.fields[0].value =
    //     personData.data.citizenship;
    //   this[targetParsedData].profile.forms[2].form.fields[1].value =
    //     personData.data.subjectRF;
    //   this[targetParsedData].profile.forms[2].form.fields[2].value =
    //     personData.data.city;
    //   // Блок "Контактная информация"
    //   this[targetParsedData].profile.forms[3].form.fields[0].value =
    //     personData.data.email;
    //   this[targetParsedData].profile.forms[3].form.fields[1].value =
    //     personData.data.phone;
    //   // Блок "Сведения о регистрации"
    //   this[targetParsedData].profile.forms[4].form.fields[0].value =
    //     personData.data.address;
    //   this[targetParsedData].profile.forms[4].form.fields[1].value =
    //     personData.data.postal;
    //
    //   // Раздел "Карточка ИПРА"
    //   // Блок "Основные сведения"
    //   this[targetParsedData].ipra.forms[0].form.fields[0].value =
    //     personData.data.fullName;
    //   this[targetParsedData].ipra.forms[0].form.fields[1].value =
    //     personData.data.birthDate;
    //   this[targetParsedData].ipra.forms[0].form.fields[2].value =
    //     personData.cripple.personIpraCard.represent;
    //   this[targetParsedData].ipra.forms[0].form.fields[3].value =
    //     personData.cripple.personIpraCard.districtName;
    //   // Блок "Организация"
    //   this[targetParsedData].ipra.forms[1].form.fields[0].value =
    //     personData.cripple.personIpraCard.orgName;
    //   this[targetParsedData].ipra.forms[1].form.fields[1].value =
    //     personData.cripple.personIpraCard.orgAddress;
    //   this[targetParsedData].ipra.forms[1].form.fields[2].value =
    //     personData.cripple.personIpraCard.orgPhone;
    //   this[targetParsedData].ipra.forms[1].form.fields[3].value =
    //     personData.cripple.personIpraCard.orgDistrictName;
    //   // Блок "Контактная информация"
    //   this[targetParsedData].ipra.forms[2].form.fields[0].value =
    //     personData.cripple.personIpraCard.phone;
    //   // Блок "Физкультурно-оздоровительные мероприятия, занятия спортом"
    //   let needless = personData.cripple.personIpraCard.isNeeded ? "Да" : "Нет";
    //   this[targetParsedData].ipra.forms[3].form.fields[0].value = needless;
    //   // Блок "ИПРА"
    //   this[targetParsedData].ipra.forms[4].form.fields[0].value =
    //     personData.cripple.personIpraCard.assignDate;
    //   this[targetParsedData].ipra.forms[4].form.fields[1].value =
    //     personData.cripple.personIpraCard.ipraNumber;
    //   this[targetParsedData].ipra.forms[4].form.fields[2].value =
    //     personData.cripple.personIpraCard.endDate;
    //   this[targetParsedData].ipra.forms[4].form.fields[3].value =
    //     personData.cripple.personIpraCard.publicDate;
    //   this[targetParsedData].ipra.forms[4].form.fields[4].value =
    //     personData.cripple.personIpraCard.limitDate;
    //   this[targetParsedData].ipra.forms[4].form.fields[5].value =
    //     personData.cripple.personIpraCard.reportStatus;
    //   this[targetParsedData].ipra.forms[4].form.fields[6].value =
    //     personData.cripple.personIpraCard.reportStatus;
    //   // Блок "Регистрация"
    //   this[targetParsedData].ipra.forms[5].form.fields[0].value =
    //     personData.cripple.personIpraCard.citizenshipName;
    //   let noLivingAddress = personData.cripple.personIpraCard.noLivingAddress
    //     ? "Да"
    //     : "Нет";
    //   this[targetParsedData].ipra.forms[5].form.fields[1].value =
    //     noLivingAddress;
    //   let noRegAddress = personData.cripple.personIpraCard.noRegAddress
    //     ? "Да"
    //     : "Нет";
    //   this[targetParsedData].ipra.forms[5].form.fields[2].value = noRegAddress;
    //   // Блок "Дополнительные поля"
    //   this[targetParsedData].ipra.forms[6].form.fields[0].value =
    //     personData.cripple.personIpraCard.sentOrgName;
    //   this[targetParsedData].ipra.forms[6].form.fields[1].value =
    //     personData.cripple.personIpraCard.sentOrgAddress;
    //   this[targetParsedData].ipra.forms[6].form.fields[2].value =
    //     personData.cripple.personIpraCard.protocolNum;
    //   this[targetParsedData].ipra.forms[6].form.fields[3].value =
    //     personData.cripple.personIpraCard.protocolDate;
    //   // Блок "Сведения об адресе"
    //   this[targetParsedData].ipra.forms[7].form.fields[0].value =
    //     personData.cripple.personIpraCard.addressTypeName;
    //   this[targetParsedData].ipra.forms[7].form.fields[1].value =
    //     personData.cripple.personIpraCard.addressString;
    //
    //   // Раздел "Документы"
    //   // Блок "Удостоверение личности"
    //   this[targetParsedData].docs.forms[0].form.fields[0].value =
    //     personData.data.docType;
    //   this[targetParsedData].docs.forms[0].form.fields[1].value =
    //     personData.data.docSeries;
    //   this[targetParsedData].docs.forms[0].form.fields[2].value =
    //     personData.data.docNumber;
    //   this[targetParsedData].docs.forms[0].form.fields[3].value =
    //     personData.data.docIssueDate;
    //   this[targetParsedData].docs.forms[0].form.fields[4].value =
    //     personData.data.docIssuer;
    //   this[targetParsedData].docs.forms[0].form.fields[5].value =
    //     personData.data.docCode;
    //   // Блок "ИНН"
    //   this[targetParsedData].docs.forms[1].form.fields[0].value =
    //     personData.data.inn;
    //   // Блок "СНИЛС"
    //   this[targetParsedData].docs.forms[2].form.fields[0].value =
    //     personData.data.snils;
    //
    //   // Раздел "Образование"
    //   if (personData.personEducations.length > 0) {
    //     // Клонирование типовой формы
    //     for (let i = 0; i < personData.personEducations.length - 1; i++) {
    //       let cloneForm = JSON.parse(
    //         JSON.stringify(this[targetParsedData].education.forms[0])
    //       );
    //       this[targetParsedData].education.forms.push(cloneForm);
    //     }
    //     for (let i = 0; i < personData.personEducations.length; i++) {
    //       this[targetParsedData].education.forms[i].id =
    //         personData.personEducations[i].id;
    //       this[targetParsedData].education.forms[i].form.fields[0].value =
    //         personData.personEducations[i].level;
    //       this[targetParsedData].education.forms[i].form.fields[1].value =
    //         personData.personEducations[i].name;
    //       this[targetParsedData].education.forms[i].form.fields[2].value =
    //         personData.personEducations[i].yearStart;
    //       this[targetParsedData].education.forms[i].form.fields[3].value =
    //         personData.personEducations[i].year;
    //     }
    //   }
    // },

    // Справочники
    // Назначение селектам форм ФЛ значений вшитых справочников
    setOrgRoles() {
      this.modals[2].formPart2.fields[0].itemsList = this.dictionaries.orgRoles;
      console.groupCollapsed("Справочник ролей организаций");
      console.log(this.dictionaries.orgRoles);
      console.groupEnd();
    },
    setEventRoles() {
      this.modals[3].formPart2.fields[0].itemsList =
        this.dictionaries.eventRoles;
      console.groupCollapsed("Справочник ролей событий");
      console.log(this.dictionaries.eventRoles);
      console.groupEnd();
    },
    setTrainingAppealTypes() {
      this.modals[4].formPart1.fields[1].itemsList =
        this.dictionaries.trainingAppealTypes;
      console.groupCollapsed("Справочник типов обращений на спортподготовку");
      console.log(this.dictionaries.trainingAppealTypes);
      console.groupEnd();
    },
    setGenders() {
      this.childCreateData[1].form.fields[0].itemsList =
        this.dictionaries.genders;
      this.childUpdateData[1].form.fields[0].itemsList =
        this.dictionaries.genders;
      console.groupCollapsed("Справочник полов");
      console.log(this.dictionaries.trainingAppealTypes);
      console.groupEnd();
    },
    setEventStatuses() {
      this.events.table.filters[2].itemsList = this.dictionaries.eventStatuses;
      console.groupCollapsed("Справочник статусов мероприятий");
      console.log(this.dictionaries.eventStatuses);
      console.groupEnd();
    },
    async getEventStatuses() {
      await axios.get(this.config.url + "events/statuses").then((response) => {
        this.parseEventStatuses(response.data.eventStatusDatas);
        console.groupCollapsed("Справочник статусов мероприятий");
        console.log(response.data);
        console.groupEnd();
      });
    },
    parseEventStatuses(eventStatusesArray) {
      let eventStatusesDict = [];
      if (eventStatusesArray.length > 0) {
        eventStatusesArray.forEach(function (item) {
          let eventStatus = {};
          eventStatus.id = item.id;
          eventStatus.value = item.id;
          eventStatus.label = item.name;
          eventStatusesDict.push(eventStatus);
        });
      }
      this.dictionaries.eventStatuses = eventStatusesDict;
      this.events.table.filters[2].itemsList = eventStatusesDict;
    },
    // Запрос справочников с сервера, необходимых при загрузке приложения, и присвоение их значений полям форм ФЛ
    async getIdentifyDocuments() {
      await axios.get(this.config.url + "dicts/doctype").then((response) => {
        this.parseIdentifyDocuments(response.data.dictDocTypeDatas);
        console.groupCollapsed(
          "Справочник документов, удостоверяющих личность"
        );
        console.log(response.data);
        console.groupEnd();
      });
    },
    parseIdentifyDocuments(docTypesArray) {
      let docTypesDict = [];
      if (docTypesArray.length > 0) {
        docTypesArray.forEach(function (item) {
          let docType = {};
          docType.id = item.id;
          docType.value = item.id;
          docType.label = item.name;
          docTypesDict.push(docType);
        });
      }
      this.dictionaries.identifyDocuments = docTypesDict;
      this.modals[0].form.fields[0].itemsList = docTypesDict;
      this.childCreateData[3].form.fields[0].itemsList = docTypesDict;
      this.childUpdateData[3].form.fields[0].itemsList = docTypesDict;
    },
    async getEducationGrades() {
      await axios.get(this.config.url + "dicts/education").then((response) => {
        this.parseEducationGrades(response.data.dictEducationDatas);
        console.groupCollapsed("Справочник типов учебных заведений");
        console.log(response.data);
        console.groupEnd();
      });
    },
    parseEducationGrades(educationGradesArray) {
      let educationGradesDict = [];
      if (educationGradesArray.length > 0) {
        educationGradesArray.forEach(function (item) {
          let grade = {};
          grade.id = item.id;
          grade.value = item.id;
          grade.label = item.name;
          educationGradesDict.push(grade);
        });
      }
      this.dictionaries.educationGrades = educationGradesDict;
      this.modals[1].form.fields[0].itemsList = educationGradesDict;
    },
    async getAllOrganizations() {
      await axios
        .get(this.config.url + "organizations/list/short")
        .then((response) => {
          console.groupCollapsed("Справочник организаций");
          console.log(response.data);
          console.groupEnd();
          this.parseAllOrganizations(response.data.organizationDataShorts);
        });
    },
    parseAllOrganizations(orgsArray) {
      let itemsList = [];
      orgsArray.forEach(function (org) {
        let item = {};
        item.id = org.id;
        item.value = org.id;
        item.label = org.name;
        itemsList.push(item);
      });
      this.dictionaries.organizations = itemsList;
      this.modals[2].formPart1.fields[0].itemsList = itemsList;
    },
    async getAllSports() {
      await axios
        .get(this.config.url + "lk/person/trainings/apps/sports")
        .then((response) => {
          console.groupCollapsed("Справочник видов спорта");
          console.log(response.data);
          console.groupEnd();
          this.parseAllSports(response.data.dictSportTypeDatas);
        });
    },
    parseAllSports(sportsArray) {
      let itemsList = [];
      sportsArray.forEach(function (sport) {
        let item = {};
        item.id = sport.id;
        item.value = sport.id;
        item.label = sport.name;
        itemsList.push(item);
      });
      this.dictionaries.allSports = itemsList;
      this.modals[4].formPart1.fields[0].itemsList = itemsList;
    },
    async getEventsForApp() {
      await axios
        .get(this.config.url + "lk/person/events/apps/events")
        .then((response) => {
          console.groupCollapsed("Справочник событий для записи");
          console.log(response.data);
          console.groupEnd();
          this.parseEventsForApp(response.data.eventDataShorts);
        });
    },
    parseEventsForApp(eventsArray) {
      let itemsList = [];
      eventsArray.forEach(function (event) {
        let item = {};
        item.id = event.id;
        item.value = event.id;
        item.label = event.name;
        itemsList.push(item);
      });
      this.dictionaries.eventsForApp = itemsList;
      this.modals[3].formPart1.fields[0].itemsList = itemsList;
    },
    async getObjectsForPublic() {
      await axios
        .get(this.config.url + "lk/person/services/apps/objects")
        .then((response) => {
          console.groupCollapsed("Справочник объектов для записи на масспорт");
          console.log(response.data);
          console.groupEnd();
          this.parseObjectsForPublic(response.data.objectDataShorts);
        });
    },
    parseObjectsForPublic(objectsArray) {
      let itemsList = [];
      objectsArray.forEach(function (object) {
        let item = {};
        item.id = object.id;
        item.value = object.id;
        item.label = object.name;
        itemsList.push(item);
      });
      this.dictionaries.objectsForPublic = itemsList;
      this.modals[6].formPart1.fields[0].itemsList = itemsList;
    },
    // Запрос справочников с сервера, необходимых при заполнении форм физлицом, и присвоение их значений полям форм ФЛ
    async getOrgsForSportTraining(sportId) {
      await axios
        .get(
          this.config.url +
            "lk/person/trainings/apps/sports/" +
            sportId +
            "/organizations"
        )
        .then((response) => {
          console.groupCollapsed(
            "Список организаций, в которых доступна спортподготовка по виду спорта с id=" +
              sportId
          );
          console.log(response.data);
          console.groupEnd();
          this.parseOrgsForSportTraining(response.data.organizationDataShorts);
        });
    },
    parseOrgsForSportTraining(orgsArray) {
      if (orgsArray.length > 0) {
        let itemsList = [];
        orgsArray.forEach(function (org) {
          let item = {};
          item.id = org.id;
          item.value = org.id;
          item.label = org.name;
          itemsList.push(item);
        });
        this.dictionaries.orgsForSportTraining = itemsList;
        this.modals[4].formPart1.fields[2].itemsList = itemsList;
        this.modals[4].formPart1.fields[2].values = [];
      } else {
        this.dictionaries.orgsForSportTraining = [];
        this.modals[4].formPart1.fields[2].itemsList = [];
        this.modals[4].formPart1.fields[2].values = [];
      }
    },
    // Данные о детях получены при запросе ЛК ФЛ и берутся из поля personAccountDB
    async getServicesForStadiumPublic(stadiumId) {
      await axios
        .get(
          this.config.url +
            "lk/person/services/apps/objects/" +
            stadiumId +
            "/services"
        )
        .then((response) => {
          console.groupCollapsed(
            "Список доступных услуг спортивного объекта с id=" + stadiumId
          );
          console.log(response.data);
          console.groupEnd();
          // Исправить servicesDataShorts после того, как будет получен ответ и уточнено поле
          this.parseServicesForStadiumPublic(response.data.objectServices);
        });
    },
    parseServicesForStadiumPublic(servicesArray) {
      if (servicesArray.length > 0) {
        let itemsList = [];
        servicesArray.forEach(function (service) {
          let item = {};
          // Уточнить наименование ключей после получения ответа
          item.id = service.id;
          item.value = service.id;
          item.label = service.name;
          itemsList.push(item);
        });
        this.dictionaries.servicesForStadiumPublic = itemsList;
        this.modals[6].formPart2.fields[0].itemsList = itemsList;
        this.modals[6].formPart2.fields[0].values = [];
        this.modals[6].formPart2.fields[0].disabled = false;
        this.modals[6].formPart2.fields[1].disabled = false;
        this.modals[6].formPart2.fields[2].disabled = false;
      } else {
        this.dictionaries.servicesForStadiumPublic = [];
        this.modals[6].formPart2.fields[0].itemsList = [];
        this.modals[6].formPart2.fields[0].values = [];
      }
    },

    // Новости
    getNews() {
      axios.get(this.newsQuery).then((response) => {
        console.groupCollapsed("Постраничные новости");
        console.log(response.data);
        console.groupEnd();
        this.news.table.pagination.itemsTotal = response.data.totalCount;
        this.parseNews(response.data.items);
      });
    },
    async parseNews(newsArray) {
      // Раздел "Все новости"
      if (newsArray.length > 0) {
        let allNews = [];
        // newsArray.forEach(function (item, index) {
        for (let i = 0; i < newsArray.length; i++) {
          let news = {};
          news.id = newsArray[i].id;
          news.title = newsArray[i].title;
          // Аннотация отсутствует
          news.annotation = "Пояснение содержания статьи";
          news.createDate = newsArray[i].createDate;
          news.author = newsArray[i].author;
          news.text = newsArray[i].text;
          if (newsArray[i].newsCategorieses > 0) {
            let categorieses = [];
            newsArray[i].newsCategorieses.forEach(function (item) {
              categorieses.push(item);
            });
            news.categories = categorieses;
          } else {
            news.categories = [];
          }
          news.image = {};
          if (newsArray[i].imageFileId > 0) {
            let newsImage = await axios.get(
              this.config.url + "files/" + newsArray[i].imageFileId
            );
            news.image.src = newsImage.data.file;
            news.image.type = newsImage.data.mimeType;
            news.imagePosition = "top";
          } else {
            news.image.src = "";
          }
          allNews.push(news);
        }
        // });
        this.news.allNews = allNews;
      }
    },
    getHotNews() {
      axios
        .get(this.config.url + "news/?page=1&pageSize=4&published=true")
        .then((response) => {
          console.groupCollapsed("Главные новости");
          console.log(response.data);
          console.groupEnd();
          this.parseHotNews(response.data.items);
        });
    },
    async parseHotNews(newsArray) {
      // Раздел "Главные новости"
      if (newsArray.length > 0) {
        let allNews = [];
        // newsArray.forEach(function (item) {
        for (let i = 0; i < newsArray.length; i++) {
          if (i >= 4) break;
          let news = {};
          news.id = newsArray[i].id;
          news.title = newsArray[i].title;
          news.createDate = newsArray[i].createDate;
          news.author = newsArray[i].author;
          news.text = newsArray[i].text;
          news.annotation = "";
          if (newsArray[i].newsCategorieses > 0) {
            let categorieses = [];
            newsArray[i].newsCategorieses.forEach(function (item) {
              categorieses.push(item);
            });
            news.categories = categorieses;
          } else {
            news.categories = ["Легкая атлетика"];
          }
          news.image = {};
          if (newsArray[i].imageFileId > 0) {
            let newsImage = await axios.get(
              this.config.url + "files/" + newsArray[i].imageFileId
            );
            news.image.src = newsImage.data.file;
            news.image.type = newsImage.data.mimeType;
            news.imagePosition = "full";
          } else {
            news.image.src = "";
          }
          news.datePublish = newsArray[i].createDate;
          allNews.push(news);
        }
        // });
        this.news.hotNews = allNews;
      }
    },
    async newsRead(newsId) {
      this.loaderStart(this.newsArticle.loader);
      this.$router.push("/newsarticle/" + newsId).catch(() => {});
      await this.getNewsArticle(newsId);
      setTimeout(
        this.loaderFinish,
        this.config.loadersDelay,
        this.newsArticle.loader
      );
    },
    async getNewsArticle(newsId) {
      axios.get(this.config.url + "news/" + newsId).then((response) => {
        console.groupCollapsed("Новость с id=" + newsId);
        console.log(response.data);
        console.groupEnd();
        this.parseNewsArticle(response.data);
      });
    },
    async parseNewsArticle(newsArticleData) {
      let newsArticle = {};
      newsArticle.id = newsArticleData.id;
      newsArticle.title = newsArticleData.title;
      newsArticle.createDate = newsArticleData.createDate;
      newsArticle.author = newsArticleData.author;
      newsArticle.text = newsArticleData.text;
      if (newsArticleData.newsCategorieses > 0) {
        let categorieses = [];
        newsArticleData.newsCategorieses.forEach(function (item) {
          categorieses.push(item);
        });
        newsArticle.categories = categorieses;
      } else {
        newsArticle.categories = [];
      }
      newsArticle.image = {};
      if (newsArticleData.imageFileId > 0) {
        let newsImage = await axios.get(
          this.config.url + "files/" + newsArticleData.imageFileId
        );
        newsArticle.image.src = newsImage.data.file;
        newsArticle.image.type = newsImage.data.mimeType;
        newsArticle.imagePosition = "top";
      } else {
        newsArticle.image.src = "";
      }
      this.newsArticle.newsArticleData = newsArticle;
    },

    // Справочная
    getFaq() {
      axios.get(this.config.url + "faqs").then((response) => {
        this.parseFaq(response.data.faqDatas);
        console.groupCollapsed("Справочная");
        console.log(response.data);
        console.groupEnd();
      });
    },
    parseFaq(faqArray) {
      if (faqArray.length > 0) {
        let faqs = [];
        faqArray.forEach(function (item, index) {
          let faq = {};
          faq.id = "faq" + index;
          faq.title = item.question;
          faq.content = item.answer;
          faqs.push(faq);
        });
        this.faq.items = faqs;
      }
    },

    // События
    getEvents() {
      axios.get(this.eventsQuery).then((response) => {
        console.groupCollapsed("Список событий");
        console.log(response.data);
        console.groupEnd();
        this.eventsDB = response.data;
        this.events.table.pagination.itemsTotal = response.data.totalCount;
        this.parseEvents(response.data.items);
      });
    },
    parseEvents(eventsArray) {
      if (eventsArray.length > 0) {
        let eventsTableRows = [];
        eventsArray.forEach(function (item) {
          let row = [];
          row.push(item.data.id);
          row.push(item.data.calendarPlan);
          row.push(item.data.name);
          row.push(item.data.dateStart + " - " + item.data.dateEnd);
          row.push(item.data.place);
          row.push(item.data.status);
          row.push("");
          eventsTableRows.push(row);
        });
        this.events.table.rowsList = eventsTableRows;
      } else {
        this.events.table.rowsList = [];
      }
    },
    getEventsPoster() {
      axios.get(this.eventsPosterQuery).then((response) => {
        console.groupCollapsed("Список событий для афиши");
        console.log(response.data);
        console.groupEnd();
        this.eventsPosterDB = response.data;
      });
    },
    findEventsByName(name) {
      let query = this.config.url + "events/?page=";
      query += this.events.table.pagination.page;
      query += "&pageSize=" + this.events.table.pagination.pageSize;
      if (this.events.table.searchField) {
        query += "&name=" + name;
      }
      axios.get(query).then((response) => {
        console.groupCollapsed("Список мероприятий, найденных по наименованию");
        console.log(response.data);
        console.groupEnd();
        this.events.table.pagination.itemsTotal = response.data.totalCount;
        this.parseEvents(response.data.items);
      });
    },
    async eventRead(eventId) {
      this.loaderStart(this.event.loader);
      this.event.disciplines.selectedDisciplineId = null;
      this.event.disciplines.sports.table.selectedRowPrimaryValue = null;
      // Выбор вкладки "Общая информация"
      for (let i = 0; i < this.event.tabs.length; i++) {
        if (this.event.tabs[i].title === "Общая информация") {
          this.event.tabs[i].active = true;
        } else {
          this.event.tabs[i].active = false;
        }
      }
      this.$router.push("/event/" + eventId).catch(() => {});
      await this.getEvent(eventId);
      setTimeout(
        this.loaderFinish,
        this.config.loadersDelay,
        this.event.loader
      );
    },
    async getEvent(eventId) {
      axios.get(this.config.url + "events/" + eventId).then((response) => {
        console.groupCollapsed("Событие с id=" + eventId);
        console.log(response.data);
        console.groupEnd();
        this.parseEvent(response.data);
      });
    },
    async parseEvent(eventData) {
      this.event.id = eventData.data.id;
      // Раздел «Общая информация»
      // Общая информация
      this.event.basic.forms[0].form.fields[0].value =
        eventData.data.calendarPlan;
      this.event.basic.forms[0].form.fields[1].value = eventData.data.name;
      this.event.basic.forms[0].form.fields[2].value = eventData.data.group;
      this.event.basic.forms[0].form.fields[3].value = eventData.data.sponsor;

      // Дата и место проведения
      this.event.basic.forms[1].form.fields[0].value = eventData.data.place;
      this.event.basic.forms[1].form.fields[1].value = eventData.data.dateApp;
      this.event.basic.forms[1].form.fields[2].value = eventData.data.dateStart;
      this.event.basic.forms[1].form.fields[3].value = eventData.data.dateEnd;

      // Виды спорта
      if (eventData.eventSports.length > 0) {
        let eventSportsTableRows = [];
        for (let i = 0; i < eventData.eventSports.length; i++) {
          let row = [];
          row.push(i + 1 + "");
          row.push(i + 1 + "");
          row.push(eventData.eventSports[i].sport);
          row.push(eventData.eventSports[i].discipline);
          eventSportsTableRows.push(row);
        }
        this.event.basic.sports.table.rowsList = eventSportsTableRows;
      } else {
        this.event.basic.sports.table.rowsList = [];
      }

      // Группы участников по полу и возрасту
      if (eventData.eventAgeGroups.length > 0) {
        let eventGroupsTableRows = [];
        for (let i = 0; i < eventData.eventAgeGroups.length; i++) {
          let row = [];
          row.push(i + 1 + "");
          row.push(i + 1 + "");
          row.push(eventData.eventAgeGroups[i].age);
          row.push(eventData.eventAgeGroups[i].count);
          eventGroupsTableRows.push(row);
        }
        this.event.basic.groups.table.rowsList = eventGroupsTableRows;
      } else {
        this.event.basic.groups.table.rowsList = [];
      }

      // Файл
      if (eventData.eventFiles.length > 0) {
        let eventDocuments = [];
        let files = [];
        for (let i = 0; i < eventData.eventFiles.length; i++) {
          let document = {};
          document.id = eventData.eventFiles[i].id;
          document.name = eventData.eventFiles[i].fileName;
          document.title = eventData.eventFiles[i].fileTitle;
          document.fileEntryId = eventData.eventFiles[i].fileEntryId;
          let request =
            this.config.url + "files/" + eventData.eventFiles[i].fileEntryId;
          files.push(axios.get(request));
          eventDocuments.push(document);
        }
        await Promise.all(files).then(function (results) {
          for (let i = 0; i < results.length; i++) {
            eventDocuments[i].file = results[i].data;
          }
        });
        this.event.documents = eventDocuments;
      } else {
        this.event.documents = [];
      }

      // Дисциплины
      // Дисциплины
      if (eventData.eventDisciplines.length > 0) {
        let eventDisciplinesSportsTableRows = [];

        for (let i = 0; i < eventData.eventDisciplines.length; i++) {
          let row = [];
          row.push(eventData.eventDisciplines[i].id);
          row.push(i + 1 + "");
          row.push(eventData.eventDisciplines[i].eventSport);
          row.push(eventData.eventDisciplines[i].eventAgeGroup);
          eventDisciplinesSportsTableRows.push(row);
        }

        this.event.disciplines.sports.table.rowsList =
          eventDisciplinesSportsTableRows;
      } else {
        this.event.disciplines.sports.table.rowsList = [];
      }

      // Протоколы
      if (eventData.eventDisciplineFiles.length > 0) {
        let docs = [];
        let files = [];
        for (let i = 0; i < eventData.eventDisciplineFiles.length; i++) {
          let request =
            this.config.url +
            "files/" +
            eventData.eventDisciplineFiles[i].fileEntryId;
          files.push(axios.get(request));
          docs.push(eventData.eventDisciplineFiles[i]);
        }
        await Promise.all(files).then(function (results) {
          for (let i = 0; i < results.length; i++) {
            docs[i].file = results[i].data;
          }
        });
        this.event.disciplines.docs = docs;
      } else {
        this.event.disciplines.docs = [];
      }

      // Судьи
      if (eventData.eventDisciplineReferees.length > 0) {
        let eventRefereeTableRows = [];
        for (let i = 0; i < eventData.eventDisciplineReferees.length; i++) {
          let row = [];
          row.push(eventData.eventDisciplineReferees[i].discId);
          row.push(i + 1 + "");
          row.push(eventData.eventDisciplineReferees[i].refereeName);
          row.push(eventData.eventDisciplineReferees[i].function);
          eventRefereeTableRows.push(row);
        }
        this.event.disciplines.referee.table.rowsList = eventRefereeTableRows;
        this.event.disciplines.referee.allEventRefereeRowsList =
          eventRefereeTableRows;
      } else {
        this.event.disciplines.referee.table.rowsList = [];
        this.event.disciplines.referee.allEventRefereeRowsList = [];
      }

      // Участники (рекорды)
      if (eventData.eventDisciplineReferees.length > 0) {
        let eventSportsmansTableRows = [];
        for (let i = 0; i < eventData.eventDisciplineSportsmans.length; i++) {
          let row = [];
          row.push(eventData.eventDisciplineSportsmans[i].discId);
          row.push(i + 1 + "");
          row.push(eventData.eventDisciplineSportsmans[i].sportsmanName);
          row.push(eventData.eventDisciplineSportsmans[i].sportsmanBirthYear);
          row.push(eventData.eventDisciplineSportsmans[i].result);
          row.push(eventData.eventDisciplineSportsmans[i].unit);
          row.push(eventData.eventDisciplineSportsmans[i].recordType);
          row.push(eventData.eventDisciplineSportsmans[i].recordDate);
          eventSportsmansTableRows.push(row);
        }
        this.event.disciplines.sportsmans.table.rowsList =
          eventSportsmansTableRows;
        this.event.disciplines.sportsmans.allSportsmanRowsList =
          eventSportsmansTableRows;
      } else {
        this.event.disciplines.sportsmans.table.rowsList = [];
        this.event.disciplines.sportsmans.allSportsmanRowsList = [];
      }

      // Участники
      // Возможно, ошибочно распарсено
      if (eventData.eventDisciplineSportsmans.length > 0) {
        let eventParticipantsTableRows = [];

        // eventData.eventDisciplineSportsmans.forEach(function (item, index) {
        //   let row = [];
        //   row.push(index + 1);
        //   row.push(index + 1);
        //   row.push(item.sportsmanName);
        //   row.push(item.recordDate);
        //   row.push("");
        //   eventParticipantsTableRows.push(row);
        // });

        for (let i = 0; i < eventData.eventDisciplineSportsmans.length; i++) {
          let row = [];
          row.push(i + 1 + "");
          row.push(i + 1 + "");
          row.push(eventData.eventDisciplineSportsmans[i].sportsmanName);
          row.push(eventData.eventDisciplineSportsmans[i].sportsmanBirthYear);
          let disc = eventData.eventDisciplines.find(
            (item) => item.id === eventData.eventDisciplineSportsmans[i].discId
          );
          row.push(disc.eventSport);
          eventParticipantsTableRows.push(row);
        }

        this.event.participants.table.rowsList = eventParticipantsTableRows;
      } else {
        this.event.participants.table.rowsList = [];
      }

      // Заявки
      if (eventData.eventApps.length > 0) {
        let appRows = [];
        for (let i = 0; i < eventData.eventApps.length; i++) {
          let row = [];
          row.push(eventData.eventApps[i].id);
          row.push(eventData.eventApps[i].id);
          row.push(eventData.eventApps[i].author);
          row.push(eventData.eventApps[i].date.substring(0, 10));
          row.push(eventData.eventApps[i].type);
          row.push(eventData.eventApps[i].status);
          row.push(eventData.data.sponsor);
          row.push({
            type: "component",
            subtype: "app-decision",
            data: eventData.eventApps[i],
          });
          appRows.push(row);
        }
        this.event.apps.table.rowsList = appRows.sort(this.compareId);
      } else {
        this.event.apps.table.rowsList = [];
      }
    },
    disciplineRead(discId) {
      this.event.disciplines.selectedDisciplineId = discId;
      this.event.disciplines.referee.table.rowsList =
        this.event.disciplines.referee.allEventRefereeRowsList.filter(
          (item) => item[0] === discId
        );
      for (
        let i = 0;
        i < this.event.disciplines.referee.table.rowsList.length;
        i++
      ) {
        this.event.disciplines.referee.table.rowsList[i][1] = i + 1 + "";
      }
      this.event.disciplines.sportsmans.table.rowsList =
        this.event.disciplines.sportsmans.allSportsmanRowsList.filter(
          (item) => item[0] === discId
        );
      for (
        let i = 0;
        i < this.event.disciplines.sportsmans.table.rowsList.length;
        i++
      ) {
        this.event.disciplines.sportsmans.table.rowsList[i][1] = i + 1 + "";
      }
      if (
        discId === this.event.disciplines.sports.table.selectedRowPrimaryValue
      ) {
        this.event.disciplines.sports.table.selectedRowPrimaryValue = null;
        this.event.disciplines.selectedDisciplineId = null;
      } else {
        this.event.disciplines.sports.table.selectedRowPrimaryValue = discId;
      }
    },
    resetDiscipline() {
      this.event.disciplines.selectedDisciplineId = null;
      this.event.disciplines.sports.table.selectedRowPrimaryValue = null;
    },
    showModalAppEventWithEvent(eventId) {
      let newFormData = {
        id: "add-person-app-event-event",
        values: [eventId],
      };
      this.changeAddAppPublic(this.modals[3].formPart1, newFormData);
      this.showModal(4);
    },

    // Спортивные объекты
    getObjects() {
      axios.get(this.stadiumsQuery).then((response) => {
        this.objects.table.pagination.itemsTotal = response.data.totalCount;
        this.parseObjects(response.data.items);
        console.groupCollapsed("Список спортивных объектов");
        console.log(response.data);
        console.groupEnd();
      });
    },
    async parseObjects(objectsArray) {
      if (objectsArray.length > 0) {
        let objectsTableRows = [];
        for (let i = 0; i < objectsArray.length; i++) {
          let row = [];
          row.push(objectsArray[i].data.id);
          if (objectsArray[i].data.avatarFileEntryId > 0) {
            let stadiumImage = await axios.get(
              this.config.url +
                "files/" +
                objectsArray[i].data.avatarFileEntryId
            );
            objectsArray[i].data.imageFile = stadiumImage.data.file;
            objectsArray[i].data.imageType = stadiumImage.data.mimeType;
          }
          row.push({
            type: "component",
            subtype: "stadium-main",
            data: objectsArray[i].data,
          });
          row.push({
            type: "component",
            subtype: "stadium-contacts",
            data: objectsArray[i].data,
          });
          if (objectsArray[i].objectParts.length > 0) {
            let partsContent = "<ul>";
            objectsArray[i].objectParts.forEach(function (stadiumPart) {
              partsContent += "<li>" + stadiumPart.name + "</li>";
            });
            partsContent += "</ul>";
            row.push({
              type: "html",
              content: partsContent,
            });
          } else {
            row.push("");
          }
          if (objectsArray[i].objectSportTypes.length > 0) {
            let sportsArray = [];
            objectsArray[i].objectSportTypes.forEach(function (item) {
              if (!sportsArray.includes(item.sportTypeName)) {
                sportsArray.push(item.sportTypeName);
              }
            });
            let sportsContent = "<ul>";
            sportsArray.forEach(function (stadiumName) {
              sportsContent += "<li>" + stadiumName + "</li>";
            });
            sportsContent += "</ul>";
            row.push({
              type: "html",
              content: sportsContent,
            });
          } else {
            row.push("");
          }
          objectsTableRows.push(row);
        }
        this.objects.table.rowsList = objectsTableRows;
      } else {
        this.objects.table.rowsList = [];
      }
    },
    findStadiumsByName(name) {
      let query = this.config.url + "objects/?page=";
      query += this.objects.table.pagination.page;
      query += "&pageSize=" + this.objects.table.pagination.pageSize;
      if (this.objects.table.searchField) {
        query += "&name=" + name;
      }
      axios.get(query).then((response) => {
        console.groupCollapsed(
          "Список спортивных объектов, найденных по наименованию"
        );
        console.log(response.data);
        console.groupEnd();
        this.objects.table.pagination.itemsTotal = response.data.totalCount;
        this.parseObjects(response.data.items);
      });
    },
    async stadiumRead(stadiumId) {
      this.loaderStart(this.stadium.loader);
      this.$router.push("/stadium/" + stadiumId).catch(() => {});
      await this.getStadium(stadiumId);
      setTimeout(
        this.loaderFinish,
        this.config.loadersDelay,
        this.stadium.loader
      );
    },
    // Переделать getStadium и parseStadium по аналогии с событиями(events)
    async getStadium(objectId) {
      await axios
        .get(this.config.url + "objects/" + objectId)
        .then((response) => {
          console.groupCollapsed("Спортивный объект с id=" + objectId);
          console.log(response.data);
          console.groupEnd();
          this.stadiumDB = response.data;
          this.parseStadium(response.data);
        });
      if (this.stadiumDB.visitRules.visitRuleFiles.length > 0) {
        console.log("Найдены файлы правил");
        let rulesFiles = [];
        let fileRequestsArray = [];
        for (
          let i = 0;
          i < this.stadiumDB.visitRules.visitRuleFiles.length;
          i++
        ) {
          let request =
            this.config.url +
            "files/" +
            this.stadiumDB.visitRules.visitRuleFiles[i].fileEntryId;
          fileRequestsArray.push(axios.get(request));
          rulesFiles.push(this.stadiumDB.visitRules.visitRuleFiles[i]);
        }
        await Promise.all(fileRequestsArray).then(function (results) {
          for (let i = 0; i < results.length; i++) {
            rulesFiles[i].file = results[i].data;
          }
        });
        console.log("Правила");
        console.log(rulesFiles);

        this.stadium.visitRules.visitRuleFiles = rulesFiles;
      } else {
        console.log("Файлы правил не найдены");
        this.stadium.visitRules.visitRuleFiles = [];
      }
    },
    async parseStadium(stadiumData) {
      console.log("Стадион распарсен " + stadiumData);

      if (stadiumData.data.avatarFileEntryId > 0) {
        let stadiumImage = await this.getFileByEntryId(
          stadiumData.data.avatarFileEntryId
        );
        stadiumData.data.imageFile = stadiumImage.file;
        stadiumData.data.imageType = stadiumImage.mimeType;
        // this.stadium.data.imageFile = stadiumImage.file;
        // this.stadium.data.imageType = stadiumImage.mimeType;
        this.stadium.data = stadiumData.data;
      } else {
        this.stadium.data = stadiumData.data;
      }
      this.stadium.objectAppServiceses = stadiumData.objectAppServiceses;
      this.stadium.objectParts = stadiumData.objectParts;
      this.stadium.objectSchedules = stadiumData.objectSchedules;
      this.stadium.objectServices = stadiumData.objectServices;
      this.stadium.objectSportTypes = stadiumData.objectSportTypes;

      // Заявки на массовый спорт
      if (stadiumData.objectAppServiceses.length > 0) {
        let appRows = [];
        for (let i = 0; i < stadiumData.objectAppServiceses.length; i++) {
          let row = [];
          row.push(stadiumData.objectAppServiceses[i].id);
          row.push(stadiumData.objectAppServiceses[i].id);
          row.push(stadiumData.objectAppServiceses[i].author);
          row.push(stadiumData.objectAppServiceses[i].date.substring(0, 10));
          // Добавить роль, когда придет с ЗК
          row.push("");
          row.push(stadiumData.objectAppServiceses[i].status);
          row.push(stadiumData.data.name);
          row.push({
            type: "component",
            subtype: "app-decision",
            data: stadiumData.objectAppServiceses[i],
          });
          appRows.push(row);
        }
        this.stadium.apps.table.rowsList = appRows.sort(this.compareId);
      } else {
        this.stadium.apps.table.rowsList = [];
      }

      //     this[targetParsedData].children.table.rowsList = appRows;
      //   }

      this.stadium.visitRules.visitRules = stadiumData.visitRules.visitRules;
    },
    showModalAppPublicWithOrg(stadiumId) {
      let newFormData = {
        id: "add-person-app-public-object",
        values: [stadiumId],
      };
      this.changeAddAppPublic(this.modals[6].formPart1, newFormData);
      this.showModal(7);
    },

    // Юридические лица
    getOrganizations() {
      axios.get(this.organizationsQuery).then((response) => {
        console.groupCollapsed("Список юридических лиц");
        console.log(response.data);
        console.groupEnd();
        this.organizationsDB = response.data.items;
        this.organizations.table.pagination.itemsTotal =
          response.data.totalCount;
        this.parseOrganizations(response.data.items);
      });
    },
    parseOrganizations(organizationsArray) {
      if (organizationsArray.length > 0) {
        let appRows = [];
        organizationsArray.forEach(function (item) {
          let row = [];
          row.push(item.data.id);
          row.push(item.data.name);
          row.push(item.data.addressJur);
          let sports = "";
          if (item.organizationSports.length > 0) {
            item.organizationSports.forEach(function (sportItem) {
              sports += sportItem.sport + "; ";
            });
          }
          row.push(sports);
          row.push(item.data.status);
          appRows.push(row);
        });
        this.organizations.table.rowsList = appRows;
      } else {
        this.organizations.table.rowsList = [];
      }
    },
    findOrgsByName(name) {
      let query = this.config.url + "organizations/?page=";
      query += this.organizations.table.pagination.page;
      query += "&pageSize=" + this.organizations.table.pagination.pageSize;
      if (this.organizations.table.searchField) {
        query += "&name=" + name;
      }
      axios.get(query).then((response) => {
        console.groupCollapsed(
          "Список юридических лиц, найденных по наименованию"
        );
        console.log(response.data);
        console.groupEnd();
        this.organizations.table.pagination.itemsTotal =
          response.data.totalCount;
        this.parseOrganizations(response.data.items);
      });
    },
    async organizationRead(orgId) {
      this.loaderStart(this.organization.loader);
      this.$router.push("/organization/" + orgId);
      await this.getOrganizationById(orgId);
      setTimeout(
        this.loaderFinish,
        this.config.loadersDelay,
        this.organization.loader
      );
    },
    async getOrganizationById(organizationId) {
      axios
        .get(this.config.url + "organizations/" + organizationId)
        .then((response) => {
          this.parseOrganization(response.data);
          console.groupCollapsed("Юридическое лицо с id=" + organizationId);
          console.log(response.data);
          console.groupEnd();
        });
      this.getOrgRequirements(organizationId);
    },
    async parseOrganization(
      organizationData,
      targetParsedData = "organization"
    ) {
      this[targetParsedData].id = organizationData.data.id;
      // Раздел "Общая информация"
      // Блок "Информация о юридическом лице"
      this[targetParsedData].profile.forms[0].form.fields[0].value =
        organizationData.data.name;
      this[targetParsedData].profile.forms[0].form.fields[1].value =
        organizationData.data.shortName;
      this[targetParsedData].profile.forms[0].form.fields[2].value =
        organizationData.data.type;
      this[targetParsedData].profile.forms[0].form.fields[3].value =
        organizationData.data.form;
      // Блок "Контактная информация"
      this[targetParsedData].profile.forms[1].form.fields[0].value =
        organizationData.data.phone;
      this[targetParsedData].profile.forms[1].form.fields[1].value =
        organizationData.data.website;
      this[targetParsedData].profile.forms[1].form.fields[2].value =
        organizationData.data.email;
      this[targetParsedData].profile.forms[1].form.fields[3].value =
        organizationData.data.addressJur;
      this[targetParsedData].profile.forms[1].form.fields[4].value =
        organizationData.data.addressReal;
      // Блок "Учредитель"
      // !!!В ответе тсутствует поле "Тип учредителя"
      // this.organization.profile.forms[2].form.fields[0].value =
      //   organizationData.data.name;
      this[targetParsedData].profile.forms[2].form.fields[1].value =
        organizationData.data.founderBody;
      this[targetParsedData].profile.forms[2].form.fields[2].value =
        organizationData.data.principalName;
      // Блок "Реквизиты"
      this[targetParsedData].profile.forms[3].form.fields[0].value =
        organizationData.data.inn;
      this[targetParsedData].profile.forms[3].form.fields[1].value =
        organizationData.data.kpp;
      this[targetParsedData].profile.forms[3].form.fields[2].value =
        organizationData.data.ogrn;
      this[targetParsedData].profile.forms[3].form.fields[3].value =
        organizationData.data.oktmo;
      // Блок "Платежные реквизиты"
      this[targetParsedData].profile.forms[4].form.fields[0].value =
        organizationData.data.bankName;
      this[targetParsedData].profile.forms[4].form.fields[1].value =
        organizationData.data.bankInn;
      this[targetParsedData].profile.forms[4].form.fields[2].value =
        organizationData.data.bankKpp;
      this[targetParsedData].profile.forms[4].form.fields[3].value =
        organizationData.data.bankBik;
      this[targetParsedData].profile.forms[4].form.fields[4].value =
        organizationData.data.accountPayment;
      this[targetParsedData].profile.forms[4].form.fields[5].value =
        organizationData.data.accountCorresponding;

      // Раздел "Виды деятельности"
      if (organizationData.organizationInfos.length > 0) {
        let activities = [];
        organizationData.organizationInfos.forEach(function (item) {
          let activity = {};
          activity.id = item.id;
          activity.title = item.name;
          activity.description = item.description;
          activities.push(activity);
        });
        this[targetParsedData].activities = activities;
      } else {
        this[targetParsedData].activities = [];
      }

      // Раздел "Виды спорта"
      if (organizationData.organizationSports.length > 0) {
        let sports = [];
        organizationData.organizationSports.forEach(function (item) {
          let sport = {};
          sport.id = item.id;
          sport.name = item.sport;
          sport.discipline = item.discipline;
          sports.push(sport);
        });
        this[targetParsedData].sports = sports;
      } else {
        this[targetParsedData].sports = [];
      }

      // Раздел "Документы"
      if (organizationData.organizationFiles.length > 0) {
        let documents = [];
        let files = [];
        for (let i = 0; i < organizationData.organizationFiles.length; i++) {
          let document = {};
          document.id = organizationData.organizationFiles[i].id;
          document.name = organizationData.organizationFiles[i].fileName;
          document.title = organizationData.organizationFiles[i].fileTitle;
          document.fileEntryId =
            organizationData.organizationFiles[i].fileEntryId;
          let request =
            this.config.url +
            "files/" +
            organizationData.organizationFiles[i].fileEntryId;
          files.push(axios.get(request));
          documents.push(document);
        }
        await Promise.all(files).then(function (results) {
          for (let i = 0; i < results.length; i++) {
            documents[i].file = results[i].data;
          }
        });
        this[targetParsedData].documents = documents;
      } else {
        this[targetParsedData].documents = [];
      }
    },

    // Паспорт юридического лица
    async getOrganizationPassportById() {
      await axios.get(this.config.url + "lk/organization/").then((response) => {
        console.groupCollapsed(
          "Паспорт юридического лица " + response.data.data.name
        );
        console.log(response.data);
        console.groupEnd();
        this.organizationPassportDB = response.data;
        this.parseOrganizationPassport(response.data, "organizationPassport");
      });
      await this.getTrainingAppsForOrgPassport();
      await this.getOrgRequirements(
        this.organizationPassportDB.data.id,
        "organizationPassport"
      );
    },
    async parseOrganizationPassport(
      organizationData,
      targetParsedData = "organization"
    ) {
      // Раздел "Общая информация"
      // Блок "Информация о юридическом лице"
      this[targetParsedData].profile.forms[0].form.fields[0].value =
        organizationData.data.name;
      this[targetParsedData].profile.forms[0].form.fields[1].value =
        organizationData.data.shortName;
      this[targetParsedData].profile.forms[0].form.fields[2].value =
        organizationData.data.type;
      this[targetParsedData].profile.forms[0].form.fields[3].value =
        organizationData.data.form;
      // Блок "Контактная информация"
      this[targetParsedData].profile.forms[1].form.fields[0].value =
        organizationData.data.phone;
      this[targetParsedData].profile.forms[1].form.fields[1].value =
        organizationData.data.website;
      this[targetParsedData].profile.forms[1].form.fields[2].value =
        organizationData.data.email;
      this[targetParsedData].profile.forms[1].form.fields[3].value =
        organizationData.data.addressJur;
      this[targetParsedData].profile.forms[1].form.fields[4].value =
        organizationData.data.addressReal;
      // Блок "Учредитель"
      // !!!В ответе тсутствует поле "Тип учредителя"
      // this.organization.profile.forms[2].form.fields[0].value =
      //   organizationData.data.name;
      this[targetParsedData].profile.forms[2].form.fields[1].value =
        organizationData.data.founderBody;
      this[targetParsedData].profile.forms[2].form.fields[2].value =
        organizationData.data.principalName;
      // Блок "Реквизиты"
      this[targetParsedData].profile.forms[3].form.fields[0].value =
        organizationData.data.inn;
      this[targetParsedData].profile.forms[3].form.fields[1].value =
        organizationData.data.kpp;
      this[targetParsedData].profile.forms[3].form.fields[2].value =
        organizationData.data.ogrn;
      this[targetParsedData].profile.forms[3].form.fields[3].value =
        organizationData.data.oktmo;
      // Блок "Платежные реквизиты"
      this[targetParsedData].profile.forms[4].form.fields[0].value =
        organizationData.data.bankName;
      this[targetParsedData].profile.forms[4].form.fields[1].value =
        organizationData.data.bankInn;
      this[targetParsedData].profile.forms[4].form.fields[2].value =
        organizationData.data.bankKpp;
      this[targetParsedData].profile.forms[4].form.fields[3].value =
        organizationData.data.bankBik;
      this[targetParsedData].profile.forms[4].form.fields[4].value =
        organizationData.data.accountPayment;
      this[targetParsedData].profile.forms[4].form.fields[5].value =
        organizationData.data.accountCorresponding;

      // Раздел "Виды деятельности"
      if (organizationData.organizationInfos.length > 0) {
        let activities = [];
        organizationData.organizationInfos.forEach(function (item) {
          let activity = {};
          activity.id = item.id;
          activity.title = item.name;
          activity.description = item.description;
          activities.push(activity);
        });
        this[targetParsedData].activities = activities;
      } else {
        this[targetParsedData].activities = [];
      }

      // Раздел "Сотрудники"
      if (organizationData.organizationPersons.length > 0) {
        let workers = [];
        for (let i = 0; i < organizationData.organizationPersons.length; i++) {
          // organizationData.organizationPersons.forEach(function (item) {
          let worker = {};
          worker.id = organizationData.organizationPersons[i].id;
          worker.fullName = organizationData.organizationPersons[i].personName;
          worker.position = organizationData.organizationPersons[i].position;
          if (organizationData.organizationPersons[i].isAdmin === "true") {
            worker.isAdmin = true;
          } else {
            worker.isAdmin = false;
          }
          worker.photo = {};
          if (+organizationData.organizationPersons[i].avatarFileEntryId > 0) {
            worker.avatarFile = await this.getFileByEntryId(
              organizationData.organizationPersons[i].avatarFileEntryId
            );
          }
          if (+organizationData.organizationPersons[i].fileEntryId > 0) {
            worker.file = await this.getFileByEntryId(
              organizationData.organizationPersons[i].fileEntryId
            );
          }
          workers.push(worker);
        }
        this[targetParsedData].workers = workers;
      } else {
        this[targetParsedData].workers = [];
      }

      // Раздел "Виды спорта"
      if (organizationData.organizationSports.length > 0) {
        let sports = [];
        organizationData.organizationSports.forEach(function (item) {
          let sport = {};
          sport.id = item.id;
          sport.name = item.sport;
          sport.discipline = item.discipline;
          sports.push(sport);
        });
        this[targetParsedData].sports = sports;
      } else {
        this[targetParsedData].sports = [];
      }

      // Раздел "Документы"
      if (organizationData.organizationFiles.length > 0) {
        let documents = [];
        let files = [];
        for (let i = 0; i < organizationData.organizationFiles.length; i++) {
          let document = {};
          document.id = organizationData.organizationFiles[i].id;
          document.name = organizationData.organizationFiles[i].fileName;
          document.title = organizationData.organizationFiles[i].fileTitle;
          document.fileEntryId =
            organizationData.organizationFiles[i].fileEntryId;
          let request =
            this.config.url +
            "files/" +
            organizationData.organizationFiles[i].fileEntryId;
          files.push(axios.get(request));
          documents.push(document);
        }
        await Promise.all(files).then(function (results) {
          for (let i = 0; i < results.length; i++) {
            documents[i].file = results[i].data;
          }
        });
        this[targetParsedData].documents = documents;
      } else {
        this[targetParsedData].documents = [];
      }

      // Раздел "Заявки"
      if (organizationData.organizationApps.length > 0) {
        let appRows = [];
        for (let i = 0; i < organizationData.organizationApps.length; i++) {
          let row = [];
          row.push(organizationData.organizationApps[i].id);
          row.push(organizationData.organizationApps[i].id);
          row.push(organizationData.organizationApps[i].author);
          row.push(organizationData.organizationApps[i].role);
          row.push(organizationData.organizationApps[i].date.substring(0, 10));
          row.push(organizationData.data.shortName);
          row.push({
            type: "component",
            subtype: "app-decision",
            data: organizationData.organizationApps[i],
          });
          appRows.push(row);
        }
        this[targetParsedData].apps.table.rowsList = appRows.sort(
          this.compareId
        );
      } else {
        this[targetParsedData].apps.table.rowsList = [];
      }

      // Раздел "Заявки на спортподготовку"
      // if (organizationData.organizationSportsTrainingApps.length > 0) {
      //   let appRows = [];
      //   for (
      //     let i = 0;
      //     i < organizationData.organizationSportsTrainingApps.length;
      //     i++
      //   ) {
      //     let row = [];
      //     row.push(organizationData.organizationSportsTrainingApps[i].id);
      //     row.push(organizationData.organizationSportsTrainingApps[i].id);
      //     row.push(organizationData.organizationSportsTrainingApps[i].author);
      //     row.push(organizationData.organizationSportsTrainingApps[i].child);
      //     row.push(
      //       organizationData.organizationSportsTrainingApps[i].date.substring(
      //         0,
      //         10
      //       )
      //     );
      //     // Нужно добавить спорт, когда он будет приходить с ЗК
      //     row.push(
      //       organizationData.organizationSportsTrainingApps[i].sportType
      //     );
      //     row.push({
      //       type: "component",
      //       subtype: "app-decision-training",
      //       data: organizationData.organizationSportsTrainingApps[i],
      //     });
      //     appRows.push(row);
      //   }
      //   this[targetParsedData].trainings.table.rowsList = appRows.sort(
      //     this.compareId
      //   );
      // } else {
      //   this[targetParsedData].trainings.table.rowsList = [];
      // }
    },
    async getOrgRequirements(orgId, targetParsedData = "organization") {
      await axios
        .get(this.config.url + "organizations/" + orgId + "/requirements")
        .then((response) => {
          console.groupCollapsed(
            "Требования спортивной организации с id=" + orgId
          );
          console.log(response.data);
          console.groupEnd();
          let targetData;
          if (typeof targetParsedData === "object") {
            targetData = targetParsedData;
          } else if (typeof targetParsedData === "string") {
            targetData = this[targetParsedData];
          }
          if (response.data.organizationRequirements[0]) {
            targetData.requirements.description =
              response.data.organizationRequirements[0].requirement;
          } else {
            targetData.requirements.description = "";
          }
        });
      await axios
        .get(this.config.url + "organizations/" + orgId + "/requirements/files")
        .then(async (response) => {
          console.groupCollapsed(
            "Файлы требований спортивной организации с id=" + orgId
          );
          console.log(response.data);
          console.groupEnd();
          let targetData;
          if (typeof targetParsedData === "object") {
            targetData = targetParsedData;
          } else if (typeof targetParsedData === "string") {
            targetData = this[targetParsedData];
          }

          if (response.data.organizationFiles.length > 0) {
            let requirementsFiles = [];
            let fileRequestsArray = [];
            for (let i = 0; i < response.data.organizationFiles.length; i++) {
              let request =
                this.config.url +
                "files/" +
                response.data.organizationFiles[i].fileEntryId;
              fileRequestsArray.push(axios.get(request));
              requirementsFiles.push(response.data.organizationFiles[i]);
            }
            await Promise.all(fileRequestsArray).then(function (results) {
              for (let i = 0; i < results.length; i++) {
                requirementsFiles[i].file = results[i].data;
              }
            });
            console.log("требования");
            console.log(requirementsFiles);

            targetData.requirements.files = requirementsFiles;
          } else {
            targetData.requirements.files = [];
          }
        });
    },
    async getTrainingAppsForOrgPassport() {
      await axios.get(this.trainingAppsForOrgPassportQuery).then((response) => {
        console.groupCollapsed("Заявки юридического лица на спортподготовку");
        console.log(response.data);
        console.groupEnd();
        // this.organizationsDB = response.data.items;
        this.organizationPassport.trainings.table.pagination.itemsTotal =
          response.data.totalCount;
        this.parseTrainingAppsForOrgPassport(response.data.items);
      });
    },
    parseTrainingAppsForOrgPassport(trainingApps) {
      console.log("Парсинг заявок СП");
      if (trainingApps.length > 0) {
        console.log("Заявки СП найдены");
        let appRows = [];
        for (let i = 0; i < trainingApps.length; i++) {
          let row = [];
          row.push(trainingApps[i].id);
          row.push(trainingApps[i].id);
          row.push(trainingApps[i].author);
          row.push(trainingApps[i].child);
          row.push(trainingApps[i].date.substring(0, 10));
          row.push(trainingApps[i].sportType);
          row.push({
            type: "component",
            subtype: "app-decision-training",
            data: trainingApps[i],
          });
          appRows.push(row);
          console.log("Заявка СП распарсена");
        }
        this.organizationPassport.trainings.table.rowsList = appRows;
        console.log("Паспорт после парсинга заявок СП");
        console.log(this.organizationPassport);
      } else {
        console.log("Заявки СП не найдены");
        this.organizationPassport.trainings.table.rowsList = [];
      }
    },
    async getSportsForOrgPassportFilter() {
      await axios
        .get(
          this.config.url +
            "lk/person/trainings/apps/organizations/" +
            this.organizationPassportDB.data.id +
            "/sports"
        )
        .then((response) => {
          console.groupCollapsed(
            "Список видов спорта для паспорта организации с id=" +
              this.organizationPassportDB.data.id
          );
          console.log(response.data);
          console.groupEnd();
          this.parseSportsForOrgPassportFilter(
            response.data.dictSportTypeDatas
          );
        });
    },
    parseSportsForOrgPassportFilter(sportsArray) {
      let itemsList = [];
      sportsArray.forEach(function (sport) {
        let item = {};
        item.id = sport.id;
        item.value = sport.name;
        item.label = sport.name;
        itemsList.push(item);
      });
      this.organizationPassport.trainings.table.filters[1] = itemsList;
    },
    // Справочники юрлица, необходимые при загрузке приложения, и присвоение их значений полям форм ЮЛ
    async getOrgSports() {
      await axios
        .get(
          this.config.url +
            "lk/person/trainings/apps/organizations/" +
            this.organizationPassportDB.data.id +
            "/sports"
        )
        .then((response) => {
          console.groupCollapsed(
            "Список видов спорта организации с id=" +
              this.organizationPassportDB.data.id
          );
          console.log(response.data);
          console.groupEnd();
          this.parseOrgSport(response.data.dictSportTypeDatas);
        });
    },
    parseOrgSport(sportsArray) {
      let itemsList = [];
      sportsArray.forEach(function (sport) {
        let item = {};
        item.id = sport.id;
        item.value = sport.id;
        item.label = sport.name;
        itemsList.push(item);
      });
      this.dictionaries.orgSports = itemsList;
      this.modals[10].form.fields[0].itemsList = itemsList;
    },
    setTrainingStatuses() {
      this.organizationPassport.trainings.table.filters[2].itemsList =
        this.dictionaries.trainingStatuses;
      console.groupCollapsed("Справочник статусов заявок на спортподготовку");
      console.log(this.dictionaries.eventStatuses);
      console.groupEnd();
    },
    // Справочники, необходимые ЮЛ при заполнении форм
    async getDisciplinesForTraining(appId) {
      await axios
        .get(
          this.config.url + "lk/person/trainings/apps/" + appId + "/disciplines"
        )
        .then((response) => {
          console.groupCollapsed("Список дисциплин для заявки с id=" + appId);
          console.log(response.data);
          console.groupEnd();
          this.parseDisciplinesForTraining(
            response.data.dictSportDisciplineDatas
          );
        });
    },
    parseDisciplinesForTraining(disciplinesArray) {
      if (disciplinesArray.length > 0) {
        let itemsList = [];
        disciplinesArray.forEach(function (disc) {
          let item = {};
          item.id = disc.id;
          item.value = disc.id;
          item.label = disc.name;
          itemsList.push(item);
        });
        this.modals[10].form.fields[1].itemsList = itemsList;
        this.modals[10].form.fields[1].values = [];
      } else {
        this.modals[10].form.fields[1].itemsList = [];
        this.modals[10].form.fields[1].values = [];
      }
    },

    // Физические лица
    getPersonById(personId = this.user.personId) {
      axios.get(this.config.url + "persons/" + personId).then((response) => {
        this.personDB = response.data;
        this.parsePerson(response.data);
        console.groupCollapsed("Физическое лицо с id=" + personId);
        console.log(response.data);
        console.groupEnd();
      });
    },
    childRead(childId) {
      console.log(childId);
      this.config.childForUpdateId = childId;
      this.setChildforUpdate(childId);
      this.$router.push("/childupdate/" + childId);
    },
    setChildforUpdate(childId) {
      let childUdated = this.personAccountDB.childs.find(function (item) {
        if (item.childData.id === childId) return true;
      });

      this.childUpdateData.id = childUdated.childData.id;
      // ФИО
      this.childUpdateData[0].form.fields[0].value =
        childUdated.childData.familyName;
      this.childUpdateData[0].form.fields[1].value =
        childUdated.childData.firstName;
      this.childUpdateData[0].form.fields[2].value =
        childUdated.childData.middleName;
      // Пол и дата рождения
      this.childUpdateData[1].form.fields[0].values[0] =
        childUdated.childData.gender === "male" ? 1 : 2;
      this.childUpdateData[1].form.fields[1].value = this.convertDateDotToInput(
        childUdated.childData.birthDate
      );
      this.childUpdateData[1].form.fields[2].value =
        childUdated.childData.birthPlace;
      // Гражданство
      this.childUpdateData[2].form.fields[0].value =
        childUdated.childData.citizenship;
      this.childUpdateData[2].form.fields[1].value =
        childUdated.childData.subjectRF;
      this.childUpdateData[2].form.fields[2].value = childUdated.childData.city;
      // Удостоверение личности
      // !!! тип документа, серия и номер не приходят
      this.childUpdateData[3].form.fields[0].values[0] = "101";
      this.childUpdateData[3].form.fields[1].value =
        childUdated.childData.docSeries;
      this.childUpdateData[3].form.fields[2].value =
        childUdated.childData.docNumber;
      this.childUpdateData[3].form.fields[3].value = this.convertDateDotToInput(
        childUdated.childData.docIssueDate
      );
      this.childUpdateData[3].form.fields[4].value =
        childUdated.childData.docIssuer;
      this.childUpdateData[3].form.fields[5].value =
        childUdated.childData.docCode;
      // Сведения о регистрации
      this.childUpdateData[4].form.fields[0].value =
        childUdated.childData.address;
      this.childUpdateData[4].form.fields[1].value =
        childUdated.childData.postal;
      // СНИЛС
      this.childUpdateData[5].form.fields[0].value =
        childUdated.childData.snils;
      // Контактная информация
      this.childUpdateData[6].form.fields[0].value =
        childUdated.childData.phone;
      this.childUpdateData[6].form.fields[1].value =
        childUdated.childData.parentPhone;
      this.childUpdateData[6].form.fields[2].value =
        childUdated.childData.email;
      this.childUpdateData[6].form.fields[3].value =
        childUdated.childData.emailNotification === "Да";
      console.log(childUdated);
    },

    async getFileByEntryId(entryId) {
      let fileData = await axios.get(this.config.url + "files/" + entryId);
      console.log("Фото стадиона получено");
      return fileData.data;
    },

    // Сайдбар
    openSidebar() {
      this.offcanvas.open = true;
      this.toggleBodyScroll();
    },
    closeSidebar() {
      if (this.offcanvas.support.open) {
        this.closeSupport();
      }
      this.offcanvas.open = false;
      this.toggleBodyScroll();
    },
    toggleSidebar() {
      if (this.offcanvas.open) {
        this.closeSidebar();
      } else {
        this.openSidebar();
      }
    },

    // Блок техподдержки в сайдбаре
    openSupport() {
      if (!this.offcanvas.open) {
        this.openSidebar();
      }
      this.offcanvas.support.open = true;
    },
    closeSupport() {
      this.offcanvas.support.open = false;
      this.toggleBodyScroll();
    },
    toggleSupport() {
      if (this.offcanvas.support.open) {
        this.closeSupport();
      } else {
        this.openSupport();
      }
    },

    // Блокировка прокрутки body
    blockBodyScroll() {
      document.documentElement.style.setProperty("overflow", "hidden");
    },
    unblockBodyScroll() {
      document.documentElement.style.removeProperty("overflow");
    },
    toggleBodyScroll() {
      if (document.documentElement.clientWidth < 576 && this.offcanvas.open) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    },

    // Модальные окна
    showModal(modalContentId) {
      console.log(modalContentId);
      let modalContent = this.modals.find((item) => item.id == modalContentId);
      this.config.modal.id = modalContent.id;
      this.config.modal.title = modalContent.title;
      if (this.config.modal.id === 9) {
        $("#appModal").modal({
          keyboard: false,
        });
      } else {
        $("#appModal").modal({
          keyboard: true,
        });
      }
      $("#appModal").modal("show");
    },
    hideModal() {
      $("#appModal").modal("hide");
    },

    // Изменения формы типовое
    changeForm(fieldsArray, newFormData) {
      console.log("Изменяется форма");
      console.log(fieldsArray);
      console.log(newFormData);
      let formField = fieldsArray.find(function (item) {
        if (item.id === newFormData.id) return true;
      });
      if (formField.type === "select") {
        formField.values = newFormData.values;
      } else if (formField.type === "range") {
        formField.itemsList[newFormData.index].value = newFormData.value;
      } else if (formField.type === "input" && formField.subtype === "file") {
        formField.file = newFormData.value;
      } else {
        formField.value = newFormData.value;
      }
      console.log("Измененное поле");
      console.log(formField);
    },
    changeFormWithValidate(formData, newFormData) {
      console.log("Изменяется форма");
      console.log(formData);
      console.log(newFormData);
      let formField = formData.fields.find(function (item) {
        if (item.id === newFormData.id) return true;
      });
      if (formField.type === "select") {
        formField.values = newFormData.values;
        if (formField.required) {
          this.validateForm(formData);
        }
      } else if (formField.type === "range") {
        formField.itemsList[newFormData.index].value = newFormData.value;
        if (formField.required) {
          this.validateForm(formData);
        }
      } else if (formField.type === "input" && formField.subtype === "file") {
        formField.file = newFormData.value;
        if (formField.required) {
          this.validateForm(formData);
        }
      } else {
        formField.value = newFormData.value;
        if (formField.required) {
          this.validateForm(formData);
        }
      }
      console.log("Измененное поле");
      console.log(formField);
    },
    validateForm(formData) {
      let fieldsValidity = [];
      formData.fields.forEach(function (field) {
        if (field.type === "select") {
          if (field.values.length === 0 && field.required) {
            fieldsValidity.push(false);
          } else {
            fieldsValidity.push(true);
          }
        } else if (field.type === "input" && field.subtype === "file") {
          if (!field.file.base64 && field.required) {
            fieldsValidity.push(false);
          } else {
            fieldsValidity.push(true);
          }
        } else {
          if (!field.value && field.required) {
            fieldsValidity.push(false);
          } else {
            fieldsValidity.push(true);
          }
        }
      });
      console.log(fieldsValidity);
      console.log(formData.validity);
      console.log(fieldsValidity.includes(false));
      if (formData.validity && fieldsValidity.includes(false)) {
        formData.validity = false;
      }
      if (!formData.validity && !fieldsValidity.includes(false)) {
        console.log("форма валидна");
        console.log(formData.validity);
        formData.validity = true;
        console.log(formData.validity);
      }
    },

    // Изменения формы добавления нового документа в ЛК ФЛ выполняется типовым методом changeForm
    // Изменения формы добавления дополнительного образования в ЛК ФЛ выполняется типовым методом changeForm
    // Изменения формы заявки в организацию в ЛК ФЛ выполняется типовым методом changeFormWithValidate
    // Изменения формы заявки на участие в мероприятии в ЛК ФЛ выполняется типовым методом changeFormWithValidate

    // Изменения формы заявки на спортивную подготовку
    async changeAddAppTraining(formData, newFormData) {
      // Поиск поля формы
      let formField = formData.fields.find(function (item) {
        if (item.id === newFormData.id) return true;
      });
      if (formField.type === "select") {
        formField.values = newFormData.values;
        if (formField.required) {
          this.validateForm(formData);
        }
        if (formField.id === "add-person-app-training-sport") {
          await this.getOrgsForSportTraining(newFormData.values[0]);
          this.modals[4].formPart1.fields[2].disabled = false;
          this.modals[4].formPart2.fields[1].disabled = false;
          this.modals[4].formPart2.fields[2].disabled = false;
        }
        if (formField.id === "add-person-app-training-type") {
          console.log(newFormData);
          if (newFormData.values[0] === 1) {
            // Выбрано личное обращение
            this.modals[4].formPart2.fields[0].label = "ФИО заявителя";
            this.modals[4].formPart2.fields[0].disabled = true;
            let selectItem = {};
            selectItem.id = this.personAccountDB.data.id;
            selectItem.value = this.personAccountDB.data.id;
            selectItem.label = this.personAccountDB.data.fullName;
            // this.modals[4].form.fields[3].disabled = false;
            this.modals[4].formPart2.fields[0].itemsList = [selectItem];
            this.modals[4].formPart2.fields[0].values = [
              this.personAccountDB.data.id,
            ];
          } else {
            // Выбрано обращение родителя
            this.modals[4].formPart2.fields[0].label = "ФИО ребенка";
            let itemsList = [];
            if (this.personAccountDB.childs.length > 0) {
              this.personAccountDB.childs.forEach(function (child) {
                let item = {};
                item.id = child.childData.id;
                item.value = child.childData.id;
                item.label = child.childData.fullName;
                itemsList.push(item);
              });
              this.modals[4].formPart2.fields[0].itemsList = itemsList;
            } else {
              this.modals[4].formPart2.fields[0].itemsList = [];
            }
            this.modals[4].formPart2.fields[0].values = [];
            this.modals[4].formPart2.fields[0].disabled = false;
          }
        }
      } else if (formField.type === "range") {
        formField.itemsList[newFormData.index].value = newFormData.value;
        if (formField.required) {
          this.validateForm(formData);
        }
      } else if (formField.type === "input" && formField.subtype === "file") {
        formField.itemsList[newFormData.index].value = newFormData.value;
        if (formField.required) {
          this.validateForm(formData);
        }
      } else {
        formField.value = newFormData.value;
        if (formField.required) {
          this.validateForm(formData);
        }
      }
    },
    async readRequirements(orgId) {
      console.log("Читаю требования для организации с id=" + orgId);
      let targetModal = this.modals[5];
      await this.getOrgRequirements(orgId, targetModal);
      this.config.modal.id = 6;
      this.config.modal.title = "Требования к спортсменам";
    },
    returnToTrainingAppModal() {
      this.config.modal.id = 5;
      this.config.modal.title = "Создание заявки на спортивную подготовку";
    },
    // Изменения формы заявки на массовый спорт
    async changeAddAppPublic(formData, newFormData) {
      // Поиск поля формы
      let formField = formData.fields.find(function (item) {
        if (item.id === newFormData.id) return true;
      });
      if (formField.type === "select") {
        formField.values = newFormData.values;
        if (formField.required) {
          this.validateForm(formData);
        }
        if (formField.id === "add-person-app-public-object") {
          await this.getServicesForStadiumPublic(newFormData.values[0]);
        }
      } else if (formField.type === "range") {
        formField.itemsList[newFormData.index].value = newFormData.value;
        if (formField.required) {
          this.validateForm(formData);
        }
      } else if (formField.type === "input" && formField.subtype === "file") {
        formField.file = newFormData.value;
        if (formField.required) {
          this.validateForm(formData);
        }
      } else {
        formField.value = newFormData.value;
        if (formField.required) {
          this.validateForm(formData);
        }
      }
    },

    // Отправка формы добавления нового документа в ЛК ФЛ
    sendAddPersonDoc(userId = this.user.oid) {
      const request = {
        docType: this.modals[0].form.fields[0].values[0],
        series: this.modals[0].form.fields[1].value,
        number: this.modals[0].form.fields[2].value,
        issueDate: this.convertDateInputToDot(
          this.modals[0].form.fields[3].value
        ),
        issuer: this.modals[0].form.fields[4].value,
        code: this.modals[0].form.fields[5].value,
        file: this.modals[0].form.fields[6].file.base64,
        fileName: this.modals[0].form.fields[6].file.name,
      };
      console.log(request);
      axios
        .post(
          this.config.url +
            "persons/createDUL/" +
            userId +
            "?personId=" +
            this.user.id,
          request
        )
        .then(() => {
          console.log("Добавлен документ в ЛК ФЛ");
          this.hideModal();
          this.getPersonAccountById();
          this.resetForm(this.modals[0].form);
        });
    },
    // Отправка формы добавления нового образования в ЛК ФЛ
    sendAddPersonEducation() {
      const request = {
        level: this.modals[1].form.fields[0].values[0],
        name: this.modals[1].form.fields[1].value,
        specialization: this.modals[1].form.fields[2].value,
        yearStart: this.modals[1].form.fields[3].value,
        year: this.modals[1].form.fields[4].value,
        series: this.modals[1].form.fields[5].value,
        number: this.modals[1].form.fields[6].value,
        issueDate: this.convertDateInputToDot(
          this.modals[1].form.fields[7].value
        ),
        file: this.modals[1].form.fields[8].file.base64
          ? this.modals[1].form.fields[8].file.base64
          : "",
        fileName: this.modals[1].form.fields[8].file.type
          ? this.modals[1].form.fields[8].file.type
          : "",
      };
      console.log(request);
      axios.post(this.config.url + "lk/person/educations", request).then(() => {
        console.log("Добавлен документ в ЛК ФЛ");
        this.hideModal();
        this.getPersonAccountById();
        this.resetForm(this.modals[1].form);
        this.modals[1].form.validity = false;
      });
    },
    // Отправка формы заявки в организацию
    sendAppOrg() {
      const request = {
        orgId: this.modals[2].formPart1.fields[0].values[0],
        roleId: this.modals[2].formPart2.fields[0].values[0],
        file: this.modals[2].formPart2.fields[2].file.base64,
        fileName: this.modals[2].formPart2.fields[2].file.name,
        comment: this.modals[2].formPart2.fields[1].value,
      };
      console.log(request);
      axios
        .post(this.config.url + "lk/person/organizations/apps", request)
        .then(() => {
          console.log("Добавлена заявка в организацию в ЛК ФЛ");
          this.hideModal();
          this.getPersonAccountById();
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.config.modal.errorText =
              "Ранее вы уже отправляли заявление в организацию на данную роль. Ваше заявление ожидает рассмотрения";
            this.config.modal.id = 13;
          }
        })
        .then(() => {
          this.resetForm(this.modals[2].formPart1);
          this.resetForm(this.modals[2].formPart2);
          this.resetForm(this.modals[2].formPart3);
          this.modals[2].formPart1.validity = false;
          this.modals[2].formPart2.validity = false;
          this.modals[2].formPart3.validity = false;
        });
    },
    // Отправка формы заявки на участие в мероприятии
    sendAppEvent() {
      const request = {
        eventId: this.modals[3].formPart1.fields[0].values[0],
        typeId: this.modals[3].formPart2.fields[0].values[0],
        comment: this.modals[3].formPart2.fields[1].value,
      };
      console.log(request);
      axios
        .post(this.config.url + "lk/person/events/apps", request)
        .then(() => {
          console.log("Добавлена заявка на участие в мероприятии в ЛК ФЛ");
          this.hideModal();
          this.getPersonAccountById();
          this.resetForm(this.modals[3].form);
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.config.modal.errorText =
              "Ранее вы уже отправляли заявление на участие в мероприятии с данной ролью. Ваше заявление ожидает рассмотрения";
            this.config.modal.id = 13;
          }
        })
        .then(() => {
          this.resetForm(this.modals[3].formPart1);
          this.resetForm(this.modals[3].formPart2);
          this.resetForm(this.modals[3].formPart3);
          this.modals[3].formPart1.validity = false;
          this.modals[3].formPart2.validity = false;
          this.modals[3].formPart3.validity = false;
        });
    },
    // Отправка формы заявки на спортивную подготовку
    // sendAppTraining(userId = this.user.oid) {
    //   const request = {
    //     orgId: this.modals[4].form.fields[0].values[0],
    //     roleId: this.modals[4].form.fields[1].values[0],
    //     sportTypeId: this.modals[4].form.fields[2].values[0],
    //     childId: this.modals[4].form.fields[3].values[0],
    //     comment: this.modals[4].form.fields[4].value,
    //     city: "",
    //   };
    //   console.log(userId);
    //   console.log(request);
    //   axios
    //     .post(this.config.url + "persons/training/app?oid=" + userId, request)
    //     .then(() => {
    //       console.log("Добавлена заявка на спортподготовку в ЛК ФЛ");
    //       this.hideModal();
    //       this.getPersonAccountById();
    //       this.resetForm(this.modals[4].form);
    //     });
    // },
    sendAppTraining() {
      const request = {
        sportTypeId: this.modals[4].formPart1.fields[0].values[0],
        roleId: this.modals[4].formPart1.fields[1].values[0],
        orgId: this.modals[4].formPart1.fields[2].values[0],
        childId:
          this.modals[4].formPart1.fields[1].values[0] === 2
            ? this.modals[4].formPart2.fields[0].values[0]
            : "",
        city: this.modals[4].formPart2.fields[1].value,
        comment: this.modals[4].formPart2.fields[2].value,
      };
      console.log(request);
      axios
        .post(this.config.url + "lk/person/trainings/apps", request)
        .then(() => {
          console.log("Добавлена заявка на спортподготовку в ЛК ФЛ");
          this.hideModal();
          this.getPersonAccountById();
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.config.modal.errorText =
              "Ранее вы уже отправляли заявление на спортивную подготовку в выбранную организацию по выбранному виду спорта для данного человека. Ваше заявление ожидает рассмотрения";
            this.config.modal.id = 13;
          }
        })
        .then(() => {
          this.resetForm(this.modals[4].formPart1);
          this.resetForm(this.modals[4].formPart2);
          this.resetForm(this.modals[4].formPart3);
          this.modals[4].formPart1.validity = false;
          this.modals[4].formPart2.validity = false;
          this.modals[4].formPart3.validity = false;
        });
    },
    // Отправка формы заявки на массовый спорт
    sendAppPublic() {
      const request = {
        serviceId: this.modals[6].formPart2.fields[0].values[0],
        day: this.convertDateInputToDot(
          this.modals[6].formPart2.fields[1].value.substr(0, 10)
        ),
        time: this.modals[6].formPart2.fields[1].value.substr(11, 5),
        comment: this.modals[6].formPart2.fields[2].value,
      };
      console.log(request);
      axios
        .post(this.config.url + "lk/person/services/apps", request)
        .then(() => {
          console.log("Добавлена заявка на массовый спорт в ЛК ФЛ");
          this.hideModal();
          this.getPersonAccountById();
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.config.modal.errorText =
              "Ранее вы уже отправляли заявление в спортивный объект на выбранный тип услуги. Ваше заявление ожидает рассмотрения";
            this.config.modal.id = 13;
          }
        })
        .then(() => {
          this.resetForm(this.modals[6].formPart1);
          this.resetForm(this.modals[6].formPart2);
          this.modals[6].formPart1.validity = false;
          this.modals[6].formPart2.validity = false;
        });
    },

    // Отправка формы добавления и редактирования ребенка
    sendNewChild() {
      let genderBySting = "";
      if (this.childCreateData[1].form.fields[0].values[0] === 1) {
        genderBySting = "male";
      } else if (this.childCreateData[1].form.fields[0].values[0] === 2) {
        genderBySting = "female";
      }
      const request = {
        familyName: this.childCreateData[0].form.fields[0].value,
        firstName: this.childCreateData[0].form.fields[1].value,
        patronymic: this.childCreateData[0].form.fields[2].value,
        // gender: this.childCreateData[1].form.fields[0].values[0],
        gender: genderBySting,
        birthDate: this.convertDateInputToDot(
          this.childCreateData[1].form.fields[1].value
        ),
        birthPlace: this.childCreateData[1].form.fields[2].value,
        citizenship: this.childCreateData[2].form.fields[0].value,
        subjectRF: this.childCreateData[2].form.fields[1].value,
        city: this.childCreateData[2].form.fields[2].value,
        docType: this.childCreateData[3].form.fields[0].values[0],
        docSeries: this.childCreateData[3].form.fields[1].value,
        docNumber: this.childCreateData[3].form.fields[2].value,
        docIssueDate: this.convertDateInputToDot(
          this.childCreateData[3].form.fields[3].value
        ),
        docIssuer: this.childCreateData[3].form.fields[4].value,
        docCode: this.childCreateData[3].form.fields[5].value,
        actNumber: this.childCreateData[3].form.fields[5].value,
        address: this.childCreateData[4].form.fields[0].value,
        postal: this.childCreateData[4].form.fields[1].value,
        snils: this.childCreateData[5].form.fields[0].value,
        phone: this.childCreateData[6].form.fields[0].value,
        parentPhone: this.childCreateData[6].form.fields[1].value,
        email: this.childCreateData[6].form.fields[2].value,
        emailNotification: "" + this.childCreateData[6].form.fields[3].value,
        avatarFile: "",
        avatarFileName: "",
      };
      console.log(request);
      axios.post(this.config.url + "children/", request).then(() => {
        console.log("Добавлен ребенок в ЛК ФЛ");
        this.getPersonAccountById();
        // Обнуление форм
        for (let i = 0; i < this.childCreateData.length; i++) {
          this.resetForm(this.childCreateData[i].form);
          if (i !== 5) {
            this.childCreateData[i].form.validity = false;
          }
        }
        // Выбор вкладки Дети в ЛК ФЛ
        for (let i = 0; i < this.personAccount.tabs.length; i++) {
          if (this.personAccount.tabs[i].title === "Дети") {
            this.personAccount.tabs[i].active = true;
          } else {
            this.personAccount.tabs[i].active = false;
          }
        }
        // Переход в личный кабинет
        this.goToView("/personaccount");
      });
    },
    sendUpdateChild() {
      let genderBySting = "";
      if (this.childUpdateData[1].form.fields[0].values[0] === 1) {
        genderBySting = "male";
      } else if (this.childUpdateData[1].form.fields[0].values[0] === 2) {
        genderBySting = "female";
      }
      const request = {
        familyName: this.childUpdateData[0].form.fields[0].value,
        firstName: this.childUpdateData[0].form.fields[1].value,
        patronymic: this.childUpdateData[0].form.fields[2].value,
        // gender: this.childCreateData[1].form.fields[0].values[0],
        gender: genderBySting,
        birthDate: this.convertDateInputToDot(
          this.childUpdateData[1].form.fields[1].value
        ),
        birthPlace: this.childUpdateData[1].form.fields[2].value,
        citizenship: this.childUpdateData[2].form.fields[0].value,
        subjectRF: this.childUpdateData[2].form.fields[1].value,
        city: this.childUpdateData[2].form.fields[2].value,
        docType: this.childUpdateData[3].form.fields[0].values[0],
        docSeries: this.childUpdateData[3].form.fields[1].value,
        docNumber: this.childUpdateData[3].form.fields[2].value,
        docIssueDate: this.convertDateInputToDot(
          this.childUpdateData[3].form.fields[3].value
        ),
        docIssuer: this.childUpdateData[3].form.fields[4].value,
        docCode: this.childUpdateData[3].form.fields[5].value,
        actNumber: this.childUpdateData[3].form.fields[5].value,
        address: this.childUpdateData[4].form.fields[0].value,
        postal: this.childUpdateData[4].form.fields[1].value,
        snils: this.childUpdateData[5].form.fields[0].value,
        phone: this.childUpdateData[6].form.fields[0].value,
        parentPhone: this.childUpdateData[6].form.fields[1].value,
        email: this.childUpdateData[6].form.fields[2].value,
        emailNotification: "" + this.childUpdateData[6].form.fields[3].value,
        avatarFile: "",
        avatarFileName: "",
      };
      console.log(request);
      axios
        .put(
          this.config.url + "children/" + this.config.childForUpdateId,
          request
        )
        .then(() => {
          console.log("Изменены сведения о ребенке в ЛК ФЛ");
          this.getPersonAccountById();
          // Обнуление форм производится по команде хука destroyed страницы редактирования ребенка
          // Выбор вкладки Дети в ЛК ФЛ
          for (let i = 0; i < this.personAccount.tabs.length; i++) {
            if (this.personAccount.tabs[i].title === "Дети") {
              this.personAccount.tabs[i].active = true;
            } else {
              this.personAccount.tabs[i].active = false;
            }
          }
          // Переход в личный кабинет
          this.goToView("/personaccount");
        });
    },
    resetChildUpdateView() {
      for (let i = 0; i < this.childUpdateData.length; i++) {
        this.resetForm(this.childUpdateData[i].form);
      }
      this.childUpdateData.forEach(function (item) {
        item.form.disabled = true;
      });
      this.config.views.childUpdate.editMode = false;
      this.config.childForUpdateId = null;
      console.log(
        "Форма редактирования ребенка возвращена в исходное состояние"
      );
    },

    goToTraining() {
      if (!this.config.auth || this.config.legal) {
        this.goToView("/auth");
      } else {
        // Выбор вкладки Дети в ЛК ФЛ
        for (let i = 0; i < this.personAccount.tabs.length; i++) {
          if (this.personAccount.tabs[i].title === "Спортивная подготовка") {
            this.personAccount.tabs[i].active = true;
          } else {
            this.personAccount.tabs[i].active = false;
          }
        }
        // Переход в личный кабинет
        this.goToView("/personaccount");
      }
    },
    goToPublic() {
      if (!this.config.auth || this.config.legal) {
        this.goToView("/auth");
      } else {
        // Выбор вкладки Дети в ЛК ФЛ
        for (let i = 0; i < this.personAccount.tabs.length; i++) {
          if (this.personAccount.tabs[i].title === "Массовый спорт") {
            this.personAccount.tabs[i].active = true;
          } else {
            this.personAccount.tabs[i].active = false;
          }
        }
        // Переход в личный кабинет
        this.goToView("/personaccount");
      }
    },
    goToApps() {
      if (!this.config.auth || this.config.legal) {
        this.goToView("/auth");
      } else {
        // Выбор вкладки Дети в ЛК ФЛ
        for (let i = 0; i < this.personAccount.tabs.length; i++) {
          if (this.personAccount.tabs[i].title === "Заявки") {
            this.personAccount.tabs[i].active = true;
          } else {
            this.personAccount.tabs[i].active = false;
          }
        }
        // Переход в личный кабинет
        this.goToView("/personaccount");
      }
    },

    // Отзывы заявок физлицом
    appOrgRevocate(appId) {
      const request = {
        file: "",
        fileName: "",
      };
      axios
        .patch(
          this.config.url +
            "lk/person/organizations/apps/" +
            appId +
            "?status=withdrawn",
          request
        )
        .then(() => {
          this.getPersonAccountById();
          console.log("Отозвана заявка с id=" + appId + " в организацию");
        });
    },
    appEventRevocate(appId) {
      axios
        .patch(
          this.config.url +
            "lk/person/events/apps/" +
            appId +
            "?status=withdrawn"
        )
        .then(() => {
          this.getPersonAccountById();
          console.log("Отозвана заявка с id=" + appId + " на мероприятие");
        });
    },
    appTrainingRevocate(appId) {
      axios
        .patch(
          this.config.url +
            "lk/person/trainings/apps/" +
            appId +
            "?status=withdrawn&comment"
        )
        .then(() => {
          this.getPersonAccountById();
          console.log("Отозвана заявка с id=" + appId + " на спортподготовку");
        });
    },
    appPublicRevocate(appId) {
      axios
        .patch(
          this.config.url +
            "lk/person/services/apps/" +
            appId +
            "?status=withdrawn"
        )
        .then(() => {
          this.getPersonAccountById();
          console.log("Отозвана заявка с id=" + appId + " на масспорт");
        });
    },

    // Подтверждение и отказ от приглашения физлицом
    appTrainingConfirm(appId) {
      axios
        .patch(
          this.config.url +
            "lk/person/trainings/apps/" +
            appId +
            "?status=confirmed&comment"
        )
        .then(() => {
          this.getPersonAccountById();
          console.log(
            "Подтверждено приглашение на заявку с id=" +
              appId +
              " на спортподготовку"
          );
        });
    },
    appTrainingRefuse(appId) {
      axios
        .patch(
          this.config.url +
            "lk/person/trainings/apps/" +
            appId +
            "?status=refused&comment"
        )
        .then(() => {
          this.getPersonAccountById();
          console.log(
            "Отказ заявителя на приглашение по заявка с id=" +
              appId +
              " на спортподготовку"
          );
        });
    },

    // Подтверждение и отзыв заявок юрлицом
    // Выбор заявки для одобрения
    appOrgSelectToApprove(appId) {
      this.config.appToApproveId = appId;
      this.showModal(8);
    },
    appOrgApprove() {
      const request = {
        position: this.modals[7].form.fields[0].value,
        file: this.modals[7].form.fields[1].file.base64,
        fileName: this.modals[7].form.fields[1].file.name,
      };
      axios
        .patch(
          this.config.url +
            "lk/person/organizations/apps/" +
            this.config.appToApproveId +
            "?status=accepted",
          request
        )
        .then(() => {
          this.getOrganizationPassportById();
          console.log(
            "Одобрена заявка с id=" +
              this.config.appToApproveId +
              " в организацию"
          );
          this.hideModal();
          this.config.appToApproveId = null;
          this.resetForm(this.modals[7].form);
          this.modals[7].form.validity = false;
        });
    },
    appOrgDeny(appId) {
      const request = {
        file: "",
        fileName: "",
      };
      axios
        .patch(
          this.config.url +
            "lk/person/organizations/apps/" +
            appId +
            "?status=refused",
          request
        )
        .then(() => {
          this.getOrganizationPassportById();
          console.log("Отказана заявка с id=" + appId + " в организацию");
        });
    },
    appEventApprove(appId) {
      axios
        .patch(
          this.config.url + "persons/event/app/" + appId + "?status=accepted"
        )
        .then(() => {
          console.log("Одобрена заявка с id=" + appId + " на мероприятие");
        });
    },
    appEventDeny(appId) {
      axios
        .patch(
          this.config.url + "persons/event/app/" + appId + "?status=refused"
        )
        .then(() => {
          console.log("Отказана заявка с id=" + appId + " на мероприятие");
        });
    },

    appTrainingSelectToOffer(appId) {
      this.config.appTrainingSelectedForDecision = appId;
      this.showModal(15);
    },
    appTrainingSelectToAdvance(appId) {
      this.config.appTrainingSelectedForDecision = appId;
      this.showModal(10);
    },
    async appTrainingSelectToApprove(appId) {
      this.config.appTrainingSelectedForDecision = appId;
      // Поиск заявки, запрашиваемого вида спорта в заявке
      let trainingApp =
        this.organizationPassportDB.organizationSportsTrainingApps.find(
          function (item) {
            if (item.id === appId) return true;
          }
        );
      console.log(trainingApp);
      console.log(this.modals[10].form.fields[0].itemsList);
      let selectedSport = this.modals[10].form.fields[0].itemsList.find(
        function (item) {
          if (item.label === trainingApp.sportType) return true;
        }
      );
      this.modals[10].form.fields[0].values[0] = selectedSport.value;
      // Установка значений в селект "Дисциплина"
      await this.getDisciplinesForTraining(appId);
      this.showModal(11);
    },
    appTrainingSelectToDeny(appId) {
      this.config.appTrainingSelectedForDecision = appId;
      this.showModal(12);
    },
    appTrainingSelectToResponse(appId) {
      this.config.appTrainingSelectedForDecision = appId;
      this.showModal(14);
    },

    appTrainingOffer() {
      const request = {
        status: "invitation",
        comment: this.modals[13].form.fields[0].value,
        file: this.modals[13].form.fields[1].file.base64,
        fileName: this.modals[13].form.fields[1].file.name,
      };
      axios
        .put(
          this.config.url +
            "lk/person/trainings/apps/" +
            this.config.appTrainingSelectedForDecision,
          request
        )
        .then(() => {
          this.getOrganizationPassportById();
          console.log(
            "Принята в работу заявка с id=" +
              this.config.appTrainingSelectedForDecision +
              " на спортподготовку"
          );
          this.hideModal();
          this.config.appTrainingSelectedForDecision = null;
          this.resetForm(this.modals[13].form);
          this.modals[13].form.validity = false;
        });
    },
    appTrainingAdvance() {
      const request = {
        status: "review",
        file: this.modals[9].form.fields[0].file.base64,
        fileName: this.modals[9].form.fields[0].file.name,
      };
      axios
        .put(
          this.config.url +
            "lk/person/trainings/apps/" +
            this.config.appTrainingSelectedForDecision,
          request
        )
        .then(() => {
          this.getOrganizationPassportById();
          console.log(
            "Принята в работу заявка с id=" +
              this.config.appTrainingSelectedForDecision +
              " на спортподготовку"
          );
          this.hideModal();
          this.config.appTrainingSelectedForDecision = null;
          this.resetForm(this.modals[9].form);
          this.modals[9].form.validity = false;
        });
    },
    appTrainingApprove() {
      const request = {
        status: "enrolled",
        disciplineIds: this.modals[10].form.fields[1].values.toString(),
      };
      axios
        .put(
          this.config.url +
            "lk/person/trainings/apps/" +
            this.config.appTrainingSelectedForDecision,
          request
        )
        .then(() => {
          this.getOrganizationPassportById();
          console.log(
            "Одобрена заявка с id=" +
              this.config.appTrainingSelectedForDecision +
              " на спортподготовку"
          );
          this.hideModal();
          this.config.appTrainingSelectedForDecision = null;
          this.resetForm(this.modals[10].form);
          this.modals[10].form.validity = false;
        });
    },
    appTrainingDeny() {
      // const request = {
      //   commentd: this.modals[11].form.fields[0].value,
      // };
      axios
        .patch(
          this.config.url +
            "lk/person/trainings/apps/" +
            this.config.appTrainingSelectedForDecision +
            "?status=denied&comment=" +
            this.modals[11].form.fields[0].value,
          ""
        )
        .then(() => {
          this.getOrganizationPassportById();
          console.log(
            "Отказана заявка с id=" +
              this.config.appTrainingSelectedForDecision +
              " на спортподготовку"
          );
          this.hideModal();
          this.config.appTrainingSelectedForDecision = null;
          this.resetForm(this.modals[11].form);
          this.modals[11].form.validity = false;
        });
    },
    appTrainingResponse() {
      // const request = {
      //   commentd: this.modals[12].form.fields[0].value,
      // };
      axios
        .patch(
          this.config.url +
            "lk/person/trainings/apps/" +
            this.config.appTrainingSelectedForDecision +
            "?status=answered&comment=" +
            this.modals[12].form.fields[0].value,
          ""
        )
        .then(() => {
          this.getOrganizationPassportById();
          console.log(
            "Отвечено на заявку с id=" +
              this.config.appTrainingSelectedForDecision +
              " на спортподготовку"
          );
          this.hideModal();
          this.config.appTrainingSelectedForDecision = null;
          this.resetForm(this.modals[12].form);
          this.modals[12].form.validity = false;
        });
    },

    appPublicApprove(appId) {
      axios
        .patch(
          this.config.url + "persons/service/app/" + appId + "?status=done"
        )
        .then(() => {
          console.log("Одобрена заявка с id=" + appId + " на масспорт");
        });
    },
    appPublicDeny(appId) {
      axios
        .patch(
          this.config.url + "persons/service/app/" + appId + "?status=cancel"
        )
        .then(() => {
          console.log("Отказана заявка с id=" + appId + " на масспорт");
        });
    },

    // Преобразование даты из формата input-date в формат дд.мм.гггг и обратно
    convertDateInputToDot(dateInputFormat) {
      let arrayDateInput = dateInputFormat.split("-");
      let requestFormatDate =
        arrayDateInput[2] + "." + arrayDateInput[1] + "." + arrayDateInput[0];
      return requestFormatDate;
    },
    convertDateDotToInput(dateDotFormat) {
      let arrayDotInput = dateDotFormat.split(".");
      let requestFormatDate =
        arrayDotInput[2] + "-" + arrayDotInput[1] + "-" + arrayDotInput[0];
      return requestFormatDate;
    },

    compareId(a, b) {
      if (+a[0] > +b[0]) return -1;
      if (+a[0] === +b[0]) return 0;
      if (+a[0] < +b[0]) return 1;
    },

    // Сброс формы к нулевым значениям
    resetForm(formData) {
      formData.fields.forEach(function (field) {
        if (field.type === "select") {
          field.values = [];
        } else if (field.type === "checkbox") {
          field.value = false;
        } else if (field.type === "input") {
          if (field.subtype === "file") {
            field.file.name = "";
            field.file.type = "";
            field.file.base64 = "";
          }
          field.value = "";
        } else if (field.type === "range") {
          field.itemslist[0].value = null;
          field.itemslist[1].value = null;
        }
      });
    },

    // Смена вкладок таб-листа
    changeTab(tabsArray, tabId) {
      tabsArray.forEach(function (item) {
        if (item.id === tabId) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },

    // События календаря
    changePreviousMonth() {
      let currentYear = this.calendar.day.getFullYear();
      let currentMonthIndex = this.calendar.day.getMonth();
      let currentDay = this.calendar.day.getDate();
      this.calendar.day = new Date(
        currentYear,
        currentMonthIndex - 1,
        currentDay
      );
      console.log("Предыдущий месяц");
      this.getEventsPoster();
    },
    changeNextMonth() {
      let currentYear = this.calendar.day.getFullYear();
      let currentMonthIndex = this.calendar.day.getMonth();
      let currentDay = this.calendar.day.getDate();
      this.calendar.day = new Date(
        currentYear,
        currentMonthIndex + 1,
        currentDay
      );
      console.log("Следущий месяц");
      this.getEventsPoster();
    },
    changePreviousWeek() {
      this.calendar.day = new Date(
        +this.calendar.day - 1000 * 60 * 60 * 24 * 7
      );
      console.log("Предыдущая неделя");
      this.getEventsPoster();
    },
    changeNextWeek() {
      this.calendar.day = new Date(
        +this.calendar.day + 1000 * 60 * 60 * 24 * 7
      );
      console.log("Следущая неделя");
      this.getEventsPoster();
    },
    calendarChangeDay(dayData) {
      this.calendar.day = dayData;
      console.log(dayData);
      this.getEventsPoster();
    },

    // Авторизация
    async checkAuth() {
      await axios.get(this.config.url + "login").then((response) => {
        console.groupCollapsed("Ответ на проверку авторизации");
        console.log(response.data);
        console.groupEnd();
        this.config.auth = response.data.auth;
        this.config.legal = response.data.legal;
        if (response.data.auth && response.data.legal) {
          this.config.authUserType = "organization";
        }
        this.config.org = response.data.org;
        this.config.esiaUrl = response.data.url;
        this.config.userShortName = response.data.user;
      });
    },
    async checkUserOrg() {
      await axios.get(this.config.url + "login/org").then((response) => {
        console.groupCollapsed("Список организаций пользователя");
        console.log(response.data);
        console.groupEnd();
        this.config.userOrgs = response.data.organizationDataShorts;
        if (response.data.organizationDataShorts.length === 1) {
          // Вариант автоматического входа под юрлицом, если у пользователя 1 организация
          // this.signInWithOrg(response.data.organizationDataShorts[0].id);
          console.log("У пользователя найдена 1 организация");
          this.config.authModalMode = "select-organization";
          this.showModal(9);
        } else if (response.data.organizationDataShorts.length > 1) {
          console.log("У пользователя найдено более 1 организации");
          this.config.authModalMode = "select-organization";
          this.showModal(9);
        } else {
          this.config.authUserType = "person";
        }
      });
    },
    signInWithOrg(orgId) {
      axios
        .patch(this.config.url + "login/org?orgId=" + orgId, "", {
          withCredentials: true,
        })
        .then((response) => {
          console.groupCollapsed("Список организаций пользователя");
          console.log(response.data);
          console.groupEnd();
          location.href = this.config.url;
          // this.hideModal();
        });
    },
    signInWithPerson() {
      this.hideModal();
      this.config.authUserType = "person";
    },
    getLogout() {
      location.href = this.config.url + "logout";
      // axios
      //   .get(this.config.url + "logout")
      //   .then((response) => {
      //     console.groupCollapsed("Ответ на выход из системы");
      //     console.log(response.data);
      //     console.groupEnd();
      //     // if (!response.data.auth) {
      //     //   this.config.esiaUrl = response.data.url;
      //     // }
      //   })
      //   .catch((error) => {
      //     if (error.response.status === 302) {
      //       console.log("Осуществлен выход из системы");
      //       this.signCheck();
      //       this.goToView("/");
      //     }
      //   });
    },

    // ЕСИА вход и выход
    esiaSignIn() {
      if (this.config.esiaUrl) {
        location.href = this.config.esiaUrl;
      }
    },

    // Получение ссылки на карту
    async getMapUrl() {
      const request = {
        login: "polipovichsa",
        password: "isands",
      };
      await axios
        .post("https://24bpd.ru/catalog/api/2.0/session", request)
        .then((response) => {
          console.groupCollapsed("Cид для карты");
          console.log(response.data);
          console.groupEnd();
          this.config.mapUrl =
            "https://24bpd.ru/maps/app/view.php?id=1a420d9b-28a9-613d-ee11-72b4cd4cdfcc&sid=" +
            response.data.data.sid +
            "&pos=16490516.6395,6211027.6861&zoom=10";
        })
        .catch(() => {
          console.log("Сид для карты не получен");
        });
    },

    // Получение кода региона
    async getRegion() {
      axios.get("/region").then((response) => {
        console.log(`Код региона - ${response.data}`);
        this.config = JSON.parse(
          JSON.stringify(this.regionsConfigs[response.data])
        );
        // let currentRegionConfig = this.regionsConfigs[response.data];
        // this.config = currentRegionConfig;
        // JSON.parse(JSON.stringify(currentRegionConfig));
        console.groupCollapsed("Конфиг региона");
        console.log(this.config);
        console.groupEnd();
      });
    },
  },

  created: async function () {
    // await this.getRegion();
  },

  mounted: async function () {
    console.log("Компонент App смонтирован");
    await this.getRegion();
    this.toggleBodyScroll();
    window.addEventListener("resize", this.toggleBodyScroll);

    await this.checkAuth();
    this.getEventStatuses();

    // Получение общих сведений
    Promise.all([
      this.getNews(),
      this.getHotNews(),
      this.getFaq(),
      this.getEvents(),
      this.getEventsPoster(),
      this.getObjects(),
      this.getOrganizations(),
      this.getMapUrl(),
    ]).then(() => {
      this.config.appLoaded = true;
    });

    if (this.config.auth && !this.config.legal) {
      await this.checkUserOrg();
    }

    if (this.config.auth && !this.config.legal) {
      // Личный кабинет физлица
      await this.getPersonAccountById();
      // console.log(this.$route);
      if (this.$route.name === "ChildUpdateView" && !this.childUpdateData.id) {
        this.childRead(this.$route.params.childid);
      }
      console.log(
        "Начало получения и установки справочников в поля форм физлица"
      );
      this.setOrgRoles();
      this.setEventRoles();
      this.setTrainingAppealTypes();
      this.setGenders();
      console.log("Внутренние справочники физлица установлены");
      Promise.all([
        this.getIdentifyDocuments(),
        this.getEducationGrades(),
        this.getAllOrganizations(),
        this.getAllSports(),
        this.getEventsForApp(),
        this.getObjectsForPublic(),
      ]).then(() => {
        this.config.dictionariesLoaded = true;
        console.log(
          "Загружены и установлены внешние и внутренние справочники физлица"
        );
      });
    } else if (this.config.auth && this.config.legal) {
      // Паспорт юрлица
      this.setTrainingStatuses();
      await this.getOrganizationPassportById();
      Promise.all([
        this.getOrgSports(),
        this.getSportsForOrgPassportFilter(),
      ]).then(() => {
        this.config.dictionariesLoaded = true;
        console.log("Загружены и установлены внешние справочники юрлица");
      });
    }
  },
};
</script>
