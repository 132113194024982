<template>
  <div id="app-add-public-form">
    <Form
      :form-data="modalFormData.formPart1"
      @change-form="$emit('change-form-part1', $event)"
    />
    <div class="modal-comment">
      Не уверены в правильном выборе спортивного объекта? Ознакомьтесь с
      сформированным системой реестром объектов спорта.
    </div>
    <a @click.prevent="$emit('go-to-view')" class="link-custom" href="#">
      <img src="icons/stadiums.svg" class="icon-custom" />
      <span>Перейти в реестр объектов спорта</span>
    </a>
    <Form
      :form-data="modalFormData.formPart2"
      @change-form="$emit('change-form-part2', $event)"
    />
    <ButtonIcon
      class="btn-add btn-primary rounded-pill m-auto"
      text="Добавить заявку"
      icon="plus"
      :disabled-status="
        !modalFormData.formPart1.validity || !modalFormData.formPart2.validity
      "
      @click="$emit('send-form')"
    />
  </div>
</template>

<script>
import Form from "../universal/BS46Form";
import ButtonIcon from "../ButtonIcon";
export default {
  name: "ModalContentPersonAddAppPublic",
  components: { ButtonIcon, Form },
  props: ["modalFormData"],
};
</script>
