import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/news",
    name: "NewsView",
    component: () => import("../views/NewsView.vue"),
  },
  {
    path: "/newsarticle/:newsid",
    name: "NewsArticleView",
    component: () => import("../views/NewsArticleView.vue"),
  },
  {
    path: "/faq",
    name: "FaqView",
    component: () => import("../views/FaqView.vue"),
  },
  {
    path: "/schedule",
    name: "ScheduleView",
    component: () => import("../views/ScheduleView.vue"),
  },
  {
    path: "/objects",
    name: "ObjectsView",
    component: () => import("../views/ObjectsView.vue"),
  },
  {
    path: "/stadium/:stadiumid",
    name: "StadiumView",
    component: () => import("../views/StadiumView.vue"),
  },
  {
    path: "/invalidaccount",
    name: "InvalidAccountView",
    component: () => import("../views/InvalidAccountView.vue"),
  },
  {
    path: "/person",
    name: "PersonView",
    component: () => import("../views/PersonView.vue"),
  },
  {
    path: "/personaccount",
    name: "PersonAccountView",
    component: () => import("../views/PersonAccountView.vue"),
  },
  {
    path: "/children",
    name: "ChildrenView",
    component: () => import("../views/ChildrenView.vue"),
  },
  {
    path: "/childnew",
    name: "ChildCreateView",
    component: () => import("../views/ChildCreateView.vue"),
  },
  {
    path: "/childupdate/:childid",
    name: "ChildUpdateView",
    component: () => import("../views/ChildUpdateView.vue"),
  },
  {
    path: "/organizations",
    name: "EntityView",
    component: () => import("../views/OrganizationsView.vue"),
  },
  {
    path: "/orgpassport",
    name: "OrganizationPassportView",
    component: () => import("../views/OrganizationPassportView.vue"),
  },
  {
    path: "/test",
    name: "TestView",
    component: () => import("../views/TestView.vue"),
  },
  {
    path: "/auth",
    name: "AuthView",
    component: () => import("../views/AuthView.vue"),
  },
  {
    path: "/event/:eventid",
    name: "EventView",
    component: () => import("../views/EventView.vue"),
  },
  {
    path: "/organization/:orgid",
    name: "OrganizationView",
    component: () => import("../views/OrganizationView.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

export default router;
