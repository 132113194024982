<template>
  <div class="calendar-month-day-selector">
    <div class="month-selector">
      <button class="btn-previous" @click="$emit('change-previous-month')">
        <img src="/icons/arrowleft.svg" />
      </button>
      <div>{{ monthYear }}</div>
      <button class="btn-next" @click="$emit('change-next-month')">
        <img src="/icons/arrowright.svg" />
      </button>
    </div>
    <div class="day-selector">
      <button class="btn-previous" @click="$emit('change-previous-week')">
        <img src="/icons/arrowleft.svg" />
      </button>
      <div
        :class="[
          'day-week-number',
          { active: +daysDateFormat[index] === +calendarData.day },
        ]"
        v-for="(day, index) of daysNumberFormat"
        :key="index"
        @click="$emit('change-day', daysDateFormat[index])"
      >
        <div class="day-of-week">
          <div>{{ daysOfWeekShort[index] }}</div>
        </div>
        <div class="day-number">
          <div>{{ day }}</div>
        </div>
      </div>
      <button class="btn-next" @click="$emit('change-next-week')">
        <img src="/icons/arrowright.svg" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalendarMonthDaySelector",
  props: {
    calendarData: Object,
  },
  data() {
    return {
      daysOfWeekShort: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
    };
  },
  computed: {
    startDayOfWeek: function () {
      let indexDay = (this.calendarData.day.getDay() + 6) % 7;
      let oneDayinMs = 1000 * 60 * 60 * 24;
      let startDayOfWeek = new Date(
        +this.calendarData.day - indexDay * oneDayinMs
      );
      return startDayOfWeek;
    },
    daysDateFormat: function () {
      let daysDateformat = [];
      let oneDayinMs = 1000 * 60 * 60 * 24;
      for (let i = 0; i < 7; i++) {
        let day = new Date(+this.startDayOfWeek + i * oneDayinMs);
        daysDateformat.push(day);
      }
      return daysDateformat;
    },
    daysNumberFormat: function () {
      let daysNumberFormat = [];
      this.daysDateFormat.forEach(function (item) {
        daysNumberFormat.push(item.getDate());
      });
      return daysNumberFormat;
    },
    monthYear: function () {
      let monthYear = this.startDayOfWeek
        .toLocaleString("ru", {
          year: "numeric",
          month: "long",
        })
        .slice(0, -3);
      return monthYear;
    },
  },
};
</script>
