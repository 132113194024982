<template>
  <Card image-position="full" no-body class="service-card" :style="imageStyle">
    <template v-slot:card-image-top>
      <div
        :class="[
          'card-img-overlay d-flex flex-column justify-content-between',
          { 'card-img-overlay-dark': colorMode === 'dark' },
        ]"
      >
        <div class="d-flex flex-column">
          <div class="card-category">{{ cardData.categories[0] }}</div>
          <h2 class="card-name">
            <a
              v-if="cardData.action"
              class="stretched-link"
              @click.prevent="$emit(cardData.action)"
              href="#"
              >{{ cardData.title }}</a
            >
            <a
              v-else-if="cardData.link.startsWith('http')"
              class="stretched-link"
              :href="cardData.link"
              target="_blank"
              >{{ cardData.title }}</a
            >
            <router-link v-else class="stretched-link" :to="cardData.link">{{
              cardData.title
            }}</router-link>
          </h2>
        </div>
        <div class="card-date">{{ cardData.datePublish }}</div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "./universal/BS46Card";
export default {
  name: "CardServices",
  components: { Card },
  props: {
    cardData: Object,
    colorMode: String,
  },
  computed: {
    imageStyle: function () {
      let imageStyle =
        "background: url(" +
        this.cardData.image.src +
        ") no-repeat center center; background-size: cover";
      return imageStyle;
    },
  },
};
</script>

<style scoped></style>
