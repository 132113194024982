<template>
  <div id="offcanvas-main">
    <div :class="['offcanvas offcanvas-start', { show: open }]">
      <div v-if="header" class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
      </div>
      <div class="offcanvas-body">
        <slot name="offcanvas-body"></slot>
      </div>
      <button class="btn-minimize" @click="$emit('toggle-offcanvas')">
        <img src="/icons/sidebar/minimize.svg" class="icon-minimize" />
      </button>
    </div>
    <div
      v-if="open"
      class="offcanvas-backdrop"
      @click="$emit('close-offcanvas')"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Offcanvas",
  props: { header: Boolean, open: Boolean },
};
</script>
