<template>
  <a
    class="link-download-file"
    :href="'data:' + fileData.mimeType + ';base64,' + fileData.file"
    :download="fileData.fileName"
  >
    <img :src="'icons/' + icon + '.svg'" class="mr-2" />
    <span>{{ linkText }}</span>
  </a>
</template>

<script>
export default {
  name: "LinkDownloadFile",
  props: ["fileData", "linkText", "icon"],
};
</script>
