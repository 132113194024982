<template>
  <Card image-position="full" no-body class="news-card-hot" :style="imageStyle">
    <template v-slot:card-image-top>
      <div
        :class="[
          'card-img-overlay d-flex flex-column justify-content-between',
          { 'card-img-overlay-dark': colorMode === 'dark' },
        ]"
      >
        <div class="d-flex flex-column">
          <!--          <div class="card-category">{{ cardData.categories[0] }}</div>-->
          <h2 class="card-name">
            <a
              class="stretched-link"
              @click.prevent="$emit('click', cardData.id)"
              :href="'/newsarticle/' + cardData.id"
              >{{ cardData.title }}</a
            >
          </h2>
        </div>
        <div class="card-date">{{ cardData.datePublish }}</div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "./universal/BS46Card";
export default {
  name: "CardNewsVertical",
  components: { Card },
  props: {
    cardData: Object,
    colorMode: String,
  },
  computed: {
    imageStyle: function () {
      let imageStyle =
        "background: url(data:" +
        this.cardData.image.type +
        ";base64," +
        this.cardData.image.src +
        ") no-repeat center center; background-size: cover";
      return imageStyle;
    },
  },
};
</script>

<style scoped></style>
