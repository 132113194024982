<template>
  <div id="app-add-training-form">
    <Form
      :form-data="modalFormData.formPart1"
      @change-form="$emit('change-form-part1', $event)"
    />
    <template
      v-if="config.personAccountPage.addAppTrainingModal.isOrgRegistryLink"
    >
      <div class="modal-comment">
        Не уверены в правильном выборе организации? Ознакомьтесь с
        сформированным системой реестром юридических лиц.
      </div>
      <a @click.prevent="$emit('go-to-view')" class="link-custom" href="#">
        <img src="icons/orgs.svg" class="icon-custom" />
        <span>Перейти в реестр юридических лиц</span>
      </a>
    </template>
    <div class="modal-comment">
      Пожалуйста, перед подачей заявки ознакомтесь с требованиями, которые
      предъявляются спортивной организацией к спортсменам.
    </div>
    <a
      v-if="modalFormData.formPart1.fields[2].values[0]"
      @click.prevent="
        $emit('read-requirements', modalFormData.formPart1.fields[2].values[0])
      "
      class="link-custom"
      href="#"
    >
      <img src="icons/reqs.svg" class="icon-custom" />
      <span>Требования спортивной организации</span>
    </a>
    <a v-else @click.prevent class="link-custom not-active-link" href="#">
      <img src="icons/reqs.svg" class="icon-custom" />
      <span>Требования спортивной организации</span>
    </a>
    <Form
      :form-data="modalFormData.formPart2"
      @change-form="$emit('change-form-part2', $event)"
    />
    <div class="modal-footer-container">
      <div class="form-check flex-shrink-1">
        <input
          class="form-check-input"
          type="checkbox"
          :value="modalFormData.formPart3.fields[0].value"
          :id="modalFormData.formPart3.fields[0].id"
          v-model="agreeValue"
          @change="
            $emit('change-form-part3', {
              id: modalFormData.formPart3.fields[0].id,
              value: agreeValue,
            })
          "
        />
        <label
          class="form-check-label"
          :for="modalFormData.formPart3.fields[0].id"
        >
          Согласие на обработку
          <a @click.prevent="" href="#">персональных данных</a>
          <span
            v-if="modalFormData.formPart3.fields[0].required"
            class="text-danger"
            >*</span
          >
        </label>
      </div>
      <ButtonIcon
        class="btn-add btn-primary rounded-pill"
        text="Добавить заявку"
        icon="plus"
        :disabled-status="
          !modalFormData.formPart1.validity ||
          !modalFormData.formPart2.validity ||
          !modalFormData.formPart3.validity
        "
        @click="$emit('send-form')"
      />
    </div>
  </div>
</template>

<script>
import Form from "../universal/BS46Form";
import ButtonIcon from "../ButtonIcon";
export default {
  name: "ModalContentPersonAddAppTraining",
  components: { ButtonIcon, Form },
  props: ["modalFormData", "config"],
  data() {
    return {
      agreeValue: false,
    };
  },

  created() {
    this.agreeValue = this.modalFormData.formPart3.fields[0].value;
  },

  updated() {
    console.log("update check");
    this.agreeValue = this.modalFormData.formPart3.fields[0].value;
  },
};
</script>
