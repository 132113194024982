<template>
  <header
    id="header"
    :class="['bg-' + variant, { 'sticky-top': sticky }, { shadow: shadow }]"
  >
    <div class="container">
      <button
        class="btn-burger btn btn-light rounded-circle d-sm-none"
        @click="$emit('open-offcanvas')"
      >
        <img src="/icons/burger.svg" class="icon-burger" />
      </button>
      <h2 class="section-title">{{ pageTitle }}</h2>
      <img
        :src="
          config.basicInfo.regionCode
            ? `/images/${config.basicInfo.regionCode}/logo.png`
            : 'images/default/logo.svg'
        "
        alt=""
        class="logo-image d-sm-none"
      />
      <form class="form-inline">
        <!--<input
          :class="[
            'search-input rounded-pill form-control',
            { active: isActiveSearch },
          ]"
          type="search"
          placeholder="Введите ваш запрос"
          aria-label="Поиск"
          @focus="isActiveSearch = true"
          @blur="isActiveSearch = false"
        />-->
        <button
          class="btn-notifications btn btn-light rounded-circle invisible"
          type="button"
        >
          <img src="/icons/bell.svg" class="icon-bell" />
        </button>
      </form>
    </div>
  </header>
</template>

<script>
export default {
  name: "TheHeader",
  components: {},
  props: {
    config: Object,
    variant: String,
    navbarItems: Array,
    sticky: Boolean,
    shadow: Boolean,
    expand: Boolean,
    expandWidthPoint: String,
    isAuthUser: Boolean,
    messages: Boolean,
    unreadMessages: Number,
    pageTitle: String,
  },

  data() {
    return {
      isActiveSearch: false,
    };
  },

  computed: {
    isDarkTheme() {
      const darkVariants = [
        "primary",
        "secondary",
        "success",
        "danger",
        "dark",
      ];
      return darkVariants.includes(this.variant);
    },
    expandClass() {
      let expandClass = "";
      if (this.expand) {
        expandClass += "navbar-expand";
        if (this.expandWidthPoint) {
          expandClass += "-" + this.expandWidthPoint;
        }
      }
      return expandClass;
    },
  },
};
</script>
