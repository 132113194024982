<template>
  <div>
    <template v-if="requirements.description || requirements.files.length > 0">
      <div v-if="requirements.files.length > 0" class="requirements-files">
        <template v-for="doc of requirements.files">
          <LinkDownloadFile
            v-if="doc.file.file"
            :key="doc.id"
            :file-data="doc.file"
            :link-text="doc.fileName"
            icon="doc"
          />
        </template>
      </div>
      <div
        v-if="requirements.description"
        v-html="requirements.description"
      ></div>
    </template>
    <Alert v-else>Требования организации ещё не добавлены</Alert>
    <a
      @click.prevent="$emit('return-to-training-app-modal')"
      href="#"
      class="d-block mt-3"
    >
      Продолжить оформление заявки
    </a>
  </div>
</template>

<script>
import Alert from "../universal/BS46Alert";
import LinkDownloadFile from "../LinkDownloadFile";
export default {
  name: "ModalContentPersonAddAppTrainingReq",
  components: { LinkDownloadFile, Alert },
  props: {
    requirements: Object,
  },
};
</script>
