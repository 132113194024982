<template>
  <div>
    <Form
      :form-data="modalFormData.form"
      @change-form="$emit('change-form', $event)"
    />
    <div class="d-flex justify-content-end">
      <ButtonIcon
        class="btn-add btn-primary rounded-pill mr-2"
        text="Сохранить"
        icon="plus"
        :disabled-status="!modalFormData.form.validity"
        @click="$emit('send-form')"
      />
      <ButtonIcon
        class="btn-add btn-outline-secondary rounded-pill"
        text="Отмена"
        icon="plus"
        @click="$emit('hide-modal')"
      />
    </div>
  </div>
</template>

<script>
import ButtonIcon from "../ButtonIcon";
import Form from "../universal/BS46Form";

export default {
  name: "ModalContentTrainingDeny",
  components: { ButtonIcon, Form },
  props: ["modalFormData"],
};
</script>
