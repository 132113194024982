<template>
  <div id="home-view">
    <TheHeader
      is-auth-user
      variant="light"
      expand
      expand-width-point="lg"
      sticky
      shadow
      page-title="Главная"
      :config="config"
      @open-offcanvas="$emit('open-offcanvas')"
    />
    <div class="container">
      <p class="block-title" style="font-size: 20px">
        Добро пожаловать в
        {{
          config.basicInfo.systemName.fullName
            ? config.basicInfo.systemName.fullName
            : "ИС Арена"
        }}
      </p>
      <p class="text-11" style="font-size: 14px">
        Платформа создана для обработки информации сферы физической культуры и
        спорта
        {{
          config.basicInfo.regionNames[1]
            ? config.basicInfo.regionNames[1]
            : "субъекта РФ"
        }}
        и автоматизации деятельности министерства физической культуры и спорта
        {{
          config.basicInfo.regionNames[1]
            ? config.basicInfo.regionNames[1]
            : "субъекта РФ"
        }}
      </p>

      <div class="row mb-3">
        <div class="col-12 col-sm-8">
          <Card header id="services-card" class="border-0">
            <template v-slot:card-header>
              <p class="block-title mb-0">Быстрый доступ к сервисам</p>
            </template>
            <template v-slot:card-body>
              <ul class="nav nav-pills" id="servicesTab" role="tablist">
                <NavTabsButton
                  v-for="tab of config.homePage.services.tabs"
                  :key="tab.id"
                  :id="tab.id"
                  :active="tab.active"
                  @click="$emit('change-tab-services', $event)"
                >
                  <img :src="tab.image.src" class="icon-custom" /><span>{{
                    tab.title
                  }}</span>
                </NavTabsButton>
              </ul>
              <div class="tab-content" id="servicesTabContent">
                <NavTabsPane
                  v-for="tabContent of config.homePage.services.tabs"
                  :key="tabContent.id"
                  :id="tabContent.id"
                  :active="tabContent.active"
                >
                  <div class="card-list row my-0">
                    <div
                      class="col-12 col-sm-6"
                      v-for="cardServiceIndex of tabContent.servicesArray"
                      :key="config.homePage.services.cards[cardServiceIndex].id"
                    >
                      <CardServices
                        :id="
                          config.homePage.services.cards[cardServiceIndex].id
                        "
                        :card-data="
                          config.homePage.services.cards[cardServiceIndex]
                        "
                        colorMode="dark"
                        class="news-card border-0 shadow-sm"
                        @go-to-training="$emit('go-to-training')"
                        @go-to-public="$emit('go-to-public')"
                        @go-to-apps="$emit('go-to-apps')"
                      />
                    </div>
                  </div>
                </NavTabsPane>
              </div>
            </template>
          </Card>
        </div>
        <div class="col-12 col-sm-4">
          <div class="row">
            <div class="col-12 mb-3">
              <Card header class="h-100 border-0">
                <template v-slot:card-header>
                  <div class="block-title mb-0">
                    {{
                      config.homePage.calendar.title
                        ? config.homePage.calendar.title
                        : "Календарь"
                    }}
                  </div>
                </template>
                <template v-slot:card-body>
                  <CalendarMonthDaySelector
                    :calendar-data="calendarData"
                    @change-previous-month="$emit('change-previous-month')"
                    @change-next-month="$emit('change-next-month')"
                    @change-previous-week="$emit('change-previous-week')"
                    @change-next-week="$emit('change-next-week')"
                    @change-day="$emit('change-day', $event)"
                  />
                </template>
              </Card>
            </div>
            <div class="col-12">
              <Card header class="h-100 border-0">
                <template v-slot:card-header>
                  <div class="block-title">Афиша событий</div>
                </template>
                <template v-slot:card-body>
                  <PlayBill
                    :calendar-data="eventsPosterData"
                    @event-read="$emit('event-read', $event)"
                  />
                </template>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <p class="block-title">Главные новости</p>
      <div class="row">
        <div
          v-for="newsItem of news.hotNews"
          :key="newsItem.id"
          class="col-12 col-sm-3"
        >
          <CardNewsVertical
            :card-data="newsItem"
            colorMode="dark"
            class="news-card border-0 shadow-sm"
            @click="$emit('news-read', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "../components/universal/BS46Card";
import TheHeader from "../components/TheHeader";
import CardNewsVertical from "../components/CardNewsVertical";
import NavTabsButton from "../components/universal/BS46NavTabsButton";
import NavTabsPane from "../components/universal/BS46NavTabsPane";
import CardServices from "../components/CardServices";
import CalendarMonthDaySelector from "../components/CalendarMonthDaySelector";
import PlayBill from "../components/PlayBill";

export default {
  name: "HomeView",
  components: {
    PlayBill,
    CalendarMonthDaySelector,
    CardServices,
    NavTabsPane,
    NavTabsButton,
    CardNewsVertical,
    TheHeader,
    Card,
  },
  props: {
    config: Object,
    calendarData: Object,
    news: Object,
    services: Object,
    eventsPosterData: Array,
  },
  data() {
    return {
      items: [],
      messagesTable: {
        id: "messagesTable",
        service: 1,
        columnsList: [
          { title: "№ сообщения", name: "id", sorted: true },
          { title: "Дата создания сообщения", name: "startDate", sorted: true },
          { title: "Тема", name: "messageTitle" },
          { title: "Дата входа в статус", name: "statusStartDate" },
          { title: "Статус", name: "status", sorted: true },
          { title: "Срок отправки ответа", name: "responseLimit" },
        ],
        primaryColumn: "№ сообщения",
        rowsList: [
          [
            "0123",
            "01.08.2022",
            "Детали аттестации",
            "07.08.2022",
            "В работе",
            "05.08.2022",
          ],
          [
            "0125",
            "05.08.2022",
            "Аттестация педагогических работников",
            "05.08.2022",
            "В работе",
            "07.08.2022",
          ],
          [
            "0128",
            "09.08.2022",
            "Аттестация педагогических работников",
            "03.08.2022",
            "Черновик",
            "10.08.2022",
          ],
          [
            "0128",
            "09.08.2022",
            "Аттестация педагогических работников",
            "03.08.2022",
            "Черновик",
            "10.08.2022",
          ],
          [
            "0128",
            "09.08.2022",
            "Аттестация педагогических работников",
            "03.08.2022",
            "Черновик",
            "10.08.2022",
          ],
          [
            "0128",
            "09.08.2022",
            "Аттестация педагогических работников",
            "03.08.2022",
            "Черновик",
            "10.08.2022",
          ],
          [
            "0128",
            "09.08.2022",
            "Аттестация педагогических работников",
            "03.08.2022",
            "Черновик",
            "10.08.2022",
          ],
          [
            "0128",
            "09.08.2022",
            "Аттестация педагогических работников",
            "03.08.2022",
            "Черновик",
            "10.08.2022",
          ],
          [
            "0128",
            "09.08.2022",
            "Аттестация педагогических работников",
            "03.08.2022",
            "Черновик",
            "10.08.2022",
          ],
          [
            "0128",
            "09.08.2022",
            "Аттестация педагогических работников",
            "03.08.2022",
            "Черновик",
            "10.08.2022",
          ],
        ],
        sortColumn: "id",
        ascendingSortOrder: false,
        filters: [
          {
            id: "1",
            label: "№ сообщения",
            type: "input",
            subtype: "number",
            width: 12,
            responsive: "col-sm-4 col-md-3 col-lg-2",
          },
          {
            id: "2",
            label: "Дата создания сообщения",
            type: "range",
            subtype: "date",
            itemsList: [{ label: " c" }, { label: " по" }],
            width: 12,
            responsive: "col-sm-8 col-md-6 col-lg-4",
          },
          {
            id: "3",
            label: "Тема",
            type: "input",
            subtype: "Тема",
            width: 12,
            responsive: "col-sm-4 col-md-3 col-lg-2",
          },
          {
            id: "4",
            label: "Дата входа в статус",
            type: "range",
            subtype: "date",
            itemsList: [{ label: " с" }, { label: " по" }],
            width: 12,
            responsive: "col-sm-8 col-md-6 col-lg-4",
          },
          {
            id: "5",
            label: "Статус",
            type: "select",
            itemsList: [
              { id: 1, text: "Черновик" },
              { id: 2, text: "В работе" },
              { id: 3, text: "Обработано" },
              { id: 4, text: "Архивная" },
            ],
            width: 12,
            responsive: "col-sm-4 col-md-3 col-lg-2",
          },
          {
            id: "6",
            label: "Срок отправки ответа",
            type: "range",
            subtype: "date",
            itemsList: [{ label: " с" }, { label: " по" }],
            width: 12,
            responsive: "col-sm-8 col-md-6 col-lg-4",
          },
          {
            id: "7",
            label: "Показать только архивные",
            type: "checkbox",
            width: 12,
            responsive: "col-sm-4 col-md-3 col-lg-2",
          },
        ],
        pagination: {
          itemsTotal: 15,
          page: 1,
          pageSize: 10,
          itemsPerPage: [10, 25, 50],
        },
      },
      authLoader: {
        isLoading: true,
        isResponse: false,
        comment: "Загрузка",
        theme: "secondary",
      },
      formData: [
        {
          id: "1",
          label: "№ сообщения",
          type: "input",
          subtype: "number",
          width: 12,
          responsive: "col-sm-4 col-md-3 col-lg-2",
        },
        {
          id: "2",
          label: "Дата создания сообщения",
          type: "range",
          subtype: "date",
          itemsList: [{ label: " c" }, { label: " по" }],
          width: 12,
          responsive: "col-sm-8 col-md-6 col-lg-4",
        },
        {
          id: "3",
          label: "Тема",
          type: "input",
          subtype: "Тема",
          width: 12,
          responsive: "col-sm-4 col-md-3 col-lg-2",
        },
        {
          id: "4",
          label: "Дата входа в статус",
          type: "range",
          subtype: "date",
          itemsList: [{ label: " с" }, { label: " по" }],
          width: 12,
          responsive: "col-sm-8 col-md-6 col-lg-4",
        },
        {
          id: "5",
          label: "Статус",
          type: "select",
          itemsList: [
            { id: 1, text: "Черновик" },
            { id: 2, text: "В работе" },
            { id: 3, text: "Обработано" },
            { id: 4, text: "Архивная" },
          ],
          width: 12,
          responsive: "col-sm-4 col-md-3 col-lg-2",
        },
        {
          id: "6",
          label: "Срок отправки ответа",
          type: "range",
          subtype: "date",
          itemsList: [{ label: " с" }, { label: " по" }],
          width: 12,
          responsive: "col-sm-8 col-md-6 col-lg-4",
        },
        {
          id: "7",
          label: "Показать только архивные",
          type: "checkbox",
          width: 12,
          responsive: "col-sm-4 col-md-3 col-lg-2",
        },
      ],
      formData2: [
        {
          id: "1",
          label: "№ сообщения",
          type: "input",
          subtype: "number",
          width: 12,
          responsive: "",
          horizontal: true,
          horizontalWidth: {
            label: {
              width: 4,
              responsive: "col-lg-6",
            },
            field: {
              width: 8,
              responsive: "col-lg-6",
            },
          },
        },
        {
          id: "2",
          label: "Статус",
          type: "select",
          itemsList: [
            { id: 1, text: "Черновик" },
            { id: 2, text: "В работе" },
            { id: 3, text: "Обработано" },
            { id: 4, text: "Архивная" },
          ],
          width: 12,
          responsive: "",
          horizontal: true,
          horizontalWidth: {
            label: {
              width: 4,
              responsive: "col-lg-6",
            },
            field: {
              width: 8,
              responsive: "col-lg-6",
            },
          },
        },
        {
          id: "3",
          label: "Дата входа в статус",
          type: "range",
          subtype: "date",
          itemsList: [{ label: " с" }, { label: " по" }],
          width: 12,
          responsive: "",
          horizontal: true,
          horizontalWidth: {
            label: {
              width: 4,
              responsive: "col-lg-6",
            },
            field: {
              width: 8,
              responsive: "col-lg-6",
            },
          },
        },
        {
          id: "4",
          label: "Показать только архивные",
          type: "checkbox",
          width: 12,
          responsive: "",
          horizontal: true,
          horizontalWidth: {
            label: {
              width: 4,
              responsive: "col-lg-6",
            },
            field: {
              width: 8,
              responsive: "col-lg-6",
            },
          },
        },
      ],
      hotServices: [
        {
          id: 1,
          title: "Записаться на занятия спортом в спортивные организации края",
          annotation: "",
          category: "Сервисы",
          image: {
            src: "/images/default_dark.jpg",
          },
        },
        {
          id: 2,
          title: "Краевой реестр объектов спортивной инфраструктуры",
          annotation: "",
          category: "Сервисы",
          image: {
            src: "/images/default_dark.jpg",
          },
        },
        {
          id: 3,
          title: "Перечень юридических лиц, работающих в сфере ФКиС",
          annotation: "",
          category: "Сервисы",
          image: {
            src: "/images/default_dark.jpg",
          },
        },
        {
          id: 4,
          title: "Региональный календарный план",
          annotation: "",
          category: "Сервисы",
          image: {
            src: "/images/default_dark.jpg",
          },
        },
      ],
      hotNews: [
        {
          id: 1,
          title: "Девять медалей завоевали красноярские лучники в Кемерово",
          annotation: "",
          category: "Стрельба из лука",
          image: {
            src: "/images/default_dark.jpg",
          },
        },
        {
          id: 2,
          title: "Красноярские тяжелоатлеты стали чемпионами",
          annotation: "",
          category: "Тяжелая атлетика",
          image: {
            src: "/images/default_dark.jpg",
          },
        },
        {
          id: 3,
          title:
            "Анастасия Анисимова – победитель чемпионата России по легкой атлетике",
          annotation: "",
          category: "Легкая атлетика",
          image: {
            src: "/images/default_dark.jpg",
          },
        },
        {
          id: 4,
          title:
            "Лыжник Андрей Мельниченко завоевал бронзу чемпионата России в марафоне",
          annotation: "",
          category: "Лыжные гонки",
          image: {
            src: "/images/default_dark.jpg",
          },
        },
      ],
    };
  },
  methods: {
    getInfo() {
      axios("https://jsonplaceholder.typicode.com/posts").then((response) => {
        this.items = response.data;
      });
    },
  },
};
</script>
