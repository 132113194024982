<template>
  <ul v-if="config" :class="navClass">
    <template v-for="listItem of navList">
      <li
        v-if="
          listItem.type === 'link' &&
          (!listItem.forAuthUser ||
            (listItem.forAuthUser &&
              listItem.forUserType === 'person' &&
              config.auth &&
              config.authUserType === 'person') ||
            (listItem.forAuthUser &&
              listItem.forUserType === 'organization' &&
              config.auth &&
              config.authUserType === 'organization'))
        "
        :key="listItem.id"
        class="nav-item"
      >
        <router-link
          v-if="listItem.link === '/'"
          class="nav-link"
          :to="listItem.link"
          exact
        >
          <img
            v-if="listItem.icon && listItem.icon.src"
            :src="listItem.icon.src"
            class="icon-custom"
          /><span>{{ listItem.title }}</span>
        </router-link>
        <router-link v-else class="nav-link" :to="listItem.link">
          <img
            v-if="listItem.icon && listItem.icon.src"
            :src="listItem.icon.src"
            class="icon-custom"
          /><span>{{ listItem.title }}</span>
        </router-link>
      </li>
      <li
        v-else-if="listItem.type === 'dropdown'"
        :key="listItem.id"
        class="nav-item dropdown"
      >
        <a
          class="nav-link dropdown-toggle"
          href="#"
          role="button"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          {{ listItem.title }}</a
        >
        <div v-if="listItem.items.length > 0" class="dropdown-menu">
          <router-link
            v-for="item of listItem.items"
            :key="item.id"
            class="dropdown-item"
            :to="item.link"
            >{{ item.title }}</router-link
          >
        </div>
      </li>
      <li
        v-else-if="listItem.type === 'text'"
        :key="listItem.id"
        class="nav-item"
      >
        {{ listItem.title }}
      </li>
    </template>

    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: "Nav",
  props: {
    navList: Array,
    vertical: Boolean,
    isAuthUser: Boolean,
    config: Object,
  },
  computed: {
    navClass: function () {
      let navClass = "nav";
      if (this.vertical) {
        navClass += " flex-column";
      } else {
        navClass += " justify-content-end";
      }
      return navClass;
    },
  },
};
</script>
