var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.config)?_c('ul',{class:_vm.navClass},[_vm._l((_vm.navList),function(listItem){return [(
        listItem.type === 'link' &&
        (!listItem.forAuthUser ||
          (listItem.forAuthUser &&
            listItem.forUserType === 'person' &&
            _vm.config.auth &&
            _vm.config.authUserType === 'person') ||
          (listItem.forAuthUser &&
            listItem.forUserType === 'organization' &&
            _vm.config.auth &&
            _vm.config.authUserType === 'organization'))
      )?_c('li',{key:listItem.id,staticClass:"nav-item"},[(listItem.link === '/')?_c('router-link',{staticClass:"nav-link",attrs:{"to":listItem.link,"exact":""}},[(listItem.icon && listItem.icon.src)?_c('img',{staticClass:"icon-custom",attrs:{"src":listItem.icon.src}}):_vm._e(),_c('span',[_vm._v(_vm._s(listItem.title))])]):_c('router-link',{staticClass:"nav-link",attrs:{"to":listItem.link}},[(listItem.icon && listItem.icon.src)?_c('img',{staticClass:"icon-custom",attrs:{"src":listItem.icon.src}}):_vm._e(),_c('span',[_vm._v(_vm._s(listItem.title))])])],1):(listItem.type === 'dropdown')?_c('li',{key:listItem.id,staticClass:"nav-item dropdown"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#","role":"button","data-toggle":"dropdown","aria-expanded":"false"}},[_vm._v(" "+_vm._s(listItem.title))]),(listItem.items.length > 0)?_c('div',{staticClass:"dropdown-menu"},_vm._l((listItem.items),function(item){return _c('router-link',{key:item.id,staticClass:"dropdown-item",attrs:{"to":item.link}},[_vm._v(_vm._s(item.title))])}),1):_vm._e()]):(listItem.type === 'text')?_c('li',{key:listItem.id,staticClass:"nav-item"},[_vm._v(" "+_vm._s(listItem.title)+" ")]):_vm._e()]}),_vm._t("default")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }