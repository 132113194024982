<template>
  <div class="modal-header-container">
    <button
      v-if="!onlyTitle"
      class="btn-modal-header btn-close"
      @click="$emit('hide-modal')"
    >
      <img src="/icons/close.svg" class="icon-close" />
    </button>
    <!--<button class="btn-modal-header btn-question">
      <span class="icon-question">?</span>
    </button>-->
    <span class="modal-title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "ModalHeader",
  props: {
    title: String,
    onlyTitle: Boolean,
  },
};
</script>
