<template>
  <button
    class="btn"
    type="button"
    @click="$emit('click')"
    :disabled="disabledStatus"
  >
    <span>{{ text }}</span>
    <img :src="'icons/' + icon + '.svg'" />
  </button>
</template>

<script>
export default {
  name: "ButtonIcon",
  props: ["text", "icon", "disabledStatus"],
};
</script>
