<template>
  <div id="person-select-org">
    <button
      v-for="org of modalFormData"
      :key="org.id"
      class="btn btn-outline-primary btn-block"
      @click="$emit('sign-in-with-org', org.id)"
    >
      {{ org.name }}
    </button>
    <button
      class="btn btn-outline-primary btn-block"
      @click="$emit('select-person')"
    >
      Войти как физическое лицо
    </button>
  </div>
</template>

<script>
export default {
  name: "ModalContentPersonSelectOrg",
  props: ["modalFormData"],
};
</script>
