<template>
  <div>
    <template v-if="calendarData.length > 0">
      <template v-for="(event, index) of calendarData">
        <div v-if="index < 3" :key="event.data.id" class="play-bill">
          <a
            href="#"
            class="d-block"
            @click.prevent="$emit('event-read', event.data.id)"
          >
            {{ event.data.name }}
          </a>
          <div>
            <img src="icons/address.svg" class="mr-2" /><span>{{
              event.data.place
            }}</span>
          </div>
          <div>
            <img src="icons/time.svg" class="mr-2" />
            <span>{{ event.data.dateStart }} - {{ event.data.dateEnd }}</span>
          </div>
        </div>
      </template>

      <router-link v-if="calendarData.length >= 3" to="/schedule">
        Смотреть все мероприятия
      </router-link>
    </template>
    <div v-else>На выбранную дату мероприятий нет</div>
  </div>
</template>

<script>
export default {
  name: "PlayBill",
  props: {
    calendarData: Array,
  },
  computed: {
    dayNumber: function () {
      let dayNumber = this.calendarData.day.getDate();
      return dayNumber;
    },
    monthYear: function () {
      let monthYear = this.calendarData.day
        .toLocaleString("ru", {
          year: "numeric",
          month: "long",
        })
        .slice(0, -3);
      return monthYear;
    },
  },
};
</script>
