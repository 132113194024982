<template>
  <div>
    <Form
      :form-data="modalFormData.form"
      @change-form="$emit('change-form', $event)"
    />
    <ButtonIcon
      class="btn-add btn-primary rounded-pill m-auto"
      text="Сохранить"
      icon="plus"
      :disabled-status="!modalFormData.form.validity"
      @click="$emit('send-form')"
    />
    <ButtonIcon
      class="btn-add btn-outline-secondary rounded-pill m-auto"
      text="Отмена"
      icon="plus"
      @click="$emit('hide-modal')"
    />
  </div>
</template>

<script>
import ButtonIcon from "../ButtonIcon";
import Form from "../universal/BS46Form";

export default {
  name: "ModalContentPersonApproveAppOrg",
  components: { ButtonIcon, Form },
  props: ["modalFormData"],
};
</script>
