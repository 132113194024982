<template>
  <div>
    <Form
      :form-data="modalFormData.form"
      @change-form="$emit('change-form', $event)"
    />
    <ButtonIcon
      class="btn-add btn-primary rounded-pill m-auto"
      text="Добавить документ"
      icon="plus"
      @click="$emit('send-form')"
    />
  </div>
</template>

<script>
import Form from "../universal/BS46Form";
import ButtonIcon from "../ButtonIcon";
export default {
  name: "ModalContentPersonAddDoc",
  components: { ButtonIcon, Form },
  props: ["modalFormData"],
};
</script>
